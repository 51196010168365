import { render, staticRenderFns } from "./AppDialogHeader.vue?vue&type=template&id=7a315c42&scoped=true&"
import script from "./AppDialogHeader.vue?vue&type=script&lang=js&"
export * from "./AppDialogHeader.vue?vue&type=script&lang=js&"
import style0 from "./AppDialogHeader.vue?vue&type=style&index=0&id=7a315c42&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a315c42",
  null
  
)

export default component.exports