const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')
const rmtColors = {
  // Theme
  dark: {
    default: 'rgb(0,17,51)',
    bg: 'rgb(51,65,92)',
  },
  light: 'rgb(255,255,255)',
  // White
  white: {
    default: 'rgb(255,255,255)',
    dim: 'rgba(255,255,255,0.15)',
    dimmer: 'rgba(255,255,255,0.05)',
  },
  // Gray
  gray: {
    default: 'rgba(92, 102, 123, 1)',
    darker: 'rgba(243, 244, 246, 1)',
    light: 'rgba(0,17,51,0.15)',
    lighter: 'rgba(0,17,51,0.05)',
    lightest: 'rgba(0,17,51,0.02)',
    'gray-stp-message': 'rgba(165, 170, 181, 1)',
    'address-gray': 'rgba(136, 143, 159, 1)',
    primary: 'rgba(92, 102, 123, 1)',
    bg: 'rgb(250,250,251)',
    'lighter-active': 'rgba(0,17,51,0.10)',
    'light-1_2': 'rgba(0,17,51,0.075)',
    'gray-loader': 'rgba(235, 233, 233, 1)',
    'gray-loader-text': 'rgba(190, 193, 201)',
    active: 'rgba(232, 233, 234, 1)',
    400: 'rgba(232, 235, 237, 1)',
    300: 'rgba(242, 243, 245, 1)',
    'blue-200': 'rgba(136, 144, 160, 1)',
    'blue-400': 'rgba(49, 63, 91, 1)',
  },
  // Aux
  red: {
    default: 'rgba(237, 95, 116, 1)',
    lighter: 'rgba(255,51,85,0.15)',
    text: 'rgba(205, 61, 100, 1)',
  },
  // Orange will inherit all the blue palette
  get orange() {
    return this.blue
  },
  blue: {
    default: 'rgba(0,108,224,1)',
    light: 'rgba(243,244,246,1)',
    lighter: 'rgba(221,221,221,1)',
    lightest: 'rgba(255, 97, 0, 0.05)',
    text: 'rgba(0,108,224,1)',
    'button-hover': 'rgb(38,130,229)',
    'button-pressed': 'rgb(65,147,233)',
    'button-focused': 'rgb(0,108,224)',
    'button-disabled': 'rgba(0, 108, 224, 0.2)',
    100: 'rgba(218, 232, 245, 1)',
    300: 'rgba(0, 97, 202, 1)',
    radio: 'rgba(240, 247, 253, 1)',
  },
  yellow: {
    default: 'rgba(244, 190, 88, 1)',
    lighter: 'rgba(255,187,0,0.15)',
    text: 'rgb(178,96,0)',
    'mustard-100': 'rgba(249, 245, 235, 1)',
    'mustard-400': 'rgba(200, 144, 38, 1)',
  },
  green: {
    default: 'rgba(25, 166, 114, 1)',
    lighter: 'rgba(34,204,102,0.15)',
    text: 'rgba(8, 129, 92, 1)',
    valid: 'rgb(9,146,67)',
    lightest: 'rgba(9, 212, 115, 1)',
  },
  purple: {
    default: 'rgba(156, 130, 219, 1)',
    lighter: 'rgba(153,102,255,0.15)',
    text: 'rgba(130, 95, 195, 1)',
  },
  pink: {
    default: 'rgb(255,68,187)',
    lighter: 'rgba(255,68,187,0.15)',
    text: 'rgb(196,47,142)',
  },
  // Remove what is left of tailwind
  teal: false,
  indigo: false,
  transparent: 'transparent',
  main: {
    blue: 'rgba(10, 20, 110, 1)',
    cyan: 'rgba(0, 108, 224, 1)',
    black: 'rgba(51, 51, 51, 1)',
    white: 'rgba(248, 249, 250, 1)',
  },
  text: {
    'gray-primary': 'rgba(92, 102, 123, 1)',
    'gray-secondary': 'rgba(174, 179, 190, 1)',
    green: 'rgba(8, 129, 92, 1)',
    orange: 'rgba(229, 112, 73, 1)',
    red: 'rgba(205, 61, 100, 1)',
    purple: 'rgba(130, 95, 195, 1)',
  },
  icon: {
    green: 'rgba(25, 166, 114, 1)',
    orange: 'rgba(229, 112, 73, 1)',
    red: 'rgba(229, 112, 73, 1)',
    purple: 'rgba(156, 130, 219, 1)',
    yellow: 'rgba(244, 190, 88, 1)',
  },
  primary: {
    text: 'rgba(51,51,51,1)',
    'dark-text': 'rgb(255,255,255)',
  },
  secondary: {
    text: 'rgba(0,17,51,0.6)',
    'dark-text': 'rgba(255,255,255,0.7)',
  },
  tertiary: {
    text: 'rgba(0,17,51,0.3)',
    'dark-text': 'rgba(255,255,255,0.4)',
    'grey-darkest': 'rgba(221, 221, 221, 1)',
    'gray-darker': 'rgba(243, 244, 246, 1)',
    'gray-lighter': 'rgba(250, 251, 253, 1)',
    'gray-lightest': 'rgba(252, 252, 252, 1)',
    blue: 'rgba(242, 247, 254, 1)',
    red: 'rgba(255, 225, 230, 1)',
    green: 'rgba(242, 254, 248, 1)',
  },
}

module.exports = {
  purge: [],
  theme: {
    colors: rmtColors,
    screens: {
      xs: '1px',
      sm: '600px',
      md: '900px',
    },
    transitionDuration: {
      90: '90ms',
      180: '180ms',
      270: '270ms',
      360: '360ms',
      450: '450ms',
      540: '540ms',
      630: '630ms',
      720: '720ms',
      810: '810ms',
      900: '900ms',
      990: '990ms',
    },
    transitionDelay: {
      90: '90ms',
      180: '180ms',
      270: '270ms',
      360: '360ms',
      450: '450ms',
      540: '540ms',
      630: '630ms',
      720: '720ms',
      810: '810ms',
      900: '900ms',
      990: '990ms',
    },
    extend: {
      borderWidth: {
        3: '3px',
      },
      height: {
        14: '3.5rem',
        18: '4.5rem',
      },
      width: {
        14: '3.5rem',
        18: '4.5rem',
        100: '25rem',
        '9/20': '45%',
      },
      maxWidth: {
        '1/2xl': '40rem',
      },
      maxHeight: {
        100: '25rem',
      },
      marginBottom: {
        0.5: '0.125rem',
      },
      zIndex: {
        100: '100',
      },
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
      },
      boxShadow: {
        'ria-1': `0px 3px 15px rgba(0, 17, 51, 0.05)`,
        'ria-2': '0px 10px 40px rgba(0, 17, 51, 0.1)',
        'ria-3': '0px 10px 40px rgba(0, 17, 51, 0.15)',
        'inset-1': 'inset -1px 1px 0 rgba(0, 17, 51, 0.05)',
        'outline-element-focus': `0 0 0 1px ${rmtColors.orange.default}`,
        'outline-input-focus': `inset 0 0 0 1px ${rmtColors.orange.default}`,
        'outline-input-focus-error': `inset 0 0 0 1px ${rmtColors.red.default}`,
        'outline-input-orange': `0 0 0 1px ${rmtColors.orange.default}`,
        'outline-input-red': `0 0 0 1px ${rmtColors.red.default}`,
        'outline-orange': `0 0 0 2px ${rmtColors.orange.lighter}`,
      },
      lineHeight: {
        11: '2.75rem',
        12: '3rem',
      },
      letterSpacing: {
        tightest: '-0.025em',
        tighter: '-0.015624em',
        tight: '-0.009375em',
        normal: '0',
        wide: '0.009375em',
        wider: '0.015624em',
        widest: '0.025em',
      },
      spacing: {
        '0_5': '0.125rem',
        '1_5': '0.375rem',
        '2_5': '0.625rem',
        '3_5': '0.875rem',
        '4_5': '1.125rem',
        18: '4.5rem',
      },
      borderRadius: {
        sm: '0.125rem',
        md: '0.25rem',
        lg: '0.375rem',
        xl: '0.75rem',
        xxl: '2rem',
      },
      animation: {
        float: 'float 5s infinite',
      },
      keyframes: {
        float: {
          '0%': {
            transform: 'translateY(-10px) rotate(0deg)',
          },
          '40%': {
            transform: 'translateY(0px) rotate(3deg)',
          },
          '60%': {
            transform: 'translateY(0px) rotate(-3deg)',
          },
          '100%': {
            transform: 'translateY(-10px) rotate(0deg)',
          },
        },
      },
      opacity: {
        10: '.1',
        20: '.2',
        30: '.3',
        40: '.4',
        60: '.6',
        70: '.7',
        80: '.8',
        90: '.9',
      },
    },
  },
  variants: {},
  plugins: [
    plugin(function ({ addUtilities, config }) {
      const typesets = {
        '.type-h1': {
          fontSize: config('theme.fontSize.2xl'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.8'),
        },
        '.type-h2': {
          fontSize: config('theme.fontSize.xl'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.7'),
        },
        '.type-h3': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-number': {
          fontSize: config('theme.fontSize.lg'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-subtitle': {
          fontSize: config('theme.fontSize.base'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-subtitle-bold': {
          fontSize: config('theme.fontSize.base'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-body': {
          fontSize: '15px',
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-body-bold': {
          fontSize: '15px',
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-caption': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.5'),
        },
        '.type-caption-bold': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.5'),
        },
        '.type-caption-xs': {
          fontSize: config('theme.fontSize.xs'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.widest'),
          lineHeight: config('theme.lineHeight.4'),
        },
        '.type-overline': {
          fontSize: config('theme.fontSize.sm'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.widest'),
          lineHeight: config('theme.lineHeight.5'),
          textTransform: 'uppercase',
        },
        '.type-button': {
          fontSize: config('theme.fontSize.base'),
          fontWeight: config('theme.fontWeight.medium'),
          letterSpacing: config('theme.letterSpacing.wide'),
          lineHeight: config('theme.lineHeight.6'),
        },
        '.type-helper': {
          fontSize: config('theme.fontSize.xs'),
          fontWeight: config('theme.fontWeight.normal'),
          letterSpacing: config('theme.letterSpacing.wider'),
          lineHeight: config('theme.lineHeight.4'),
        },
        // Type using new STP font variant
        '.type-stp-title': {
          fontSize: config('theme.fontSize.3xl'),
          fontWeight: config('theme.fontWeight.semibold'),
          fontStyle: 'normal',
          lineHeight: config('theme.lineHeight.10'),
        },
      }
      addUtilities(typesets)
    }),
    require('@tailwindcss/ui'),
  ],
}
