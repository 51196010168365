import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import AddressDetails from '@/models/CorporateRegistration/AddressDetails/app'

export default new APIHandler(
  API,
  ({ addressId }) => {
    addressId = encodeURIComponent(addressId)
    return {
      method: 'GET',
      url: `address/${addressId}`,
    }
  },
  ({ data: { data: { items = [] } = {} } = {} }) => {
    return {
      data: items.map((item) => {
        const decoded = {}
        for (const key in item) {
          try {
            decoded[key] = decodeURIComponent(item[key])
          } catch (err) {
            console.error(`Error decoding "${key}": ${err.message}`)
            decoded[key] = item[key]
          }
        }
        return new AddressDetails(decoded)
      }),
    }
  }
)
