/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole trader, Private Limited Company (Ltd), Publicly Traded Partnership, Public Limited Company (PLC), Partnerships, Entrepreneurship Company (with limited liability), Charity
 */

export const regionValues = {
  regionId: 205,
  defaultCountryFrom: 'DE',
  defaultCountryFromOnfido: 'DEU',
  defaultCountryCodeFrom: 49,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'de-DE',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{5}$`,
  availableLanguageCultures: ['de-DE', 'en-DE'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['1701', '1702', '1703', '1704', '1705', '1706', '1707'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['1701'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
