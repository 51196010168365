import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import { logrocketSetting } from '@/composables/useSession'
import LogRocket from 'logrocket'

import { useAnalyticsStore } from '@/stores/analytics'

export const useSessionStore = defineStore(
  'session',
  () => {
    // ------- STORES -------
    const analyticsStore = useAnalyticsStore()

    // ------- STATE -------
    const session = ref(null)
    const sessionId = ref(null)
    const logRocketSessionUrl = ref(null)

    // ------- ACTIONS -------

    const initLogRocket = (userId) => {
      let settings
      // Set options based on the environment
      if (config.VUE_APP_ENVIRONMENT === 'integration') {
        // Development env settings
        settings = logrocketSetting({
          // DOM video recording - https://docs.logrocket.com/v1.0/reference/dom
          dom: {
            isEnabled: true, //disable|enable all DOM recording
            inputSanitizer: false, // obfuscate all user-input elements <select> and <input>
          },
          shouldCaptureIP: false,
        })
      } else {
        // Staging, prod env settings
        settings = logrocketSetting(
          {
            dom: {
              isEnabled: true, //disable|enable all DOM recording
              inputSanitizer: true, // obfuscate all user-input elements <select> and <input>
            },
            shouldCaptureIP: false,
          },
          true // Set network sanitize for logRocket
        )
      }

      // Log Rocket init - // https://docs.logrocket.com/v1.0/reference/init
      LogRocket.init(config.VUE_APP_KEYS_LOGROCKET_KEY, settings)
      // Session URL Handler
      LogRocket.getSessionURL((sessionUrl) => {
        analyticsStore.track({
          userId,
          event: 'LogRocket',
          traits: {
            sessionURL: sessionUrl,
          },
        })

        logRocketSessionUrl.value = sessionUrl
      })
    }

    // ------- GETTERS -------

    return {
      session,
      sessionId,
      logRocketSessionUrl,
      initLogRocket,
    }
  },

  {
    persist: {
      storage: sessionStorage,
    },
  }
)
