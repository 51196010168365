/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Publicly Traded Partnership, Sole trader, General Partnership, Limited Partnership
 */
export const regionValues = {
  regionId: 1105,
  defaultCountryFrom: 'BE',
  defaultCountryFromOnfido: 'BEL',
  defaultCountryCodeFrom: 32,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'fr-BE',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  availableLanguageCultures: ['fr-BE', 'en-BE', 'nl-BE'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2801', '2802', '2803', '2804', '2805', '2806'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2804'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
