import pendingActionsConfig from '@/config/pendingActions'

export default (companyType, country) => {
  let soleTypes = ['Sole Proprietorship', 'Sole Proprietor', 'Sole Trader']
  // for now include all EU/APAC country company types under LIMITED
  let limitedTypes = [
    'AUS Domestic Public Listed',
    'AUS Limited',
    'Association',
    'Charity',
    'Cooperative',
    'Corporate Trust',
    'Entrepreneurship Company (with limited liability)',
    'Establishment',
    'Foundation',
    'Foundations and Associations',
    'General Partnership',
    'General Partnership (use optional)',
    'General Partnership with Mutual Liability',
    'Group Practice (of professionals)',
    'Incorporated Association',
    'Investment Trust',
    'Joint Stock Company',
    'LTD',
    'Labour Limited Corporation',
    'Limited Company',
    'Limited Liability Company',
    'Limited Liability Cooperative',
    'Limited Liability Partnership',
    'Limited Liability Sports Corporation',
    'Limited Partnership',
    'Limited Partnership with Shares',
    'Listed Public Company',
    'Ltd',
    'NZ Limited',
    'NZ/AU Government Bodies',
    'NZ/AU Incorporated',
    'NZ/AU Registered Co-operatives',
    'PLC',
    'Partnership',
    'Partnerships',
    'Private Company',
    'Private Limited Company',
    'Private Limited Company (Ltd.)',
    'Private Trust',
    'Privately Held (closely held) corporation',
    'Proprietary Limited',
    'Public Limited Company',
    'Public Limited Company (plc)',
    'Public Limited Company/Subsidiary',
    'Publicaly Traded Corporation',
    'Publicly Traded Partnership',
    'Trust',
    'Unincorporated',
    'Unincorporated Association',
    'Limited',
  ]
  if (country && pendingActionsConfig.allowedForCompanyTypeMapper.includes(country)) {
    switch (companyType) {
      case 'Sole Proprietor':
      case 'Sole Trader':
      case 'Sole Proprietorship':
        return 'SOLE'
      case 'Limited':
        return 'LIMITED'
      case 'Corporation':
      case 'Limited Liability Company':
        return 'LLC'
      case 'Private Corporation':
        return 'PRIVATE'
      case 'Other':
        return 'NOT SUPPORTED'
    }
    return 'NOT SUPPORTED'
  }

  if (soleTypes.includes(companyType)) {
    return 'SOLE'
  } else if (limitedTypes.includes(companyType)) {
    return 'LIMITED'
  } else {
    return 'NOT SUPPORTED'
  }
}