<template>
  <Transition
    :name="transition"
    appear
    @beforeEnter="onShowing"
    @enter="onShow"
    @afterEnter="onShown"
    @beforeLeave="onHiding"
    @leave="onHide"
    @afterLeave="onHidden"
  >
    <div
      v-if="showMask"
      class="overlay-mask"
      :class="{ 'overflow-hidden': !showContent }"
      @mousedown.self="onMouseDownMask"
      @mouseup.self="onMouseUpMask"
    >
      <Transition appear :name="transitionType" @leave="onContentLeave">
        <div
          v-if="showContent"
          class="overlay-content"
          @mousedown="onMouseOverlayContent"
          @mouseup="onMouseOverlayContent"
        >
          <!-- Default slot -->
          <slot />
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import useAppOverlay from '@/composables/useAppOverlay'

export default {
  name: 'AppOverlay',
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'fade',
    },
    transitionType: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const {
      dismiss,
      showOverlay,
      onMouseDownMask,
      onMouseUpMask,
      onMouseOverlayContent,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    } = useAppOverlay(props, emit)

    const showContent = computed(() => {
      if (props.transitionType) {
        return showOverlay.value
      }
      return true
    })

    const showMask = ref(props.value)
    watch(showOverlay, (v) => {
      if (v) {
        showMask.value = true
      } else if (!v && !props.transitionType) {
        showMask.value = false
      }
    })

    const onContentLeave = () => {
      showMask.value = false
    }

    return {
      dismiss,
      showMask,
      showContent,
      onContentLeave,
      showOverlay,
      onMouseDownMask,
      onMouseUpMask,
      onMouseOverlayContent,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    }
  },
}
</script>

<style scoped>
.overlay-mask {
  &::-webkit-scrollbar-track {
    background-color: #b2b7c1; /* otherwise we could see several scrollbars (behind the mask) */
  }
  &::-webkit-scrollbar-thumb {
    border-color: #b2b7c1;
  }

  @apply fixed top-0 right-0 bottom-0 left-0 z-50;
  @apply overflow-y-auto;
  @apply flex items-center justify-center;
  @apply bg-tertiary-text;

  &.fade-enter-active,
  &.fade-leave-active {
    @apply overflow-hidden;
  }

  .overlay-content {
    @apply m-auto;
  }
}
</style>
