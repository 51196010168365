/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole Trader, Listed Public Company, Partnership, Proprietary Limited, Trust, Cooperative, Incorporated Association, Unincorporated Association
 */
export const regionValues = {
  defaultCountryFrom: 'NF',
  defaultCurrencyFrom: 'AUD',
  //allowedCompanyTypesForStp: ['4712', '4713', '4714', '4715', '4716', '4717', '4718', '4719'],
  //allowedSTPCountry: true,
  //marketingPromptCompanies: ['4712'],
  postalCodeRegex: `^[0-9 -]{5,5}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
