import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'
import { map, flow, sortBy } from 'lodash-es'

// Config
import countrySpecificText from '@/cms/countrySpecificPendingActionsText.json'

// ------- Stores -------
import countriesConfig from '@/regions/countriesConfig'

export const useCountriesStore = defineStore(
  'countries',
  () => {
    // ------- STORES -------

    // ------- STATE -------
    const countries = ref([])
    const offeredCountries = ref([])
    const countrySettings = ref({})
    const agentSettings = ref({})

    // ------- ACTIONS -------
    const getCountrySpecificText = (resourceId, countryCode = 'US') => {
      return countrySpecificText[resourceId][countryCode]
    }

    // ------- GETTERS -------

    const offeredCountriesDialingCodesOptions = computed(() => {
      return flow(
        // Sort them by countryCode
        (countries) => sortBy(countries, 'countryCode'),
        // Map to text value pair
        (countries) =>
          map(countries, (country) => {
            const countryCode = country.countryCode.trim()
            return {
              text: `+${countryCode} ${country.nameAbbr}`,
              value: countryCode,
              id: country.id,
            }
          })
      )(countries.value)
    })

    const getCountryById = (id) => {
      return countries.value.find((country) => country.id === id)
    }

    const getCountryByIsoCode = (isoCode) => {
      return countries.value.find((country) => country.nameAbbr === isoCode)
    }

    const getCountryConfigByCountryCode = (countryCode) => {
      return countriesConfig.find((config) => config.defaultCountryFrom === countryCode)
    }

    return {
      countries,
      offeredCountries,
      countrySettings,
      agentSettings,
      offeredCountriesDialingCodesOptions,
      getCountryById,
      getCountryByIsoCode,
      getCountryConfigByCountryCode,
      getCountrySpecificText,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
