import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import router from '@/router'

import { useAnalyticsStore } from '@/stores/analytics'
import { useResourcesStore } from '@/stores/resources'
import { useDeviceStore } from '@/stores/device'

export const useAppStore = defineStore('app', () => {
  // ------- STORES -------
  const analyticsStore = useAnalyticsStore()
  const resourcesStore = useResourcesStore()
  const deviceStore = useDeviceStore()

  // ------- STATE -------
  const messageBox = ref(null)
  const hasMessage = ref(false)
  const version = ref('')
  const loaded = ref(false)
  const isDashboardLoaded = ref(false)

  // ------- ACTIONS -------
  const messageBoxGenericError = async () => {
    await router.push({
      name: 'RegisterError',
      props: {
        errorType: 'general',
      },
    })
  }

  const hideMessageBox = async () => {
    hasMessage.value = false
  }

  const logException = async ({ text, exception, fireEvent }) => {
    // TODO: Update how we need to handle app exceptions beside doing a console.error
    // https://euronet.atlassian.net/wiki/spaces/XSS/pages/2694545409/Corp+Reg+-+Front+End+Tech+Debt
    // eslint-disable-next-line no-console
    console.error('EXCEPTION: ' + text)
    // eslint-disable-next-line no-console
    console.error(exception)
    if (fireEvent) {
      await analyticsStore.track({ event: text, traits: { exceptionMessage: exception } })
    }
  }

  const setup = async () => {
    await resourcesStore.getSystemFields(deviceStore.country)
  }

  const setVersion = () => {
    const xeVersion = config.VUE_APP_XE_VERSION || 'development'
    version.value = xeVersion
  }

  const setLoadedState = (value) => {
    loaded.value = value
  }

  const setIsDashboardLoaded = (isDashboardLoaded) => {
    isDashboardLoaded.value = isDashboardLoaded
  }

  // ------- GETTERS -------

  //   no getters migrated because they can be accesssed directly from the state.

  return {
    messageBox,
    hasMessage,
    version,
    loaded,
    isDashboardLoaded,
    messageBoxGenericError,
    hideMessageBox,
    logException,
    setup,
    setVersion,
    setLoadedState,
    setIsDashboardLoaded,
  }
})
