<template>
  <AppDialog v-if="messageBox" v-model="hasMessage" class="message-box">
    <template #header>
      <AppDialogHeader>
        <h3 v-if="messageBox.title">{{ messageBox.title }}</h3>
      </AppDialogHeader>
    </template>
    <p>
      {{ messageBox.text }}
    </p>
    <template #footer>
      <AppDialogFooter v-if="messageBox.buttons">
        <AppButton
          v-for="(button, idx) of messageBox.buttons"
          :key="idx"
          :analytics-name="analyticsName ? analyticsName + '-button-' + idx : null"
          :theme="button.theme"
          @click="onMessageBoxButton(button)"
        >
          {{ button.text }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import AppDialog from '@/components/AppDialog/AppDialog'
import AppDialogHeader from '@/components/AppDialogHeader/AppDialogHeader'
import AppButton from '@/components/AppButton/AppButton'
import { useStore } from '@/composables/useStore'
import { computed } from '@vue/composition-api'
import { useAppStore } from '@/stores/app'

import AppDialogFooter from '@/components/AppDialogFooter/AppDialogFooter'
//import { getConfig, configKeys } from '@/environments/environment'
export default {
  name: 'RiaMessageBox',
  components: { AppDialogFooter, AppButton, AppDialogHeader, AppDialog },
  props: {
    analyticsName: {
      type: String,
      default: 'message-box',
      //required: !!getConfig(configKeys.VUE_APP_ANALYTICS_ENABLED),
    },
  },
  setup() {
    const store = useStore()
    const appStore = useAppStore()

    const messageBox = computed(() => {
      return appStore.messageBox
    })
    const hasMessage = computed(() => {
      return appStore.hasMessage
    })
    const onMessageBoxButton = (button) => {
      if (button.click) {
        if (!button.click()) {
          return
        }
      }
      appStore.hideMessageBox()
    }
    return {
      messageBox,
      hasMessage,
      onMessageBoxButton,
    }
  },
}
</script>

<style scoped>
.message-box {
  z-index: 1500; /* important otherwise primevue dropdowns would overlap since they use a zindex around 1000 */
}
</style>
