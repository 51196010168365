// Imports
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import { useAppStore } from '@/stores/app'

// Element to obfuscate
import fieldsToSanitize from './FieldsToSanitize.json'
import urlToSanitize from './urlToSanitize.json'

/*
 * Set network option based on fields and url to be sanitized
 * @param {object} - fieldToSanitize - field names to be obfuscate
 * @param {object} - urlToSanitize - body url's to be obfuscate
 */
const requestSanitizer = (fieldsToSanitize, urlToSanitize) => {
  // Set field names that would like to sanitize (obfuscate) -> logrocket fuzzy search sanitizer
  const privateFieldNames = fieldsToSanitize.privateFieldNames
  const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([...privateFieldNames])

  // Header sanitizer config
  const sanitizeHeader = (network) => {
    if (network.headers) {
      for (const key in network.headers) {
        const header = key.toLowerCase()
        if (header === 'authorization' || header === 'bearer') {
          network.headers[key] = '*'
        }
      }
    }
  }

  // Body Sanitizer config
  const sanitizeBodyUrls = urlToSanitize
  const sanitizeBody = (network) => {
    if (sanitizeBodyUrls.length > 0 && sanitizeBodyUrls.some((url) => network.url.indexOf(url) !== -1)) {
      if (network.method !== 'GET') {
        network.body = null
      }
    }
  }

  // Set network sanitizer option - https://docs.logrocket.com/reference/network
  const network = {
    requestSanitizer: (request) => {
      sanitizeHeader(request)
      sanitizeBody(request)
      return requestSanitizer(request)
    },
    responseSanitizer: (response) => {
      sanitizeHeader(response)
      sanitizeBody(response)
      return responseSanitizer(response)
    },
  }

  return network
}

/*
 * Set logRocket settings
 * @param {object} - options - custom options
 * @param {boolean} - obfuscate - Sanitize request?
 */
export function logrocketSetting(options = {}, obfuscate = false) {
  const appStore = useAppStore()

  // Setting default options
  const settings = {
    // App release - https://docs.logrocket.com/reference/release
    release: `${appStore.version}`,
  }

  // Set sanitizer
  if (obfuscate) {
    return {
      ...settings,
      network: requestSanitizer(fieldsToSanitize, urlToSanitize),
      ...options,
    }
  }
  return { ...settings, ...options }
}
