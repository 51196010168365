/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'IM',
  allowedCompanyTypesForStp: ['4201', '4202', '4203'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4201'],
  postalCodeRegex: `^IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
