import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'
import router from '@/router'
import { useCountriesStore } from '@/stores/countries'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import config from '@/config/register'
import { getRegionFromCountry } from '@/utils/i18n'

export const useRegistrationStore = defineStore(
  'registration',
  () => {
    // ------- STORES -------
    const countriesStore = useCountriesStore()
    const companyDetailsStore = useCompanyDetailsStore()

    // ------- STATE -------
    const steps = ref([
      {
        title: 'Account',
        path: '/registration',
        pageTitle: 'Create an account',
        titleKey: 'PageRegister.StepAccountTitle',
        pageTitleKey: 'PageRegister.StepAccountPageTitle',
        // $t('PageRegister.StepAccountTitle')
        // $t('PageRegister.StepAccountPageTitle')
      },
      {
        title: 'Verification',
        path: '/registration/verification',
        pageTitle: 'Verification',
        titleKey: 'PageRegister.StepVerificationTitle',
        pageTitleKey: 'PageRegister.StepVerificationPageTitle',
        // $t('PageRegister.StepVerificationTitle')
        // $t('PageRegister.StepVerificationPageTitle')
      },
      {
        title: 'Info',
        path: '/registration/info',
        pageTitle: 'Personal information',
        titleKey: 'PageRegister.StepInfoTitle',
        pageTitleKey: 'PageRegister.StepInfoPageTitle',
        // $t('PageRegister.StepInfoTitle')
        // $t('PageRegister.StepInfoPageTitle')
      },
      {
        title: 'Address',
        path: '/registration/address',
        pageTitle: 'Your address',
        titleKey: 'PageRegister.StepAddressTitle',
        pageTitleKey: 'PageRegister.StepAddressPageTitle',
        // $t('PageRegister.StepAddressTitle')
        // $t('PageRegister.StepAddressPageTitle')
      },
    ])
    const activeStepIdx = ref(0)
    const profileId = ref(0)
    const email = ref('')
    const password = ref('')
    const isSubmitRegistration = ref(false)
    const form = ref({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      mobilePhone: '',
      mobilePrefix: '+1',
      addressLine1: '',
      place: '',
      postalCode: '',
      unit: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      state: '',
      country: 'US',
    })
    const stpConfig = ref({})
    const redirectToOldFlow = ref(true)
    const formattedAddress = ref('')
    const registrationCompleted = ref(false)
    const registrationConfig = ref({})

    // ------- ACTIONS -------

    const setRegistrationConfig = () => {
      registrationConfig.value = config
    }

    const checkRedirectSimplifiedFlow = () => {
      let isRegistrationRoute = false
      if (router.history?.current?.matched) {
        isRegistrationRoute =
          router.history.current.matched.findIndex((route) => route.name === 'register') >= 0
      }
      if (isRegistrationRoute) {
        // non-stp countries are redirected
        redirectToOldFlow.value = !isStpAllowedCountry()

        // Push the route if needed
        const registerRouteName = isStpAllowedCountry()
          ? 'RegisterPersonalDetails'
          : 'RegisterBusinessInformation'

        const currentRouteName = router.history.current.name
        if (currentRouteName !== registerRouteName) {
          router.push({ name: registerRouteName })
        }
      }
    }

    const initStpConfig = () => {
      stpConfig.value = require('@/config/stpConfig')
    }

    const resetForm = () => {
      stpConfig.value = {}
      formattedAddress.value = ''
      isSubmitRegistration.value = false
      profileId.value = 0
      email.value = ''
      password.value = ''
      activeStepIdx.value = 0
      form.value.firstName = ''
      form.value.lastName = ''
      form.value.dateOfBirth = ''
      form.value.mobilePhone = ''
      form.value.mobilePrefix = ''
      form.value.addressLine1 = ''
      form.value.place = ''
      form.value.postalCode = ''
      form.value.unit = ''
      form.value.streetNumber = ''
      form.value.streetName = ''
      form.value.streetType = ''
      form.value.state = ''
      form.value.country = 'US'
    }
    //  ------ ACTIONS ---------
    const getRegistrationStepConfigById = (id) => {
      if (stpConfig.value) {
        const registrationStep = stpConfig.value.registrationFlow.find((group) => group.id === id)
        return registrationStep || {}
      }
      return {}
    }
    // TODO external stores in use
    const isStpAllowedCountry = (country) => {
      // Check registered country
      const deviceCountry = country
      // Check for allowed countries
      const countryConfig = countriesStore.getCountryConfigByCountryCode(deviceCountry)
      // Return if country is allowed
      return countryConfig?.allowedSTPCountry || false
    }

    // TODO external stores in use
    const isStpAllowCompanyType = (country, companyType) => {
      // Get allowed company type
      const countryConfig = countriesStore.getCountryConfigByCountryCode(country)
      // Return if company type is allowed
      return countryConfig.allowedCompanyTypesForStp.includes(
        companyType ? companyType : companyDetailsStore.companyType
      )
    }

    // ------- GETTERS -------
    // TODO external stores in use
    const formFilledInformationAllowsStpFlow = computed(() => {
      // We use this value on business-type screen to validate if the user can continue registering their combination of country/company type
      const selectedCountry = companyDetailsStore.companyCountry
      const selectedCompanyType = companyDetailsStore.companyType
      const countryConfig = countriesStore.getCountryConfigByCountryCode(selectedCountry)
      return countryConfig?.allowedSTPCountry
        ? countryConfig.allowedCompanyTypesForStp.includes(selectedCompanyType)
        : false
    })

    const getStepOptions = computed(() => {
      if (redirectToOldFlow.value) {
        return [
          {
            title: 'Account',
          },
          {
            title: 'Verification',
          },
          {
            title: 'Business',
            path: '/',
            pageTitle: 'Business information',
          },
          {
            title: 'Personal',
            path: '/personal-details',
            pageTitle: 'Personal details',
          },
        ]
      } else {
        return stpConfig.value.registrationSteps
      }
    })

    const getInfoFormField = (fieldId) => {
      const infoFormFieldItem = form.value.infoForm[fieldId]
      return {
        value: infoFormFieldItem ? infoFormFieldItem.value : '',
      }
    }

    const getIsSoleCompanyType = computed(() => {
      const country = getRegionFromCountry(country)
        ? getRegionFromCountry(country)
        : companyDetailsStore.companyCountry
      const companyType = companyDetailsStore.companyType
      const allowedForMkPrompt =
        countriesStore.getCountryConfigByCountryCode(country)?.marketingPromptCompanies
      return allowedForMkPrompt ? allowedForMkPrompt.includes(companyType) : false
    })

    const getCountryOrRegionIsAllowedForStp = computed(() => {
      const country = getRegionFromCountry(country)
        ? getRegionFromCountry(country)
        : companyDetailsStore.companyCountry
      const companyType = companyDetailsStore.companyType
      const countryConfig = countriesStore.getCountryConfigByCountryCode(country || '')
      if (countryConfig) {
        // Check company type
        const isAllowedCompanyType =
          countryConfig?.allowedCompanyTypesForStp?.includes(companyType) || false

        // Return condition
        return ((countryConfig?.allowedSTPCountry || false) && isAllowedCompanyType) || false
      }
    })

    // ------- RETURN -------
    return {
      steps,
      activeStepIdx,
      profileId,
      email,
      password,
      isSubmitRegistration,
      form,
      stpConfig,
      redirectToOldFlow,
      formattedAddress,
      registrationCompleted,
      registrationConfig,
      setRegistrationConfig,
      checkRedirectSimplifiedFlow,
      initStpConfig,
      resetForm,
      getRegistrationStepConfigById,
      isStpAllowedCountry,
      isStpAllowCompanyType,
      formFilledInformationAllowsStpFlow,
      getStepOptions,
      getIsSoleCompanyType,
      getCountryOrRegionIsAllowedForStp,
      getInfoFormField,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
