// Merge two arrays of objects while handling duplicate IDs and maintaining arrayA's structure
export const mergeArrays = (arrayA, arrayB, id = 'id') => {
  // Create a map from arrayA where IDs are keys
  const idMap = new Map(arrayA.map((obj) => [obj[id], obj]))
  // Merge arrayB into idMap, replacing or adding objects based on ID
  arrayB.forEach((obj) => idMap.set(obj[id], obj)) // Use set to ensure unique IDs)
  // Convert idMap values back to an array while maintaining arrayA order
  const mergedArray = Array.from(idMap.values())
  // Return merged array
  return mergedArray
}

// Function to sort arrayToSort to follow the structure of referenceArray
export const sortArrayToMatchStructure = (items, referenceArray, key) => {
  // Extract keys from referenceArray to use for sorting and filtering
  const referenceKeys = referenceArray.map((obj) => obj[key])
  // Filter items to include only those with keys present in referenceArray
  const filteredItems = items.filter((item) => referenceKeys.includes(item[key]))
  // Sort filtered items based on the order of keys in referenceArray
  const sortedItems = filteredItems.sort((a, b) => {
    const indexA = referenceKeys.indexOf(a[key])
    const indexB = referenceKeys.indexOf(b[key])
    return indexA - indexB
  })
  // Remove items from items array that are not present in sortedItems
  const itemsToRemove = items.filter((item) => !referenceKeys.includes(item[key]))
  itemsToRemove.forEach((item) => {
    const index = items.indexOf(item)
    if (index !== -1) {
      items.splice(index, 1) // Remove item from original items array
    }
  })
  // Return sorted array
  return sortedItems
}
// Add an space separator
export const splitText = (item, key) => {
  return item[key]?.split(' ')
}

// Named based on useage. Check if one array contains the other
export const isSamePerson = (nameArr1, nameArr2) => {
  return nameArr1.every((v) => nameArr2.includes(v)) || nameArr2.every((v) => nameArr1.includes(v))
}
