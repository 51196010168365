<template>
  <button
    :type="type"
    class="button"
    :class="[themeClass, loadingClass]"
    :disabled="isDisabled"
    v-on="$listeners"
  >
    <AppRipple
      v-if="theme === 'icon'"
      class="button-inner"
      md-centered
      :md-disabled="isDisabled"
    >
      <slot></slot>
    </AppRipple>
    <AppRipple v-else class="button-inner" :md-disabled="isDisabled">
      <div v-if="$slots.leftIcon" class="button-left-icon">
        <!-- @slot leftIcon: For the left Icon -->
        <slot name="leftIcon" />
      </div>
      <slot />
      <div v-if="$slots.rightIcon" class="button-right-icon">
        <!-- @slot rightIcon: For the right Icon -->
        <slot name="rightIcon" />
      </div>
      <Transition name="icon-expand">
        <AppIcon
          v-if="loading"
          class="button-loading-icon animate-spin"
          size="18"
        >
          <IconSpinner />
        </AppIcon>
      </Transition>
    </AppRipple>
  </button>
</template>

<script>
import { computed } from '@vue/composition-api'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconSpinner } from '@moneytransfer.ui/euronet-icons'
export const themes = ['primary', 'secondary', 'text', 'icon']
export const types = ['button', 'submit']
export default {
  name: 'AppButton',
  components: { AppIcon, IconSpinner },
  props: {
    theme: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return themes.indexOf(value) !== -1
      },
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => {
        return types.indexOf(value) !== -1
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const themeClass = computed(() => {
      return `button--${props.theme}`
    })
    const loadingClass = computed(() => {
      return props.loading ? `button--loading` : null
    })
    const isDisabled = computed(() => {
      return attrs.disabled || props.loading
    })
    return {
      themeClass,
      loadingClass,
      isDisabled,
    }
  },
}
</script>

<style lang="postcss" scoped>
.button {
  @apply rounded-xxl;
  @apply outline-none box-border;
  /* Modifiers */
  &.button--loading {
    @apply cursor-default;
  }
  &.button--primary {
    @apply text-white bg-orange;
    .button-inner {
      @apply px-8;
    }
    &:hover {
      @apply bg-orange-button-hover;
    }
    &:active:not(:disabled) {
      @apply bg-orange-button-pressed;
    }
    &:focus {
      @apply bg-orange-button-focused;
    }
    &:disabled:not(.button--loading) {
      @apply text-tertiary-text bg-gray-light;
    }
  }
  &.button--secondary {
    @apply border;
    @apply text-orange border-orange-light;
    .button-inner {
      @apply px-6;
    }
    &:hover {
      @apply bg-transparent;
    }
    &:active:not(:disabled) {
    }
    &:focus {
      @apply bg-transparent;
    }
    &:disabled:not(.button--loading) {
      @apply text-tertiary-text border-gray-light;
    }
  }
  &.button--text {
    @apply text-orange;
    .button-inner {
      @apply py-2;
    }
    &:hover {
      @apply bg-transparent;
    }
    &:active:not(:disabled) {
    }
    &:focus {
      @apply bg-transparent;
    }
    &:disabled:not(.button--loading) {
      @apply text-tertiary-text;
    }
  }
  &.button--icon {
    @apply text-secondary-text;
    .button-inner {
      @apply p-0;
      @apply w-12 h-12;
    }
    svg {
      @apply w-6 h-6;
    }
    &:hover {
      @apply bg-gray-lightest text-primary-text;
    }
    &:active:not(:disabled) {
      @apply text-primary-text;
    }
    &:focus {
      @apply bg-gray-lighter text-primary-text;
    }
    &:disabled:not(.button--loading) {
      @apply text-tertiary-text;
    }
  }
}
.button-inner {
  @apply inline-flex items-center justify-center;
  @apply type-button;
  @apply px-4 py-3;
  @apply rounded-xxl;
  @apply transition ease-in-out duration-180;
  &:disabled {
    @apply text-tertiary-text;
  }
  &:focus {
    @apply shadow-ria-1;
  }

  /* Elements */
  .button-left-icon,
  .button-right-icon {
    @apply flex items-center;
    /deep/ svg {
      @apply w-4_5 h-4_5;
    }
  }
  .button-left-icon {
    @apply mr-2;
    &:first-child {
      @apply -ml-1_5;
    }
  }
  .button-right-icon {
    @apply ml-2;
    @apply -mr-1_5;
  }
  .button-loading-icon {
    @apply w-5 relative left-2.5;
  }

  /deep/ .md-ripple-enter-active {
    @apply duration-630;
    &.md-centered {
      @apply duration-630;
    }
  }
}
</style>
