import {
  setClienAffiliateCookie,
  setDfxIdCookie,
  setOptimzelyOptOut,
  cleanCookies,
  consentState,
} from './useConsent'

import { useConsentStore } from '@/stores/consent'

export const consentManager = () => {
  const consentStore = useConsentStore()
  const queryParams = new URLSearchParams(window.location.search)
  const leadSourcesState = {}

  // Try set the lead source state with the value from the query params,
  // defaulting to the existing cookie value if the query param is invalid
  leadSourcesState['dfxId'] =
    setDfxIdCookie(queryParams.get('_dfxid')) || consentStore.getDfxIdFromCookie
  leadSourcesState['clientAffiliate'] =
    setClienAffiliateCookie(queryParams.get('_clientagentid')) ||
    consentStore.getClientAffiliateIdFromCookie

  // user does not have xeConsent cookie, so store leadsources in localstorage until user accepts cookie modal in accounts ui
  if (!consentState) {
    cleanCookies()
  }
  consentStore.leadSourcesState = leadSourcesState

  // user has xeConset cookie but declined performance tracking
  if (consentState && !consentState.performance) {
    cleanCookies()
    consentStore.leadSourcesState = {}
  }

  setOptimzelyOptOut(consentState)

  return consentState
}
