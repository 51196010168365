import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

import getCompanyTypeFromApi from '@/api/corporate/companyType/get'
import companyNameSearch from '@/api/corporate/companySearch/get'
import { newRegNumber } from '@/utils/prefix'
import { getMappedCountry } from '@/utils/i18n'

export const useCompanyDetailsStore = defineStore(
  'companyDetails',
  () => {
    // ------- STATE -------
    const companyCountry = ref('')
    const companyType = ref('')
    const companyDetails = ref({})
    const companyAddress = ref({
      id: '',
      companyName: '',
      streetNumber: '',
      streetName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      area: '',
      countryCode: '',
      postCode: '',
      state: '',
      county: '',
      consolidationCode: '',
      naceCode: '',
      naceLabel: '',
      incorporationDate: '',
      nationalId: [],
      vatNumber: [],
      website: [],
    })
    const companyOperatingAddress = ref({
      id: '',
      companyName: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      area: '',
      countryCode: '',
      postCode: '',
      state: '',
      county: '',
      consolidationCode: '',
      naceCode: '',
      naceLabel: '',
      incorporationDate: '',
      nationalId: [],
      vatNumber: [],
      website: [],
    })
    const companyTypeList = ref([])
    const signingAuthority = ref(false)
    const hasCompanyTypeChanged = ref(false)

    // ------- ACTIONS -------

    const getCompanyTypeList = async (country) => {
      const { data } = await getCompanyTypeFromApi.exec({ country })
      if (data) {
        companyTypeList.value = data
      }
    }

    const setCompanyCountry = async (country) => {
      companyCountry.value = country
      if (country) {
        await getCompanyTypeList(country)
      }
    }

    const getCompanyNameSearchResult = async ({ name, country }) => {
      const newCountry = await getMappedCountry(country)
      const newName = await newRegNumber(name, newCountry)
      const { data } = await companyNameSearch.exec({ name: newName, country: newCountry })
      if (data) {
        return data
      }
    }

    const resetForm = () => {
      companyCountry.value = ''
      companyType.value = ''
      companyDetails.value = {}
      companyAddress.value = {
        id: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        countryCode: '',
        postCode: '',
        state: '',
        county: '',
        consolidationCode: '',
        naceCode: '',
        naceLabel: '',
        incorporationDate: '',
        nationalId: [],
        vatNumber: [],
        website: [],
        streetNumber: '',
        streetName: '',
        area: '',
      }
      companyOperatingAddress.value = {
        id: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        countryCode: '',
        postCode: '',
        state: '',
        county: '',
        consolidationCode: '',
        naceCode: '',
        naceLabel: '',
        incorporationDate: '',
        nationalId: [],
        vatNumber: [],
        website: [],
        streetNumber: '',
        streetName: '',
        area: '',
      }
      signingAuthority.value = false
    }

    // ------- GETTERS -------

    return {
      companyCountry,
      companyType,
      companyDetails,
      companyAddress,
      companyOperatingAddress,
      companyTypeList,
      signingAuthority,
      hasCompanyTypeChanged,
      getCompanyTypeList,
      setCompanyCountry,
      getCompanyNameSearchResult,
      resetForm,
    }
  },

  {
    persist: {
      storage: sessionStorage,
    },
  }
)
