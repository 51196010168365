/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'EE',
  allowedCompanyTypesForStp: ['3901', '3902', '3903'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3901'],
  postalCodeRegex: `^\\d{5}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
