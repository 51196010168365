export default class VerifyResponse {
  constructor(verifyResponse) {
    const { data, errors } = verifyResponse
    this.kycRequirements = data.kycRequirements.map((item) => new KycRequirement(item))
    this.verified = data.verified
    this.isExtraInfoRequired = !!errors?.find((err) => err.id === 'extraInfoRequired')
  }
}

class KycRequirement {
  constructor(kycRequirement) {
    this.id = kycRequirement.id
    this.type = kycRequirement.type
    this.mandatory = kycRequirement.mandatory
    this.edd = kycRequirement.edd
    this.group = kycRequirement.group
    this.validation = kycRequirement.validation
  }
}
