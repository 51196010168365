import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'
import getSystemFieldsCall from '@/api/launchpad/system/fields/_country/get'
import { useCountriesStore } from '@/stores/countries'

export const useResourcesStore = defineStore('resources', () => {
  // ------- STORES -------
  const countriesStore = useCountriesStore()

  // ------- STATE -------
  const systemFields = ref({})

  // ------- ACTIONS -------

  const getSystemFields = async (country) => {
    const { data } = await getSystemFieldsCall.exec(country)
    systemFields.value = data
    countriesStore.countries = data.countries

    return data
  }

  return {
    systemFields,
    getSystemFields,
  }
})
