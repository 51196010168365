export const selectAddress = (isUserAddress, userAddress, profileAddress) => {
  return isUserAddress ? userAddress : profileAddress
}

export const addressFormat = (country, data, isFixed) => {
  isFixed = isFixed || isFixedFormat(country)
  let addressKey = isFixed ? 'fixedFormat' : 'freeFormat'
  let address = { Country: country }

  // Shared data
  address[addressKey] = {
    place: data?.place || data?.city || '',
    postalCode: data?.postalCode || data?.postalcode || data?.postCode || '',
  }

  if (isFixed) {
    // Fixed format data
    address[addressKey] = {
      ...address[addressKey],
      unit: data?.unit || '',
      streetNumber: data?.streetNumber || data?.streetnumber || '',
      streetName: data?.streetName || data?.streetname || '',
      streetType: data?.streetType || data?.streettype || '',
      area: data?.area || data?.suburb || '',
      state: data?.state || '',
    }
  } else {
    // Free format data
    address[addressKey] = {
      ...address[addressKey],
      addressLine1: data?.addressLine1 || '',
      addressLine2: data?.addressLine2 || '',
      addressLine3: data?.addressLine3 || '',
      county: data?.county || '',
    }
  }
  return address
}

export const addressFormatString = (country, address, isFixed) => {
  isFixed = isFixed || isFixedFormat(country)
  let formattedAddressString = ''
  if (isFixed) {
    const fixedFormat = address.fixedFormat || {}

    const {
      unit: unitVal,
      streetNumber: streetNumberVal,
      streetName: streetNameVal,
      streetType: streetTypeVal,
      county: countyVal,
      place: placeVal,
      area: areaVal,
      city: cityVal,
      state: stateVal,
      postalCode: postalCodeVal,
    } = fixedFormat

    const unit = fixedFormat.hasOwnProperty('unit') ? unitVal : undefined
    const streetNumber = fixedFormat.hasOwnProperty('streetNumber') ? streetNumberVal : undefined
    const streetName = fixedFormat.hasOwnProperty('streetName') ? streetNameVal : undefined
    const streetType = fixedFormat.hasOwnProperty('streetType') ? streetTypeVal : undefined
    const county = fixedFormat.hasOwnProperty('county') ? countyVal : undefined
    const place = fixedFormat.hasOwnProperty('place') ? placeVal : undefined
    const area = fixedFormat.hasOwnProperty('area') ? areaVal : undefined
    const city = fixedFormat.hasOwnProperty('city') ? cityVal : undefined
    const state = fixedFormat.hasOwnProperty('state') ? stateVal : undefined
    const postalCode = fixedFormat.hasOwnProperty('postalCode') ? postalCodeVal : undefined

    formattedAddressString = `${unit ? unit + '-' : ''}${streetNumber ? streetNumber + ' ' : ''}${
      streetName ? streetName + ' ' : ''
    }${streetType ? streetType + ', ' : ''}${county ? county + ', ' : ''}${
      place ? place + ', ' : ''
    }${area ? area + ', ' : ''}${city ? city + ', ' : ''}${state ? state + ', ' : ''}${
      postalCode ? postalCode + ', ' : ''
    }${country}`
  } else {
    const { addressLine1, addressLine2, addressLine3, county, place, postalCode } =
      address.freeFormat ?? address
    formattedAddressString = [
      addressLine1,
      addressLine2,
      addressLine3,
      county,
      place,
      postalCode,
      country.value,
    ].join(', ')
  }
  return formattedAddressString.replace(', ,', '-').replace(', undefined,', ' -')
}

export const isFixedFormat = (country) => {
  const fixedFormatCountries = ['AU', 'NF', 'NZ', 'CK']
  return fixedFormatCountries.includes(country)
}
