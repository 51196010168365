import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { setHeader } from '@/api/APIHandler'
import { useAuthStore } from '../../stores/auth'

const API = axios.create({
  baseURL: config.VUE_APP_ENDPOINTS_CORP_INT,
})

const checkJWTToken = async (config) => {
  // * Get Auth store
  const authStore = useAuthStore()
  // * Gets the token
  const currentToken = authStore.token
  // * No Token, the call proceeds.
  if (!currentToken) {
    return config
  }
  // * Cancels the interceptor if the call is for renewing token
  const tokenUrl = authStore.tokenUrl
  if (config.url === tokenUrl) {
    return config
  }
  // * Gets Token Data
  const currentTime = new Date().valueOf() / 1000
  const { exp } = jwtDecode(currentToken)
  // * Token expired case
  if (currentTime > exp) {
    try {
      // * Calls the tokenUrl Endpoint
      const { data } = await axios.create().get(tokenUrl, {
        withCredentials: true,
        transformRequest: (data, headers) => {
          const keys = Object.keys(headers.common)
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (key !== 'Accept') {
              delete headers.common[key]
            }
          }
          return data
        },
      })
      const { access_token } = data.tokens
      // * Sets The Header for Future calls
      setHeader('Authorization', `Bearer ${access_token}`)
      // * Updates the store
      authStore.token = access_token
      return config
    } catch {
      // * Handle rejection
      const loginUrl = authStore.loginUrl
      window.removeEventListener('beforeunload', window.handleWindowClose)
      await authStore.reset()
      window.location.href = loginUrl
    }
  }
  return config
}

API.interceptors.request.use(checkJWTToken)

export default API

export class ResponseFromCorporateApi {
  constructor(result, data) {
    this.result = result
    this.status = new StatusFromCorporateAPI(data)
  }
}
export class StatusFromCorporateAPI {
  constructor(data) {
    this.code = data.Result.RetVal
    this.message = data.Result.RetMsg
  }
}
