import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import State from '@/models/CorporateRegistration/State/app'

export default new APIHandler(
  API,
  (country) => {
    return {
      method: 'GET',
      url: `countries/${country}/states`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((state) => {return new State(state)}),
    }
  }
)
