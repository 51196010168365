import FieldErrorDetail from "../FieldErrorDetail/app"

export default class FieldError {
  constructor(error) {
    this.group = error.group
    this.id = error.id
    this.enteredValue = error.enteredValue
    this.errors = error.errors.map((error) => new FieldErrorDetail(error))
  }
}
