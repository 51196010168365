/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole Trader, Listed Public Company, Private Company, Partnership
 */
export const regionValues = {
  defaultCountryFrom: 'NZ',
  //allowedCompanyTypesForStp: ['3109', '1201', '1202', '1203'],
  //allowedSTPCountry: true,
  //marketingPromptCompanies: ['3109'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
