/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'CY',
  allowedCompanyTypesForStp: ['3701', '3702', '3703'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3701'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\w\u0370-\u03FF\u1F00-\u1FFFa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
