/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'SK',
  allowedCompanyTypesForStp: ['3501', '3502', '3503'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3501'],
  postalCodeRegex: `^\\d{3}[ ]?\\d{2}$`,
  addressRegex: `^[\\wěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
