/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Limited Partnership, Private Limited Company, Sole Proprietorship, Association, General Partnership, Limited Liability Company
 */
export const regionValues = {
  regionId: 1205,
  defaultCountryFrom: 'DK',
  defaultCountryFromOnfido: 'DNK',
  defaultCountryCodeFrom: 45,
  defaultCurrencyFrom: 'DKK',
  defaultLanguageCulture: 'da-DK',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['da-DK', 'en-DK'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2501', '2502', '2503', '2504', '2505', '2506', '2507'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2503'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
