/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Limited Partnership
 */
export const regionValues = {
  regionId: 8805,
  defaultCountryFrom: 'LU',
  defaultCountryFromOnfido: 'LUX',
  defaultCountryCodeFrom: 352,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'fr-LU',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['lu-LU', 'fr-LU', 'en-LU'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['3001', '3002', '3003'],
  allowedSTPCountry: true,
  marketingPromptCompanies: [],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
