<template>
  <div class="app">
    <AppSpinnerBig :loading="!loaded"></AppSpinnerBig>
    <div v-show="loaded" class="h-full">
      <RouterView />
      <RiaSnackAlert />
      <RiaMessageBox />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed, onMounted } from '@vue/composition-api'
import { setI18n } from 'vue-composable'
import { provideStore, useStore } from '@/composables/useStore'
import { provideRouter } from '@/composables/useRouter'
import { consentManager } from '@/composables/useConsentManager'
import enEn from '@/locales/en-en.json'
import AppSpinnerBig from '@/components/AppSpinnerBig/AppSpinnerBig'
import RiaMessageBox from '@/components/RiaMessageBox/RiaMessageBox'
import RiaSnackAlert from '@/components/RiaSnackAlert/RiaSnackAlert'

import { useAnalyticsStore } from '@/stores/analytics'
import { useSessionStore } from '@/stores/session'
import { useAppStore } from '@/stores/app'
import { useConsentStore } from '@/stores/consent'
import { useAuthStore } from '@/stores/auth'

export default {
  components: {
    AppSpinnerBig,
    RiaSnackAlert,
    RiaMessageBox,
  },
  metaInfo: {
    // if no sub-components specify a metaInfo.title, this title will be used
    title: 'App',
    // all titles will be injected into this template
    titleTemplate: '%s | XE Money Transfer',
  },
  setup(props, { root: { $store, $router } }) {
    provideStore($store)
    provideRouter($router)
    const store = useStore()
    const analyticsStore = useAnalyticsStore()
    const appStore = useAppStore()
    const consentStore = useConsentStore()
    const authStore = useAuthStore()

    // I18n
    setI18n({
      locale: 'en-en',
      fallback: 'en-en',
      messages: {
        'en-en': enEn,
        'es-es': () => import('@/locales/es-es.json'),
      },
    })

    //  Consent Manager
    const consentState = consentManager(store)

    // Send GTM event - 'Consent State Change'
    analyticsStore.gtmTrack({
      event: 'consentStateChange',
      variables: {
        isConsentedToPerformance: consentState.performance,
        isConsentedToMarketing: consentState.marketing,
        isConsentedToCompliance: consentState.compliance,
      },
    })

    // Set app version
    appStore.setVersion()

    const loaded = computed(() => {
      return appStore.loaded
    })
    onBeforeMount(async () => {
      // Step 1: Consent OK
      consentStore.consentState = consentState
    })

    onMounted(() => {
      const sessionStore = useSessionStore()
      // Init logRocket
      try {
        // Initialize session provider based on compliance consent
        if (consentState.compliance) {
          // init LogRocket
          const userId = authStore.profileId
          sessionStore.initLogRocket(userId)
        }
      } catch (error) {
        // Error handler
        appStore.logException({
          text: 'Exception during LogRocket initialization',
          exception: error,
        })
      }
    })

    return {
      loaded,
      store,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app {
  @apply h-full;
}
</style>
