/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Public Limited Company / Subsidiary, Private Limited Company, Public limited company, Sole Proprietorship, Investment Trust, Partnerships
 */

export const regionValues = {
  regionId: 805,
  defaultCountryFrom: 'FR',
  defaultCountryFromOnfido: 'FRA',
  defaultCountryCodeFrom: 33,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'fr-FR',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{5}$`,
  availableLanguageCultures: ['fr-FR', 'en-FR'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['1801', '1802', '1803', '1804', '1805', '1806'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['1804'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
