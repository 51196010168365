import XeFieldValidation from './XeFieldValidation'

export default class XeField {
  constructor(name, field) {
    ;(this.id = field.id),
      (this.uniqueId = name + field.id),
      (this.validations = this.createValidations(field)),
      (this.label = field.displayname),
      (this.subLabel = field.subLabel),
      (this.wrapperClass = field.wrapperClass),
      (this.helperText = field.helperText),
      (this.hasAsyncValues = field?.validation?.url ? true : false),
      (this.options = field.options),
      (this.checkboxOptions = field.checkboxOptions),
      (this.parent = null),
      (this.readonly = false),
      (this.showOnParentValues = []),
      (this.type = this.createType(field)),
      (this.value = field.Value),
      (this.placeholder = field.placeholder),
      (this.inputFormat = field.inputFormat),
      (this.otherPlaceholder = field.otherPlaceholder),
      (this.selectedOptionWithFlag = field.selectedOptionWithFlag),
      (this.dropdownLabel = field.dropdownLabel),
      (this.selectedOptionWithFlag = field.selectedOptionWithFlag),
      (this.hideBorder = field.hideBorder),
      (this.customInput = field.customInput)
  }

  createValidations(field) {
    var validations = []

    if (field.mandatory) {
      validations.push(
        new XeFieldValidation(
          field.otherPlaceholder || field.validation.error || null,
          'Required',
          null
        )
      )
    }

    const { range, regex, error, min, max } = { ...field.validation }
    if (regex) {
      validations.push(new XeFieldValidation(regex?.error || error, 'Regex', regex))
    }
    if (min) {
      validations.push(new XeFieldValidation(min?.error || error, 'MinLength', min.value))
    }
    if (max) {
      validations.push(new XeFieldValidation(max?.error || error, 'MaxLength', max.value))
    }
    if (range) {
      validations.push(new XeFieldValidation(range?.error || error, 'MinLength', range.min))
      validations.push(new XeFieldValidation(range?.error || error, 'MaxLength', range.max))
    }
    if (!(regex || range) && error) {
      validations.push(new XeFieldValidation(error, 'Generic', null))
    }

    return validations
  }

  createType(field) {
    const { type, id, validation } = field
    if (id === 'phonenumber') {
      return 'phoneNumber'
    }
    if (type === 'Date') {
      return 'date'
    }
    if (type === 'country') {
      return 'country'
    }
    if (validation?.url || type === 'xeInputDropdown') {
      return 'xeInputDropdown'
    }
    if (id === 'country') {
      return 'country'
    }
    if (type === 'Textarea') {
      return 'xeTextarea'
    }
    if (type === 'DropdownWithCustomComponent') {
      return 'xeInputDropdownCustomComponent'
    }
    if (type === 'XeInputMultiselect') {
      return 'xeInputMultiselect'
    }
    if (type === 'xeInputCheckboxList') {
      return 'xeInputCheckboxList'
    }
    if (type === 'xeMultipleChoiceButtonsWithCustomInput') {
      return 'xeMultipleChoiceButtonsWithCustomInput'
    }

    return 'default'
  }
}
