// Composables
import companyTypeMapper from '@/composables/companyTypeMapper'

// Constants
import { PERSONNEL_TYPE, PERSONNEL_KEY } from '@/constants/businessOwners'

// Utils
import { splitText } from '@/utils/array'
import { capitalizeText } from '@/utils/string'

export const hasPersonnelType = (individual, type) => {
  return individual?.personneltype ? individual?.personneltype.includes(type) : false
}

export const getIndividualRoles = (individual) => {
  let individualStatuses = []

  if (individual.isSignatory) {
    individualStatuses.push(PERSONNEL_TYPE.authorizedSignatory)
  }
  if (individual.isUbo || hasPersonnelType(individual, PERSONNEL_KEY.isUbo)) {
    individualStatuses.push(PERSONNEL_TYPE.owner)
  }
  if (individual.isDirector || hasPersonnelType(individual, PERSONNEL_KEY.isDirector)) {
    individualStatuses.push(PERSONNEL_TYPE.director)
  }
  if (!individual.isUbo && !individual.isDirector && individual.role) {
    individualStatuses.push(individual.role)
  }
  return individualStatuses
}

export const getIndividualRolesStringCapitalized = (individual) => {
  const roles = getIndividualRoles(individual)
  if (roles.length === 1) {
    return capitalizeText(roles[0])
  } else {
    const commaSeparated = roles.slice(0, -1).join(', ')
    return `${capitalizeText(commaSeparated)} and ${capitalizeText(roles[roles.length - 1])}`
  }
}

export const isIndividualPending = (individual) => {
  const keysToCheck = ['firstName', 'lastName', 'personneltype', 'dob', 'email']
  const addressKeysToCheck = ['freeFormatAddress', 'fixedFormatAddress']
  if (individual?.isPep) {
    keysToCheck.push(...['nationality', 'countryOfBirth'])
  }
  return (
    !keysToCheck.every((key) => key in individual && Boolean(individual[key])) &&
    !addressKeysToCheck.some((key) => key in individual && Boolean(individual[key]))
  )
}

export const isEveryIndividualsValid = (individuals) => {
  return individuals.every((individual) => !isIndividualPending(individual))
}

export const formattedLastName = (item) => {
  return splitText(item, 'lastName')?.length > 1
    ? splitText(item, 'lastName')[splitText(item, 'lastName').length - 1]?.toLowerCase()
    : item.lastName?.toLowerCase()
}

export const formattedFirstName = (item) => {
  return splitText(item, 'firstName')?.length > 1
    ? splitText(item, 'firstName')[0]?.toLowerCase()
    : item.firstName?.toLowerCase()
}

// remove existing auth personnel from suggestions
export const removeExistingPersonnel = (items, authorizedPersonnel) =>
  items.filter(
    (eachBvdPerson) =>
      !authorizedPersonnel.some(
        (eachPerson) =>
          formattedFirstName(eachBvdPerson) === formattedFirstName(eachPerson) &&
          formattedLastName(eachBvdPerson) === formattedLastName(eachPerson)
      )
  )

export const getFullNameOfExistingPersonnel = (props) => {
  const fullName = `${props.authorizedPersonnel.firstName} ${props.authorizedPersonnel.lastName}`
  return fullName
}

// set list of suggested individual
export const suggestedIndividualList = (personnelFromBvd, authorizedPersonnel) => {
  const seenIdentifiers = new Set()
  const res = {}
  removeExistingPersonnel(personnelFromBvd, authorizedPersonnel).forEach((person) => {
    // filter duplicate using Set()
    // Create an identifier to be tested by the has method
    const identifier = `${formattedFirstName(person)}-${formattedLastName(person)}`
    if (!seenIdentifiers.has(identifier)) {
      // adding a new Personnel
      seenIdentifiers.add(identifier)
      res[identifier] = person
    } else {
      // merge the 2 individuals
      // 1. always use ubo dob if exists
      // 2. merged result should have both roles
      // 3. always grab Ubo address if exists
      const personneltype = [...res[identifier].personneltype, ...person.personneltype]
      res[identifier] = {
        ...res[identifier],
        dob: res[identifier].dob ?? person.dob,
        address: res[identifier].address ?? person.address,
        personneltype,
      }
      personneltype.forEach((type) => (res[identifier][type] = true))
    }
  })
  return Object.values(res).slice(0, 6) // show max. of 6
}

export const formatConfirmationCheckboxMessage = (corpProfile, isDirectorNeeded) => {
  const formattedCompanyType = companyTypeMapper(
    corpProfile.registrationAddress.country.toUpperCase(),
    corpProfile.companyDetails.companyType
  )
  let extraMsg = isDirectorNeeded || formattedCompanyType === 'LIMITED' ? ', directors ' : ' '
  let msg = `I certify that all business owners${extraMsg}or equivalent individuals have been added.`
  return msg
}
