/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC, Small Partnership
 */
export const regionValues = {
  defaultCountryFrom: 'LT',
  allowedCompanyTypesForStp: ['4601', '4602', '4603', '4604'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4601'],
  postalCodeRegex: `^\\d{5}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
