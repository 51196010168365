import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  (stpApprovalRequest) => {
    return {
      method: 'POST',
      url: `verify/stpapprovecorporate`,
      data: stpApprovalRequest,
    }
  },
  (response) => {
    return {
      ...response.data,
    }
  }
)
