import { defineStore } from 'pinia'
import { analyticsInit, identify, gtmTrack, track } from '@/composables/useAnalytics/index'

export const useAnalyticsStore = defineStore('analytics', () => {
  return {
    gtmTrack: async ({ event, variables }) => await gtmTrack({ event, variables }),
    identify: async ({ userId, traits }) => await identify({ userId, traits }),
    init: async ({ ...analyticsKeys }) => await analyticsInit({ ...analyticsKeys }),
    track: async ({ userId, event, traits }) => await track({ userId, event, traits }),
  }
})
