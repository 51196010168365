/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole Trader, Listed Public Company, Partnership, Proprietary Limited, Trust, Cooperative, Incorporated Association, Unincorporated Association
 */
export const regionValues = {
  regionId: 705,
  defaultCountryFrom: 'AU',
  defaultCountryFromOnfido: 'AUS',
  defaultCountryCodeFrom: 61,
  defaultCurrencyFrom: 'AUD',
  defaultLanguageCulture: 'en-AU',
  defaultLocation: {
    latitude: -25.274398,
    longitude: 133.775136,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  support: {
    email: 'customerservice@riamoneytransfer.com',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://www.riamoneytransfer.com/us/en/mobile-content/privacy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://app.riamoneytransfer.com/app-terms-and-conditions',
  },
  availableLanguageCultures: ['en-AU'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  paymentProvider: null,
  isPlaidEnabled: true,
  isBankAccountEnabled: true,
  useSecurityQuestions: false,
  oldRemittanceLogin: false,
  onfidoMaxAttempts: 0,
  //allowedCompanyTypesForStp: ['3108', '1302', '1303', '1304', '1305', '1306', '1307', '1309'],
  //allowedSTPCountry: true,
  //marketingPromptCompanies: ['3108'],
}
