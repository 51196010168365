<template>
  <AppOverlay
    v-model="visible"
    :dismissible="dismissible"
    class="dialog"
    :transition-type="transitionType"
    @showing="onShowing"
    @show="onShow"
    @shown="onShown"
    @hiding="onHiding"
    @hide="onHide"
    @hidden="onHidden"
  >
    <AppCard class="dialog--card">
      <template v-if="$slots.header" #header>
        <slot name="header" :dismiss="dismiss" />
      </template>

      <slot />

      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </AppCard>
  </AppOverlay>
</template>

<script>
import { computed } from '@vue/composition-api'

import AppOverlay from '@/components/AppOverlay/AppOverlay'
import AppCard from '@/components/AppCard/AppCard'

import useAppOverlay from '@/composables/useAppOverlay'
import { useMediaQuery } from '@/composables/useMediaQuery'

export default {
  name: 'AppDialog',
  components: {
    AppOverlay,
    AppCard,
  },
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const mq = useMediaQuery()

    const transitionType = computed(() => {
      if (mq.current === 'xs') {
        return 'slide-up'
      }
      return null
    })

    const {
      dismiss,
      visible,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    } = useAppOverlay(props, emit)

    return {
      dismiss,
      visible,
      transitionType,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
      slots,
    }
  },
}
</script>

<style scoped>
.dialog {
  .overlay-content,
  ::v-deep.overlay-content {
    @apply mb-0;
    @apply pb-0;
    @apply mx-0;

    @apply w-full;

    @screen sm {
      width: 400px; /* no Tailwind equivalent  */
    }

    .dialog--card {
      @apply rounded-none;
    }

    @screen sm {
      /* Resets */
      @apply m-auto;
      @apply pt-10 pb-10;
    }
  }

  .dialog--card {
    @apply overflow-hidden;
    @apply shadow-ria-1;
    &.card {
      @apply p-0;
      @screen sm {
        @apply rounded-xl;
        @apply border-0;
      }
      ::v-deep {
        .card-content {
          @apply px-6 py-4;
          @apply whitespace-normal;
          @apply text-left;

          .card-content-block {
            @apply mb-0;
          }
        }
      }
    }
  }
}
</style>
