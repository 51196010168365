/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'HR',
  allowedCompanyTypesForStp: ['3601', '3602', '3603'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3601'],
  postalCodeRegex: `^\\d{5}$`,
  addressRegex: `^[\\wěščćřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
