import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import CompanyType from '@/models/CorporateRegistration/CompanyTypes/app'

export default new APIHandler(
  API,
  ({ country }) => {
    return {
      method: 'GET',
      url: `companytypes/${country}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((item) => new CompanyType(item)),
    }
  }
)
