import Vue from 'vue'
import VueCompositionAPI, { computed, ref, watch } from '@vue/composition-api'

Vue.use(VueCompositionAPI)

class Alert {
  constructor(text) {
    this.text = text
    this.timeout = null
    this.dismissed = false
    this.id = Symbol()
  }
  startTimeout() {
    this.timeout = setTimeout(() => {
      this.dismissed = true
    }, 5000)
  }
  dismiss() {
    this.dismissed = true
  }
}

const queue = ref([])
const alert = computed(() => queue.value[0])

const dismiss = () => {
  queue.value.shift()
}

const add = (text) => {
  //If text is not unique return
  if (queue.value.some((alert) => alert.text === text)) {
    return
  }

  queue.value.push(new Alert(text))
}

const isAlertBtmMargin = ref(false)

// Watch the first alert in the queue
watch(
  alert,
  (alert) => {
    if (alert) {
      // If timeout hasn't started, lets start it
      if (!alert.timeout) {
        alert.startTimeout()
      }

      // If the alert has been dismissed, lets remove it from the array
      if (alert.dismissed) {
        dismiss()
      }
    }
  },
  { deep: true }
)

export default function () {
  return {
    queue,
    alert,
    add,
    dismiss,
    isAlertBtmMargin,
  }
}
