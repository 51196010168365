/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultCountryFrom Default value to use for send-from country, i.e. in price calculator
 * @defaultCurrencyFrom Default value to use for send-from currency, i.e. in price calculator
 * @defaultLanguage Default language culture to use, i.e. content translations
 */
export const regionValues = {
  regionId: 105,
  defaultCountryFrom: 'US',
  defaultCountryFromOnfido: 'USA',
  defaultCountryCodeFrom: 1,
  defaultCurrencyFrom: 'USD',
  defaultLanguageCulture: 'en-US',
  customerServicePhone: '18774431399',
  defaultLocation: {
    latitude: 34.037587,
    longitude: -118.249483,
  },
  dateFormat: 'MM/DD/YYYY',
  postalCodeRegex: `^[0-9 -]{5,5}$`,
  support: {
    email: 'customerservice@riamoneytransfer.com',
    title: 'Support request',
  },
  links: {
    linkFaq: 'https://www.riamoneytransfer.com/us/en/mobile-content/faqs',
    linkPrivacy: 'https://www.riamoneytransfer.com/us/en/mobile-content/privacy',
    linkStoredCardAgreement:
      'https://www.riamoneytransfer.com/us/en/mobile-content/stored-card-agreement',
    linkTerms: 'https://app.riamoneytransfer.com/app-terms-and-conditions',
  },
  availableLanguageCultures: ['en-US', 'es-US'],
  regionalIp: '66.82.123.234',
  requireAddress: false,
  isPlaidEnabled: true,
  isBankAccountEnabled: true,
  useSecurityQuestions: true,
  oldRemittanceLogin: true,
  onfidoMaxAttempts: 0,
  allowedSTPCountry: false,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
