import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import CorporateFieldGroup from '@/models/CorporateRegistration/CorporateFieldGroup/app'
import { useConsentStore } from '@/stores/consent'

const getCorpRegURL = () => {
  const consentStore = useConsentStore()
  const baseUrl = 'corporate-registration'
  const leadSourceState = consentStore.leadSourcesState
  const dfxId = leadSourceState?.dfxId || consentStore.getDfxIdFromCookie
  if (dfxId) {
    return `${baseUrl}?dfxId=${dfxId}`
  }

  const clientAffiliateId =
    leadSourceState?.clientAffiliate || consentStore.getClientAffiliateIdFromCookie
  if (clientAffiliateId) {
    return `${baseUrl}?clientAgentId=${clientAffiliateId}`
  }
  return baseUrl
}

export default new APIHandler(
  API,
  ({ data }) => {
    return {
      method: 'POST',
      url: getCorpRegURL(),
      data,
    }
  },
  (response) => {
    return {
      ...response.data,
      data: response.data.requirements.map((fieldGroup) => new CorporateFieldGroup(fieldGroup)),
    }
  }
)
