<template>
  <div class="alert" role="alert" :class="alertTheme">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
export const themes = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'gray',
  'dark',
  'blue-with-bg',
  'yellow-with-bg',
]

export default {
  name: 'AppAlert',
  props: {
    theme: {
      type: String,
      default: 'red',
      validator: (value) => {
        return themes.indexOf(value) > -1
      },
    },
  },
  setup(props) {
    const alertTheme = computed(() => {
      return `alert--${props.theme}`
    })

    return {
      alertTheme,
    }
  },
}
</script>

<style lang="postcss" scoped>
.alert {
  @apply flex justify-between items-center box-border rounded-md py-2 px-3;
}

.alert-action {
  @apply cursor-pointer;
}

.alert--dark {
  @apply text-light bg-dark;
}
.alert--cyan {
 @apply text-main-cyan bg-tertiary-blue;
}
.alert--red {
  @apply text-red-text bg-red-lighter;
}
.alert--orange {
  @apply text-orange-text bg-orange-lighter;
}
.alert--yellow {
  @apply text-yellow-text bg-yellow-lighter;
}
.alert--green {
  @apply text-green-text bg-green-lighter;
}
.alert--blue {
  @apply text-blue-text bg-blue-lighter;
}
.alert--blue-with-bg {
  color: #5c667b;
  background-color: #dae8f5;
}
.alert--yellow-with-bg {
  color: #5c667b;
  background-color: #f5e9da;
}
.alert--purple {
  @apply text-purple-text bg-purple-lighter;
}
.alert--pink {
  @apply text-pink-text bg-pink-lighter;
}
.alert--gray {
  @apply text-secondary-text bg-gray-lighter;
}
</style>
