/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Limited Company, Sole Trader, Partnership, Corporate Trust, Charity, Private Trust, Unincorporated
 */
export const regionValues = {
  regionId: 1605,
  defaultCountryFrom: 'IE',
  defaultCountryFromOnfido: 'IRL',
  defaultCountryCodeFrom: 353,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'en-IE',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^[A-Za-z0-9 -]{0,20}$`, //test: N91 E2TA,Dublin 6W,Dublin 12,D09,D9,A75
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['en-IE'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2201', '2202', '2203', '2204', '2205', '2206', '2207'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2202'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
