// ------- Dependencies -------
import { defineStore } from 'pinia'
import { ref, toRefs, watch, computed } from '@vue/composition-api'
import postNewCustomerRequiredFields from '@/api/corporate/corporateRegistration/post'
import fetchedMockedCustomerRequiredFields from '@/data/registration/corporateRegistration'
import CorporateFieldGroup from '@/models/CorporateRegistration/CorporateFieldGroup/app'
import PhoneDetails from '@/models/CorporateRegistration/PhoneDetails/app'
import { map, flow, sortBy } from 'lodash-es'

// ------- APIs -------
import getCountriesList from '@/api/corporate/countries/get'
import getStates from '@/api/corporate/countries/states/get'
import getStreetTypes from '@/api/corporate/streetTypes/get'
import addressSearch from '@/api/corporate/addressSearch/get'
import addressDetails from '@/api/corporate/addressDetails/get'
import getCorporateRegistration from '@/api/corporate/corporateRegistration/get'
import getTermsAndConditions from '@/api/corporate/termsConditions/get'
import putTermsAndConditions from '@/api/corporate/termsConditions/put'
import putMarketingPreferenceConsent from '@/api/corporate/marketingPreferenceConsent/put'
import submitFields from '@/api/corporate/submitFields/put'
import postSubmitRegistration from '@/api/corporate/submitRegistration/_id/post'
import getNatureOfBusiness from '@/api/corporate/natureOfBusiness/get'
import getSubIndustry from '@/api/corporate/natureOfBusiness/subIndustry/get'
import postAuthorizeProfile from '@/api/corporate/authorize/profile/post'
import getGsoToken from '@/api/corporate/authorize/gsoToken/get'
import { setHeader } from '@/api/APIHandler'
import getCurrencyType from '@/api/corporate/currencyType/get'
import getMarketingPrompt from '@/api/corporate/marketingPreferenceConsent/marketingPrompt/get'

// ------- Stores -------
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useAuthStore } from '@/stores/auth'
import { useDeviceStore } from '@/stores/device'
import { useAppStore } from '@/stores/app'
import { useDashboardStore } from '@/stores/dashboard'
import { getMappedCountry } from '@/utils/i18n'

export const useCorporateRegistrationStore = defineStore(
  'corporateRegistration',
  () => {
    // ------- STORES -------
    const companyDetailsStore = useCompanyDetailsStore()
    const authStore = useAuthStore()
    const deviceStore = useDeviceStore()
    const appStore = useAppStore()
    const dashboardStore = useDashboardStore()

    // ------- STATE -------
    const steps = ref([
      {
        title: 'Account',
      },
      {
        title: 'Verification',
      },
      {
        title: 'Business',
        path: '/',
        pageTitle: 'Business information',
      },
      {
        title: 'Personal',
        path: '/personal-details',
        pageTitle: 'Personal details',
      },
    ])
    const activeStepIdx = ref(0)
    const phoneDetails = ref({
      number: '',
      areaCode: '',
      nameAbbr: '',
    })
    const userGeoCountry = ref('GB')
    const countryList = ref([])
    const allCountryList = ref([])
    const stateList = ref([])
    const streetTypeList = ref([])
    const supportCountryList = ref([])
    const natureOfBusinessList = ref([])
    const subIndustryList = ref([])
    const businessDetail = ref({
      companyName: '',
      businessDifferentName: false,
      ein: '',
      website: '',
      natureOfBusiness: '',
      signinAuthority: false,
      tradingName: '',
    })
    const personalAddress = ref({
      id: '',
      language: '',
      buildingName: '',
      buildingNumber: '',
      subBuilding: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      street: '',
      city: '',
      district: '',
      countryCode: '',
      postCode: '',
      provinceName: '',
    })
    const personalDetail = ref({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
    })
    const businessSameOperatingAddress = ref(false)
    const newCustomerRequiredFields = ref(null)
    const mockedCustomerRequiredFields = ref(null)
    const corporateForm = ref({})
    const userId = ref('')
    const companyTypeName = ref('')
    const promptForMarketingConsent = ref(false)
    const marketingOptIn = ref(false)
    const clientNumber = ref('')
    const currencyTypeList = ref([])
    const cachedFormFields = ref([]) // old name: saveFormFields

    // ------- ACTIONS -------

    const setActiveStep = (path) => {
      const step = getStepByPath(path)
      const idx = getStepIdx(step)
      // commit('setActiveStepIdx', idx)
      activeStepIdx.value = idx
    }

    const fetchNewCustomerRequiredFields = async (setId = true) => {
      const { id, data } = await postNewCustomerRequiredFields.exec({
        data: {
          country: companyDetailsStore.companyCountry,
          companyType: companyDetailsStore.companyType,
        },
      })
      if (data) {
        newCustomerRequiredFields.value = data
        // commit('setNewCustomerRequiredFields', data)
      }
      if (setId && id) {
        userId.value = id
        const login = authStore.lastLogin
        if (login) {
          deviceStore.corporateRegistrationIds[login] = id
        }
      }
    }

    const setClearCustomerFieldsGroup = (group) => {
      delete corporateForm.value[group]
    }

    const getMockedCustomerRequiredFields = () => {
      const data = fetchedMockedCustomerRequiredFields.requirements.map(
        (fieldGroup) => new CorporateFieldGroup(fieldGroup)
      )
      if (data) {
        // commit('setMockedCustomerRequiredFields', data)
        mockedCustomerRequiredFields.value = data
      }
    }

    const fetchCountryList = async () => {
      const { data } = await getCountriesList.exec()
      if (data) {
        const sorted = data.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          } else if (a.name < b.name) {
            return -1
          }
          return 0
        })
        // commit('setCountryList', sorted)
        setCountryList(sorted)
      }
    }

    const fetchAllCountryList = async () => {
      const { data } = await getCountriesList.exec({ all: true })
      if (data) {
        // commit('setAllCountryList', data)
        allCountryList.value = data
      }
    }

    const setCountryList = (list) => {
      countryList.value = list
      supportCountryList.value = countryList.value // countryList.value.filter((country) => country.canRegister)
    }

    const fetchStateList = async (country) => {
      const { data } = await getStates.exec(getMappedCountry(country))
      if (data) {
        // commit('setStateList', data)
        stateList.value = data
      }
    }

    const fetchStreetTypeList = async (country) => {
      const { data } = await getStreetTypes.exec(getMappedCountry(country))
      if (data) {
        streetTypeList.value = data
      }
    }

    const fetchAddressSearchResult = async ({ searchTerm, country, searchContext }) => {
      const { data } = await addressSearch.exec({ searchTerm, country, searchContext })
      if (data) {
        return data
      }
    }

    const fetchAddressDetails = async ({ addressId }) => {
      const { data } = await addressDetails.exec({ addressId })
      if (data) {
        return data
      }
    }

    const saveFormFields = async ({ group, formFields = null, customFields = null }) => {
      if (formFields) {
        formFields = formFields?.value || formFields
        formFields.map(({ id, value }) => {
          if (!value) {
            value = ''
          }
          // commit('setSaveFormFields', { group, id, raw: value })
          setSaveFormFields({ group, id, raw: value })
        })
      }
      // Save custom fields
      if (customFields) {
        customFields.map(({ id, value }) => {
          setSaveFormFields({ group, id, raw: value })
          // commit('setSaveFormFields', { group, id, raw: value })
        })
      }
    }

    const setSaveFormFields = (formData) => {
      // Save object checking for duplicates
      const existingIndex = cachedFormFields.value.findIndex((item) => item.id === formData.id)
      if (existingIndex !== -1) {
        // If duplicate is found, replace it with the new object
        cachedFormFields.value[existingIndex] = formData
      } else {
        // no duplicates, push the object into the array
        cachedFormFields.value.push(formData)
      }
    }

    const submitFieldData = async ({ group, formFields, extraFields = null, send = true }) => {
      if (formFields.value) {
        formFields = formFields.value
      }
      const fields = formFields.map(({ id, value }) => {
        if (!value) {
          value = ''
        }
        return { group, id, raw: value }
      })
      if (extraFields) {
        for (const key in extraFields) {
          const value = extraFields[key] || ''
          fields.push({ group, id: key, raw: value })
        }
      }

      if (send) {
        const id = userId.value
        const result = await submitFields.exec({ id, fields })
        const errors = result.data
        if (errors && errors.length > 0) {
          return errors
        }
      }

      setCorporateFormFieldValue({ name: group, formData: formFields })
      return null
    }

    const setCorporateFormFieldValue = (payload) => {
      const { name, formData, clearFieldValues } = payload
      if (clearFieldValues) {
        corporateForm.value = {}
      }
      let form = {}
      for (let i in formData) {
        const data = formData[i]
        form[data.id] = {
          name: data.label,
          value: data.value,
        }
      }
      corporateForm.value[name] = { ...corporateForm.value[name], ...form }
    }

    const submitAllFieldsData = async () => {
      const result = await submitFields.exec({ id: userId.value, fields: cachedFormFields.value })
      const errors = result.data
      if (errors && errors.length > 0) {
        return errors
      }
      return null
    }

    const submitRegistration = async () => {
      const id = userId.value
      const countryFromIp = deviceStore.country
      const {
        data,
        promptForMarketingConsent: retMarketingConsent,
        clientNumber: retClientNumber,
      } = await postSubmitRegistration.exec({
        id,
        countryFromIp,
      })
      // commit('setMarketingConsentPopup', promptForMarketingConsent)
      // commit('setClientNumber', clientNumber)

      promptForMarketingConsent.value = retMarketingConsent
      clientNumber.value = retClientNumber

      return data
    }

    const fetchNatureOfBusinessList = async () => {
      const { data } = await getNatureOfBusiness.exec()
      if (data) {
        natureOfBusinessList.value = data
      }
    }

    const setSubIndustryList = async (categoryId) => {
      if (categoryId) {
        // Cleans the sub industry list first before updating it
        subIndustryList.value = []

        const { data } = await getSubIndustry.exec(categoryId)
        subIndustryList.value = data
      }
    }

    const checkRedirect = async (router) => {
      const search = '?corporate-registration='
      if (window.location.href.indexOf(search) !== -1) {
        const parts = window.location.href.split(search)
        if (parts.length > 1) {
          // await dispatch('redirectRegistrationId', { registrationId: parts[1], router })
          await redirectRegistrationId({ registrationId: parts[1], router })
          return true
        }
      }

      const login = authStore.lastLogin
      if (login) {
        const id = deviceStore.getCorporateRegistrationIdForUser(login)
        if (id) {
          try {
            // await dispatch('redirectRegistrationId', { registrationId: id, router })
            await redirectRegistrationId({ registrationId: id, router })
          } catch (ex) {
            // dispatch(
            //   'app/logException',
            //   {
            //     text: 'Exception during checking registration ID',
            //     exception: ex,
            //   },
            //   { root: true }
            // )

            appStore.logException({
              text: 'Exception during checking registration ID',
              exception: ex,
            })

            const rootUrl =
              window.location.protocol + '//' + window.location.host + '/registration/business-type'
            // if (!rootGetters['companyDetails/getCompanyType'] && window.location.href !== rootUrl) {
            if (!companyDetailsStore.companyType && window.location.href !== rootUrl) {
              window.location.href = rootUrl
              return false
            }
          }
        }
      }
      return true
    }

    const redirectRegistrationId = async ({ registrationId, router }) => {
      const { data } = await getCorporateRegistration.exec(registrationId)
      if (data.corporateForm) {
        const country = data.corporateForm['registeredaddress']['country'].value
        // await dispatch('resetForm')
        resetForm()
        // commit('companyDetails/setCompanyCountry', country)
        // commit('companyDetails/setCompanyType', data.corporateForm['company']['companytype'].value.toString())
        companyDetailsStore.companyCountry = country
        companyDetailsStore.companyType =
          data.corporateForm['company']['companytype'].value.toString()

        const promises = []
        // promises.push(dispatch('getNewCustomerRequiredFields', false))
        promises.push(fetchNewCustomerRequiredFields(false))

        // IMPROVE: ideally we don't need them here
        promises.push(fetchStateList(country))
        promises.push(fetchStreetTypeList(country))
        promises.push(fetchNatureOfBusinessList(null))

        await Promise.all(promises)

        corporateForm.value = data.corporateForm
        const updatedPhoneDetails = {
          number: '',
          areaCode: '',
          nameAbbr: '',
        }
        if (data.corporateForm['contact'] && data.corporateForm['contact']['phonenumber']) {
          const countryList = countryList?.value
          updatedPhoneDetails.areaCode = data.corporateForm['contact']['phoneprefix'].value
          if (updatedPhoneDetails.areaCode) {
            updatedPhoneDetails.areaCode = updatedPhoneDetails.areaCode.replace('+', '')
          }
          updatedPhoneDetails.number =
            updatedPhoneDetails.areaCode + '-' + data.corporateForm['contact']['phonenumber'].value
          const phoneCountries = countryList.filter(
            (phoneCountry) => phoneCountry.phonePrefix === updatedPhoneDetails.areaCode
          )
          if (phoneCountries && phoneCountries.length > 0) {
            updatedPhoneDetails.nameAbbr = phoneCountries[0].code
            if (phoneCountries.length > 1) {
              const phoneCountry = phoneCountries.find(
                (phoneCountry) => phoneCountry.code === country
              )
              if (phoneCountry) {
                updatedPhoneDetails.nameAbbr = phoneCountry.code
              }
            }
          }
        }

        phoneDetails.value = updatedPhoneDetails
        userId.value = registrationId

        // if not already on a business page, redirect to the latest one
        if (window.location.href.indexOf('/') === -1) {
          if (data.corporateForm['contactaddress']) {
            router.push({ name: 'PersonalAddress' })
          } else if (data.corporateForm['contact']) {
            router.push({ name: 'RegisterPersonalDetails' })
          } else if (data.corporateForm['tradingaddress']) {
            router.push({ name: 'OperatingAddress' })
          } else if (
            data.corporateForm['registeredaddress'] &&
            data.corporateForm['registeredaddress']['city']
          ) {
            router.push({ name: 'BusinessAddress' })
          } else if (
            data.corporateForm['company'] &&
            data.corporateForm['company']['registeredname']
          ) {
            router.push({ name: 'RegisterBusinessDetails' })
          } else if (data.corporateForm['company']) {
            router.push({ name: 'RegisterBusinessSearch' })
          } else {
            router.push({ name: 'RegisterBusinessInformation' })
          }
        }
      }
    }

    const fetchTermsAndConditions = async () => {
      const { data } = await getTermsAndConditions.exec(companyDetailsStore.companyCountry)
      return data
    }

    const agreeTermsAndConditions = async (version) => {
      const { data } = await putTermsAndConditions.exec(version)
      return data
    }

    const updateProfile = async () => {
      const result = await postAuthorizeProfile.exec({
        country: companyDetailsStore.companyCountry,
      })
      if (result && result.data && result.data.refreshToken) {
        const tokenResult = await getGsoToken.exec(authStore.tokenUrl)
        if (tokenResult && tokenResult.data && tokenResult.data.accessToken) {
          setHeader('Authorization', `Bearer ${tokenResult.data.accessToken}`)
        }
      }
      return result.data
    }

    // Attention: why do we have a watcher here
    const showServerErrors = ({ errors, formFields }) => {
      for (const err of errors) {
        const { id, errors } = err
        const descriptions = errors.map((err) => err.description)
        const field = formFields.value.find((formField) => formField.id === id)
        if (field) {
          field.serverError = descriptions.join('\r\n')
          const stop = watch(toRefs(field).value, () => {
            field.serverError = null
            stop()
          })
        }
      }
    }

    const proceedRegistration = () => {
      window.location = getRedirectUrl.value
    }

    const updateMarketingPreferences = async () => {
      await putMarketingPreferenceConsent.exec({
        consentedToMarketing: marketingOptIn.value,
        promptForMarketingConsent: false,
      })
    }

    const fetchMarketingPrompt = async ({ countryCode, companyTypeId }) => {
      const result = await getMarketingPrompt.exec({ countryCode, companyTypeId })
      promptForMarketingConsent.value = result.data
    }

    const mapStateValue = (stateName) => {
      const states = stateList.value
      if (stateName && states && states.length) {
        stateName = stateName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        const state = states.find((item) => item.name === stateName)
        if (state) {
          return state.value
        }
      }
      return stateName
    }

    const setMarketingOptIn = (op) => {
      marketingOptIn.value = op
    }

    const fetchCurrencyTypeList = async ({ country }) => {
      const { data } = await getCurrencyType.exec({ country })
      if (data) {
        currencyTypeList.value = data
      }
    }

    const resetForm = () => {
      phoneDetails.value.number = ''
      phoneDetails.value.areaCode = ''
      phoneDetails.value.nameAbbr = ''

      businessDetail.value.companyName = ''
      businessDetail.value.businessDifferentName = false
      businessDetail.value.ein = ''
      businessDetail.value.website = ''
      businessDetail.value.natureOfBusiness = ''
      businessDetail.value.signinAuthority = false
      businessDetail.value.tradingName = ''

      personalAddress.value.id = ''
      personalAddress.value.language = ''
      personalAddress.value.buildingName = ''
      personalAddress.value.buildingNumber = ''
      personalAddress.value.subBuilding = ''
      personalAddress.value.addressLine1 = ''
      personalAddress.value.addressLine2 = ''
      personalAddress.value.addressLine3 = ''
      personalAddress.value.street = ''
      personalAddress.value.city = ''
      personalAddress.value.district = ''
      personalAddress.value.countryCode = ''
      personalAddress.value.postCode = ''
      personalAddress.value.provinceName = ''

      personalDetail.value.firstName = ''
      personalDetail.value.lastName = ''
      personalDetail.value.dateOfBirth = ''

      businessSameOperatingAddress.value = false
      corporateForm.value = {}
      userId.value = ''
      clientNumber.value = ''
      cachedFormFields.value = []
    }

    // mutators
    const setActiveStepPageTitle = (pageTitle) => {
      if (activeStepIdx.value && steps.value[activeStepIdx.value]) {
        steps.value[activeStepIdx.value].pageTitle = pageTitle
      }
    }

    // ------- GETTERS -------

    const activeStep = computed(() => {
      return steps.value[activeStepIdx.value]
    })

    // Attention: fix this
    const getRedirectUrl = computed(() => {
      const isApiIntegrationUser = dashboardStore.corpProfile?.isApiUser ?? false

      if (isApiIntegrationUser) {
        return config.VUE_APP_URL_BUSINESS_CENTRAL
      }

      return config.VUE_APP_URL_TRANSFER
    })

    const getStepByPath = (path) => {
      return steps.value.find((step) => {
        return step.path === path
      })
    }

    const getStepIdx = (step) => {
      return steps.value.indexOf(step)
    }

    const getPhoneDetails = computed(() => {
      const phoneDetails = phoneDetails?.value
      const selectedCountryCode = companyDetailsStore?.companyCountry
      if (phoneDetails && phoneDetails.number && phoneDetails.number !== phoneDetails.areaCode) {
        return new PhoneDetails({ ...phoneDetails })
      } else {
        const country = countryList.value?.find((country) => country.code === selectedCountryCode)
        return PhoneDetails?.FromCountry(country)
      }
    })

    const getSelectedPhoneDetails = computed(() => {
      if (phoneDetails.value) {
        return new PhoneDetails({ ...phoneDetails.value })
      }
    })

    const getNewCustomerFields = (stage) => {
      if (newCustomerRequiredFields.value) {
        const corpFieldGroup = newCustomerRequiredFields.value.find((group) => group.name === stage)
        return corpFieldGroup || {}
      }
      return {}
    }

    const getMockedCustomerFields = (stage) => {
      if (mockedCustomerRequiredFields.value) {
        const corpFieldGroup = mockedCustomerRequiredFields.value.find(
          (group) => group.name === stage
        )
        return corpFieldGroup || {}
      }
      return {}
    }

    const getCorporateForm = (name) => {
      const form = corporateForm.value[name]
      if (form) {
        return form
      }
      return null
    }

    const getCorporateFormFieldValue = (name, id) => {
      const formField = corporateForm.value[name]
      if (formField && formField[id]) {
        return formField[id].value
      }
      return null
    }

    const getCorporateFormSavedValue = (name, id) => {
      const savedFields = cachedFormFields.value
        .filter((item) => item.group === name && item.id === id)
        .shift()
      return savedFields?.raw || null
    }

    const offeredCountriesDialingCodesOptions = computed(() => {
      return flow(
        // Sort them by phonePrefix
        (countryList) => sortBy(countryList, 'phonePrefix'),
        // Map to text value pair
        (countryList) =>
          map(countryList, (country) => {
            const { phonePrefix, code, name } = country
            return {
              text: `+${phonePrefix} ${code}`,
              value: phonePrefix,
              id: code,
              nameAbbr: code,
              country: name,
            }
          })
      )(supportCountryList.value)
    })

    const getIsEuCountry = computed(() => {
      const euCountryCodes = [
        'BE',
        'EL',
        'LT',
        'PT',
        'BG',
        'ES',
        'LU',
        'RO',
        'CZ',
        'FR',
        'RE',
        'GP',
        'MQ',
        'GF',
        'YT',
        'BL',
        'MF',
        'PM',
        'WF',
        'PF',
        'NC',
        'HU',
        'SI',
        'DK',
        'FO',
        'GL',
        'HR',
        'MT',
        'SK',
        'DE',
        'IT',
        'NL',
        'AW',
        'CW',
        'SX',
        'FI',
        'AX',
        'EE',
        'CY',
        'AT',
        'SE',
        'IE',
        'LV',
        'PL',
        'UK',
        'GB',
        'AI',
        'BM',
        'IO',
        'VG',
        'KY',
        'FK',
        'GI',
        'MS',
        'PN',
        'SH',
        'TC',
        'GG',
        'JE',
        'IM',
      ]
      const selectedCountryCode = companyDetailsStore.companyCountry
      return euCountryCodes.includes(selectedCountryCode)
    })

    const getStepperItems = computed(() => {
      return steps.value.map((item, i) => {
        return {
          name: item.title,
          activeItem: i === activeStepIdx.value,
          isItemChecked: i < activeStepIdx.value,
        }
      })
    })

    const contactUs = async () => {
      window.location = 'https://www.xe.com/contact/faq/'
    }

    return {
      allCountryList,
      steps,
      activeStepIdx,
      phoneDetails,
      userGeoCountry,
      countryList,
      stateList,
      streetTypeList,
      supportCountryList,
      natureOfBusinessList,
      subIndustryList,
      businessDetail,
      personalAddress,
      personalDetail,
      businessSameOperatingAddress,
      newCustomerRequiredFields,
      mockedCustomerRequiredFields,
      corporateForm,
      userId,
      companyTypeName,
      promptForMarketingConsent,
      marketingOptIn,
      clientNumber,
      currencyTypeList,
      cachedFormFields,
      setActiveStep,
      fetchNewCustomerRequiredFields,
      setClearCustomerFieldsGroup,
      getMockedCustomerRequiredFields,
      fetchCountryList,
      setCountryList,
      fetchAllCountryList,
      fetchStateList,
      fetchStreetTypeList,
      fetchAddressSearchResult,
      fetchAddressDetails,
      setSaveFormFields,
      saveFormFields,
      submitFieldData,
      setCorporateFormFieldValue,
      submitAllFieldsData,
      submitRegistration,
      fetchNatureOfBusinessList,
      setSubIndustryList,
      redirectRegistrationId,
      checkRedirect,
      resetForm,
      fetchTermsAndConditions,
      showServerErrors,
      agreeTermsAndConditions,
      updateProfile,
      proceedRegistration,
      updateMarketingPreferences,
      fetchMarketingPrompt,
      mapStateValue,
      setMarketingOptIn,
      fetchCurrencyTypeList,
      getRedirectUrl,
      setActiveStepPageTitle,
      getPhoneDetails,
      getSelectedPhoneDetails,
      getNewCustomerFields,
      getMockedCustomerFields,
      getCorporateForm,
      getCorporateFormFieldValue,
      getCorporateFormSavedValue,
      offeredCountriesDialingCodesOptions,
      getIsEuCountry,
      getStepperItems,
      activeStep,
      contactUs,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
)
