import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  ({ country, language = 'en' }) => {
    return {
      method: 'POST',
      url: `authorize/profile`,
      data: {
        country,
        preferredLanguage: language,
      },
    }
  },
  (response) => {
    return {
      ...response.data,
      data: {
        refreshToken: response.data.refreshToken,
        profileId: response.data.profileId
      }
    }
  }
)
