import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import StreetType from '@/models/CorporateRegistration/StreetType/app'

export default new APIHandler(
  API,
  (countryCode) => {
    return {
      method: 'GET',
      url: `address/streetTypes?countryCode=${countryCode}`,
    }
  },
  (response) => {
    return {
      data: response.data.data.map((streetType) => {
        return new StreetType(streetType)
      }),
    }
  }
)
