import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMeta from 'vue-meta'
import { AppRipple } from '@/components/AppRipple'
import vueDebounce from 'vue-debounce'
import { makeServer } from './server'
import FloatingVue from 'floating-vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './assets/styles/index.css'

import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

Vue.config.productionTip = false

// Plugins
Vue.use(PiniaVuePlugin)
Vue.use(AppRipple)
Vue.use(VueCompositionAPI)
Vue.use(VueMeta)
Vue.use(vueDebounce, { listenTo: 'input' })
Vue.use(FloatingVue)
Vue.use(LottieVuePlayer)

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

// Init
new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app')

if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_MOCKING === 'true') {
  makeServer()
}
