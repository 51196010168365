<template>
  <footer class="dialog-footer" :class="classes">
    <slot></slot>
  </footer>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppDialogFooter',
  emits: [],
  props: {},
  setup(props, { slots }) {
    const classes = computed(() => {
      // Check component has default slot
      if (slots.default) {
        // Find any AppButton component in default slot that  does not have a theme for propsData
        // propsData is an object of additional data to be passed to component
        // AppButton that do not have propsData.theme will use default prop value, primary
        const hasPrimaryButton = slots.default().find((item) => {
          const { componentOptions } = item
          if (componentOptions && componentOptions.tag === 'AppButton') {
            const { propsData } = componentOptions
            return !propsData.theme
          }
          return false
        })

        if (hasPrimaryButton) {
          return ['dialog-footer--preferred-option']
        }
      }

      return []
    })

    return {
      classes,
    }
  },
}
</script>

<style scoped>
.dialog-footer {
  @apply w-full;
  @apply mt-0;
  button {
    @apply block;
    @apply w-full;
  }

  &.dialog-footer--preferred-option {
    button {
      &.button--primary {
        @apply rounded-none;
        @apply mt-2;
        ::v-deep .md-ripple {
          @apply rounded-none;
        }
      }
      &:not(.button--primary) {
        @apply w-auto;
        @apply mx-auto;
        @apply mb-2;
      }
    }
  }
  &:not(.dialog-footer--preferred-option) {
    /* // TODO Looks ugly, figma comment #316
    //@apply px-5; */
    button {
      @apply h-12;
      &:not(:first-child) {
        @apply border-t border-gray-lighter;
      }
      @apply rounded-none;
      ::v-deep .md-ripple {
        @apply rounded-none;
      }
    }
  }
}
</style>
