/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Sole trader, General Partnership, Limited Partnership, Foundation
 */
export const regionValues = {
  regionId: 11811,
  defaultCountryFrom: 'SE',
  defaultCountryFromOnfido: 'SWE',
  defaultCountryCodeFrom: 46,
  defaultCurrencyFrom: 'SEK',
  defaultLanguageCulture: 'sv-SE',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{3}\\s*\\d{2}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['sv-SE', 'en-SE'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2401', '2402', '2403', '2404', '2405', '2406'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2403'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
