/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'RO',
  allowedCompanyTypesForStp: ['4701', '4702', '4703'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4701'],
  postalCodeRegex: `^\\d{6}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
