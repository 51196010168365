import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import { useConsentStore } from '@/stores/consent'

export default new APIHandler(
  API,
  () => {
    const consentStore = useConsentStore()
    const dfxId = consentStore.getDfxIdFromCookie
    return {
      method: 'GET',
      url: `/authorize/sso_settings`,
      params: {
        dfxId,
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        isLiteRegistration: response.data.isLiteRegistration,
        loginUri: response.data.loginUri,
        tokenUri: response.data.tokenUri,
      },
    }
  }
)
