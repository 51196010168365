/**
 * @allowedCompanyTypesForStp Ltd, Establishment
 */
export const regionValues = {
  defaultCountryFrom: 'LI',
  allowedCompanyTypesForStp: ['4501', '4502'],
  allowedSTPCountry: true,
  marketingPromptCompanies: [],
  postalCodeRegex: `^(948[5-9])|(949[0-7])$`,
  addressRegex: `^[\\w\u0430-\u044fa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
