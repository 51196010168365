import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import VerifyResponse from '@/models/Verify/VerifyResponse'
import VerifyError from '@/models/Verify/VerifyError'

export default new APIHandler(
  API,
  ({ country, companyType, kycInformation, userCertification }) => {
    return {
      method: 'POST',
      url: `verify/corporatependingactions`,
      data: {
        country,
        companyType,
        kycInformation,
        userCertification,
      },
    }
  },
  (response) => {
    // ideally return verified Boolean and empty kycrequirements for case true
    const isVerified = response.data.data.verified?.toLowerCase() === 'true'
    return {
      data: isVerified ? { verified: 'true' } : new VerifyResponse(response.data),
      errors: isVerified ? [] : response.data.errors.map((error) => new VerifyError(error)),
    }
  }
)
