import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import AddressSearchResult from '@/models/CorporateRegistration/AddressSearchResult/app'

export default new APIHandler(
  API,
  ({ searchTerm, country, searchContext }) => {
    searchTerm = encodeURIComponent(searchTerm)
    return {
      method: 'GET',
      url: `address?searchTerm=${searchTerm}${country ? `&country=${country}` : ''}${
        searchContext ? `&searchContext=${searchContext}` : ''
      }`,
    }
  },
  ({ data: { data: { items = [] } = {} } = {} }) => {
    return {
      data: items.map((item) => new AddressSearchResult(item)),
    }
  }
)
