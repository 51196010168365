import { computed, reactive, ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import router from '@/router'

import getClient from '@/api/corporate/client/get'
import postStpEkycCheck from '@/api/corporate/client/stpEkycCheck/post'
import postStpApproval from '@/api/corporate/verify/stpApproveCorporate/post'
import stpStartApproval from '@/api/corporate/verify/stpStartApproveCorporate/post'
import stpGetApproval from '@/api/corporate/verify/stpGetApproveCorporate/post'
import getPurposeOfPaymentsCall from '@/api/corporate/purposeOfPayments/get'
import postCorporatePendingActions from '@/api/corporate/verify/corporatePendingActions/post'
import postFile from '@/api/corporate/verify/docs/post'
import getCorpProfileCall from '@/api/corporate/profiles/profile/get'
import putCorpProfileCall from '@/api/corporate/profiles/profile/put'
import additionalInformationMock from '@/data/pendingActions/additionalInformationMockData'
import companyTypeMapper from '@/composables/companyTypeMapper'
import { isEveryIndividualsValid } from '@/helpers/businessOwners'
import { getRegionFromCountry } from '@/utils/i18n'

import config from '@/config/pendingActions'
import mockedCompanyDetailApi from '@/data/pendingActions/companyDetails'
import { selectAddress, addressFormat } from '@/utils/address'

// ------- STORES -------
import { useAnalyticsStore } from './analytics'
import { useAuthStore } from './auth'
import { useAppStore } from './app'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useCountriesStore } from './countries'
import { useDeviceStore } from '@/stores/device'
import { useCompanyDetailsStore } from './companyDetails'
import { useConsentStore } from '@/stores/consent'

// eslint-disable-next-line prettier/prettier
export const useDashboardStore = defineStore(
  'dashboard',
  () => {
    // ------- STORES -------
    const countriesStore = useCountriesStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const deviceStore = useDeviceStore()
    const consentStore = useConsentStore()

    // ------- STATE -------
    const steps = ref([
      {
        title: 'AdditionalInfo',
        path: '/dashboard/pending-actions/additional-information/questions',
        pageTitle: 'Questions',
      },
    ])
    const authorizedPersonnel = ref([])
    const authorizedSignatoryDetails = ref({})
    const activeStepIdx = ref(0)
    const uploadedFile = ref({})
    const corpUserDetails = ref({})
    const corpProfile = ref({})
    const pendingActions = ref({})
    const additionalInformation = ref({})
    const questionsAndAnswers = ref([])
    const purposeOfPayments = ref([])
    const documentaryEvidenceStatus = ref([])
    const additionalInformationStatus = ref([
      { name: 'AdditionalInfo', title: 'questions', status: 'pending', hidden: false },
      {
        name: 'AuthorizedPersonnel',
        title: 'authorized personnel',
        status: 'pending',
        hidden: false,
      },
    ])
    const isFirstLoad = ref(false)
    const agreeAllOwners = ref(false)
    const agreeHasNoOwners = ref(false)
    const pendingActionsConfig = ref({})
    const stepperItems = ref({})
    const mockedCompanyDetails = ref(null)
    const companyDetails = ref({})
    const addressCompanyDetails = ref({})
    const currentRoute = ref('')
    const isStepperHidden = ref(false)
    const businessOwnersFromBvd = ref([])
    // This is the working array based on BVD data from the API (we can add/remove from this)
    const suggestedBvdPersonnelResult = ref([])
    const directorsFromBvd = ref([])
    const bvdData = ref({})
    const businessOwnersOptions = ref(null)
    const hasSuggestedIndividualsFinished = ref(false)
    const displayHVModal = ref(true)

    // ------- GETTERS -------
    const getOtherDocumentRequestText = computed(
      () => corpUserDetails.value.extraInfoRequired?.otherDocumentsText
    )
    const getOtherQuestionRequestText = computed(
      () => corpUserDetails.value.extraInfoRequired?.otherQuestionsText
    )
    const getOtherDocumentDisplayableIds = computed(
      () => pendingActionsConfig.otherQuestionDisplayableIds
    )
    const getIsExtraInfoRequired = computed(() => pendingActions.value.data?.isExtraInfoRequired)
    const getCompanyDetailsCompletionStatus = computed(() => {
      if (getIsExtraInfoRequired.value) {
        return true
      }
      const companyDetailsSteps = getStepperItems('companyDetails')
      if (!companyDetailsSteps) {
        return false
      }
      const stepsCompleteness = companyDetailsSteps.map((step) => step.isItemChecked || false)
      return stepsCompleteness.length > 0 ? stepsCompleteness.every((x) => !!x) : false
    })
    const getBusinessPurposesCompletionStatus = computed(() => {
      const requiredPurposes = additionalInformation.value.fields || []
      const completedSoFar = questionsAndAnswers.value
      const mandatoryFields = requiredPurposes.filter((field) => field.mandatory)
      const allBusinessPurposesCompleted = mandatoryFields.every((requirement) => {
        const answerToReq = completedSoFar.find((ans) => ans.id === requirement.id)
        return answerToReq && answerToReq?.value
      })
      return allBusinessPurposesCompleted
    })

    const getAdditionalDocumentsCompletionStatus = computed(() => {
      const uploadedFiles = uploadedFile.value
      // check if file is successfully uploaded by s3key
      return Object.values(uploadedFiles).every((doc) => !!doc.s3key && doc.s3key !== '')
    })

    const getIsUboAdded = computed(() => authorizedPersonnel.value.some((item) => item.isUbo))

    const getHasOwnersOrAgreedNoOwners = computed(
      () => agreeHasNoOwners.value || getIsUboAdded.value
    )

    const getNeedsDirector = computed(() => {
      // referring to https://euronet.atlassian.net/wiki/spaces/XSS/pages/3004335455/Corp+Pending+Actions+Rules
      const companiesThatRequireDirector = [1401, 1402, 1503, 1101] // TODO: remove hardcode values
      return companiesThatRequireDirector.includes(corpProfile.value.companyDetails?.companyTypeId)
    })

    const getIsDirectorAdded = computed(() =>
      authorizedPersonnel.value.some((item) => item.isDirector)
    )

    // For ready to submit validation
    const getNeedsDirectorOrDirectorIsAdded = computed(
      () => getNeedsDirector.value || getIsDirectorAdded.value
    )

    const getIsOwnershipLimitExceeded = computed(() => {
      const numberOfUbo = authorizedPersonnel.value.filter((item) => item.isUbo).length || 0
      return numberOfUbo >= 4
    })

    const getAuthorizedSignatory = computed(() => {
      const { firstName, lastName, isSignatory, email } = corpProfile.value.mainContact ?? {}
      return {
        firstName,
        lastName,
        isSignatory,
        email,
      }
    })

    const getFormAuthorizedSignatory = computed(() =>
      authorizedPersonnel.value.find((personnel) => personnel.isSignatory)
    )

    const getUboCount = computed(
      () => authorizedPersonnel.value.filter((item) => item.isUbo || false).length
    )

    const getIsEveryIndividualsValid = computed(() =>
      isEveryIndividualsValid(authorizedPersonnel.value)
    )

    const getIsUsLlcOrSp = computed(() => {
      const allowedCompanyTypes = [1402, 1405] // TODO: remove hardcode values
      const companyTypeId = corpProfile.value.companyDetails?.companyTypeId
      return allowedCompanyTypes.includes(companyTypeId)
    })

    const getIsUsCorp = computed(() => corpProfile.value.companyDetails?.companyTypeId === 1401)
    const getIsCaPrivate = computed(() => corpProfile.value.companyDetails?.companyTypeId === 1503)
    const getIsCaSole = computed(() => corpProfile.value.companyDetails?.companyTypeId === 1506)
    const getIsGbSole = computed(() => corpProfile.value.companyDetails?.companyTypeId === 1103)
    const getIsGbLimited = computed(() => corpProfile.value.companyDetails?.companyTypeId === 1101)
    const getShouldShowDirectorBanner = computed(() => {
      // LLC or SP always shows the banner
      if (getIsUsLlcOrSp || getIsCaSole) {
        // Shows the banner if has owner or a director
        return authorizedPersonnel.value.length > 0 && !getIsDirectorAdded.value
      }
      // Corps we show the banner if only if the director was not added
      return !getIsDirectorAdded.value
    })
    const getRequiredStpPagesForExtraInfo = computed(() => {
      let result = new Set()
      if (!pendingActions.value.data?.kycRequirements || !getIsExtraInfoRequired.value) {
        return result
      }
      const kycRequirements = pendingActions.value.data?.kycRequirements
      for (const requirement of kycRequirements) {
        if (requirement.group === 'OtherInfo') {
          result.add('businessPurpose')
        } else if (requirement.group === 'IdDoc') {
          result.add('additionalDocuments')
        }
      }
      return result
    })
    const getRedirectUrlWithDashboardFlag = computed(() => {
      const redirectUrl = corporateRegistrationStore.getRedirectUrl
      //set flag to true in order to ack not to redirect back
      return `${redirectUrl}?pendingActionsCompleted=true`
    })
    const getPendingActionsSubmitText = computed(() => {
      const profileCountry = corpProfile.value.mainContact?.address?.country
      const countryConfig = countriesStore.getCountryConfigByCountryCode(profileCountry)
      const isAllowedCountry = countryConfig?.allowedSTPCountry || false
      if (isAllowedCountry) {
        const animationText =
          pendingActionsConfig.value.submit.animationText[getRegionFromCountry(profileCountry)] ||
          false
        return animationText ? animationText : []
      }
    })
    const getAccountIsAllowedForStp = computed(() => {
      // Check is profile is allow for STP
      if (corpProfile) {
        // Check country
        const profileCountry = corpProfile.value.registrationAddress?.country
        const countryConfig = countriesStore.getCountryConfigByCountryCode(profileCountry || '')
        if (countryConfig) {
          // Check company type
          const profileCompanyType = corpProfile.value.companyDetails.companyTypeId.toString()
          const isAllowedCompanyType =
            countryConfig?.allowedCompanyTypesForStp?.includes(profileCompanyType) || false
          // Return condition
          return (countryConfig?.allowedSTPCountry || false) && isAllowedCompanyType
        }
      }
      return false
    })

    const getPendingListItems = computed(() => {
      let { pendingActionsList } = getStpPageById('PendingActions').content
      if (!(getRequiredStpPagesForExtraInfo.value && pendingActionsConfig.value)) {
        return []
      }
      // case returned: display the extra questions/docs tabs only
      if (getIsExtraInfoRequired.value) {
        return pendingActionsList.filter((item) =>
          getRequiredStpPagesForExtraInfo.value.has(item.id)
        )
      }
      // hide documents if not being asked
      if (documentaryEvidenceStatus.value.length === 0) {
        return pendingActionsList.filter((item) => item.id !== 'additionalDocuments')
      }
      // default: return the base list from BFF
      return pendingActionsList
    })

    const getCompanyInfoFields = computed(() => {
      if (corpProfile.value) {
        const country = corpProfile.value?.registrationAddress?.country
        const getCompanyDetails = companyDetails.value
        const { registeredaddress, tradingaddress } = addressCompanyDetails.value

        let registeredBusinessAddress = {
          street: [
            registeredaddress?.companyAddress?.freeFormat?.addressLine1,
            registeredaddress?.companyAddress?.freeFormat?.addressLine2,
            registeredaddress?.companyAddress?.freeFormat?.addressLine3,
          ]
            .filter(Boolean)
            .join(', '),
          city: registeredaddress?.companyAddress?.freeFormat?.place,
          state: registeredaddress?.companyAddress?.freeFormat?.county,
          postalCode: registeredaddress?.companyAddress?.freeFormat?.postalCode,
          country: country,
        }
        let operatingAddress = {
          street: [
            tradingaddress?.companyAddress?.freeFormat?.addressLine1,
            tradingaddress?.companyAddress?.freeFormat?.addressLine2,
            tradingaddress?.companyAddress?.freeFormat?.addressLine3,
          ]
            .filter(Boolean)
            .join(', '),
          city: tradingaddress?.companyAddress?.freeFormat?.place,
          state: tradingaddress?.companyAddress?.freeFormat?.county,
          postalCode: tradingaddress?.companyAddress?.freeFormat?.postalCode,
          country: country,
        }
        if (corpProfile.value.registrationAddress?.fixedFormat) {
          registeredBusinessAddress = {
            street: [
              registeredaddress?.companyAddress?.fixedFormat?.unit,
              registeredaddress?.companyAddress?.fixedFormat?.streetNumber,
              registeredaddress?.companyAddress?.fixedFormat?.streetName,
              registeredaddress?.companyAddress?.fixedFormat?.streetType,
            ]
              .filter(Boolean)
              .join(', '),
            city: registeredaddress?.companyAddress?.fixedFormat?.place,
            state: registeredaddress?.companyAddress?.fixedFormat?.county,
            postalCode: registeredaddress?.companyAddress?.fixedFormat?.postalCode,
            country: country,
          }
          operatingAddress = {
            street: [
              tradingaddress?.companyAddress?.fixedFormat?.unit,
              tradingaddress?.companyAddress?.fixedFormat?.streetNumber,
              tradingaddress?.companyAddress?.fixedFormat?.streetName,
              tradingaddress?.companyAddress?.fixedFormat?.streetType,
            ]
              .filter(Boolean)
              .join(', '),
            city: tradingaddress?.companyAddress?.fixedFormat?.place,
            state: tradingaddress?.companyAddress?.fixedFormat?.county,
            postalCode: tradingaddress?.companyAddress?.fixedFormat?.postalCode,
            country: country,
          }
        }

        return {
          id: 'companyInformation',
          companyInformation: {
            FullLegalName: getCompanyDetails.registeredname,
            TradingName: getCompanyDetails.tradingname,
            CompanyType: getCompanyDetails.companytype,
            DateOfEstablishment: getCompanyDetails.incorporationdate,
            RegistrationNumber: getCompanyDetails.registrationnumber,
            VatNumber: getCompanyDetails.vatNumber || '',
            RegisteredBusinessAddress: registeredBusinessAddress,
            OperatingAddress: operatingAddress,
            OnlinePresence: getCompanyDetails.website || '',
            IpAddressCountry: useDeviceStore.country,
          },
        }
      }
    })

    const getAllPendingReadyToSubmit = computed(() => {
      // Submission Status
      // 1. Company details
      const isCompanyDetailsReadyToSubmit = getCompanyDetailsCompletionStatus.value
      // 2. Business Purposes
      const isBusinessPurposesReadyToSubmit = getBusinessPurposesCompletionStatus.value
      // 3. Documents
      const isAdditionalDocumentsReadyToSubmit = getAdditionalDocumentsCompletionStatus.value
      // 4. Business Owners
      const isBusinessOwnersReadyToSubmit = getIsDirectorAdded.value
      return (
        isCompanyDetailsReadyToSubmit &&
        isBusinessPurposesReadyToSubmit &&
        isAdditionalDocumentsReadyToSubmit &&
        isBusinessOwnersReadyToSubmit
      )
    })

    const getCurrentStepperItems = computed(() => stepperItems.value[currentRoute.value])

    const getActiveStepperItem = computed(
      () => getCurrentStepperItems.value?.find((item) => item.activeItem) || null
    )

    const getStepperOptions = computed(() => {
      const pageConfig = getStpPageById(currentRoute.value)
      if (pageConfig?.stepperOptions) {
        return pageConfig.stepperOptions
      }
      return {}
    })

    const getStepperOptionsHiddenFlag = computed(() => {
      const pageConfig = getStpPageById(currentRoute.value)
      return !!pageConfig?.stepperOptions?.isHidden
    })

    const getStpPages = computed(() => pendingActionsConfig.value.stp.pages)
    const getStepperInitializeState = computed(() => Object.keys(stepperItems.value).length === 0)
    const getIsStepperActivated = computed(() => {
      const pageConfig = getStpPageById(currentRoute.value)
      return pageConfig ? pageConfig.isStepper : false
    })

    const getAuthorizedPersonnelFromBvd = computed(() =>
      businessOwnersFromBvd.value.concat(directorsFromBvd.value)
    )

    const getBusinessCentralUrl = () => {
      // Get DFX cookie
      const dfxId = consentStore.getDfxIdFromCookie
      // Get Api user
      // Return business central URL
      return dfxId || corpProfile.value.isApiUser ? config.VUE_APP_URL_BUSINESS_CENTRAL : false
    }

    // Return right redirection URL (FXWeb/Galileo or (DFX Id/API user))
    const getRedirectUrl = () => getRedirectUrlWithDashboardFlag.value || getBusinessCentralUrl

    // getters with args:
    const getQuestionsAndAnswersValueById = (id) => {
      const persistedQuestionsAndAnswers = questionsAndAnswers.value
      if (persistedQuestionsAndAnswers.length > 0) {
        return persistedQuestionsAndAnswers.find((question) => question.id === id)?.value
      }
      return ''
    }

    const getIsAdditionalInformationStatusCompleted = (section) => {
      if (section === 'questionsAndAnswers') {
        return questionsAndAnswers.value.length > 0
      } else if (section === 'authorizedPersonnel') {
        return getHasOwnersOrAgreedNoOwners.value && getNeedsDirectorOrDirectorIsAdded.value
      }
      return false
    }

    const getAuthorizedPerssonelById = (id) =>
      authorizedPersonnel.value.find((personnel) => personnel.id === id)

    const getStpPageById = (key) =>
      pendingActionsConfig.value.stp.pages.find((page) => page.id === key)

    const getStepperItems = (group) => {
      const res = stepperItems?.value[group] ?? []
      const country = corpProfile.value?.registrationAddress?.country
      const regNumberIndex = res.findIndex((field) => field.id === 'registrationnumber')
      if (regNumberIndex !== -1) {
        if (['AU', 'NF'].includes(country)) {
          res[regNumberIndex].name = 'ABN/ACN'
        } else if (['NZ', 'CK'].includes(country)) {
          res[regNumberIndex].name = 'NZBN'
        }
      }
      return res
    }

    const getFundDestinationCountriesByIsoCode = (isoCodes) => {
      const allCountriesFullName = corporateRegistrationStore.allCountryList
        .filter((countryObj) => isoCodes.includes(countryObj.code))
        .map((c) => c.name)
      return allCountriesFullName
    }

    const getStepperIsCompleted = (group) => {
      if (stepperItems.value[group]) {
        return stepperItems.value[group]
      }
    }

    const getIsHighValueClient = computed(() => {
      if (corpProfile.value && corpProfile.value.companyDetails) {
        const expectedAnnualAmount = corpProfile.value.companyDetails.expectedAnnualTradingVolume

        if (expectedAnnualAmount && expectedAnnualAmount >= 5 * 10e4) {
          return true
        }
      }

      return false
    })

    const getPhoneNumber = computed(() => {
      if (corpProfile.value) {
        return corpProfile.value.mainContact?.phoneNumber
      }

      return undefined
    })

    const getClientNumber = computed(() => {
      if (corpProfile.value) {
        return corpProfile.value.clientNumber
      }

      return undefined
    })

    const getTbuName = computed(() => {
      if (corpProfile.value) {
        return corpProfile.value.tbuName
      }

      return undefined
    })

    // ------- ACTIONS -------
    const setUploadedFile = (file) => {
      const { target, data } = file
      const clonedUploadedFile = { ...uploadedFile.value }
      uploadedFile.value = {
        ...clonedUploadedFile,
        [target]: { ...clonedUploadedFile[target], ...data },
      }
    }
    const setClearFile = (fileName) => (uploadedFile.value[fileName] = {})

    const setAdditionalInformation = (questions) => {
      if (getAccountIsAllowedForStp.value) {
        // Passes the additionalInformation values on STP as is
        additionalInformation.value = questions
      } else {
        // Ignores fields for old flow users
        const fieldsToIgnore = ['timeInIndustry']
        const fieldsToAdd = ['heardAboutUs', 'planToUseAccount']
        const isExtraInfoRequired = getIsExtraInfoRequired.value

        let fields = questions.fields.filter(({ id }) => !fieldsToIgnore.includes(id))
        if (!isExtraInfoRequired) {
          fields = fields.concat(
            additionalInformationMock.fields.filter((item) => fieldsToAdd.includes(item.id))
          )
        }

        const value = {
          ...questions,
          fields: fields,
        }
        additionalInformation.value = value
      }
    }

    const initAdditionalInformationStatus = (pendingActions) => {
      let additionalInformationStatusValue = additionalInformationStatus.value
      const country = corpProfile.value.registrationAddress.country.toUpperCase()
      const authorizedPersonnelTitle = countriesStore.getCountrySpecificText(
        'AuthorizedPersonnelTitle',
        country
      )
      // Set hidden values
      const setHiddenValue = (
        statusName,
        group,
        additionalInformationStatusValue,
        pendingActions
      ) => {
        const questionIndex = additionalInformationStatusValue.findIndex(
          (status) => status.name === statusName
        )

        additionalInformationStatusValue[questionIndex] = {
          ...additionalInformationStatusValue[questionIndex],
          ...(statusName === 'AuthorizedPersonnel' && { title: authorizedPersonnelTitle }),
          hidden: !pendingActions.data.kycRequirements.some((req) => req.group === group),
        }
      }
      setHiddenValue(
        'AdditionalInfo',
        'OtherInfo',
        additionalInformationStatusValue,
        pendingActions
      )
      setHiddenValue(
        'AuthorizedPersonnel',
        'Person',
        additionalInformationStatusValue,
        pendingActions
      )
      additionalInformationStatus.value = additionalInformationStatusValue
    }

    const setAdditionalInformationStatus = (section) => {
      if (section === 'questionsAndAnswers') {
        let answersCount = 0
        questionsAndAnswers.value.forEach((qa) => {
          if (qa.value !== '') {
            answersCount++
          }
        })

        if (additionalInformation.value?.fields.length === answersCount) {
          additionalInformationStatus.value[0].status = 'completed'

          // Set event traits dynamically based on question fetched by Corp BFF
          const mappedEventTraits = {
            destinationCountries: 'destinationOfFunds',
            generateBusiness: 'businessStrategy',
            businessDescription: 'businessDescription',
            purpose: 'purposeOfPayment',
            purposeOfAccount: 'purposeOfAccount',
            isHighRiskCustomer: 'isHighRiskCustomer',
          }

          const traits = reactive({})
          additionalInformation.value?.fields.forEach((field) => {
            const key = mappedEventTraits[field.id]
            if (key) {
              traits[key] = getQuestionsAndAnswersValueById(field.id)?.toString()
            }
          })

          analyticsStore.track({
            event: 'Additional Information Saved',
            traits: traits,
          })
        } else {
          additionalInformationStatus.value[0].status = 'pending'
        }
      } else if (section === 'authorizedPersonnel') {
        if (
          ((getIsUsCorp.value || getIsCaPrivate.value || getIsGbSole.value) &&
            agreeHasNoOwners.value) ||
          (getHasOwnersOrAgreedNoOwners.value &&
            (getIsUsLlcOrSp.value || getIsDirectorAdded.value)) ||
          getIsAdditionalInformationStatusCompleted(section)
        ) {
          additionalInformationStatus.value[1].status = 'completed'
        } else {
          additionalInformationStatus.value[1].status = 'pending'
        }
      }
    }

    const setAuthorizedPersonnel = (ap) => {
      authorizedPersonnel.value = [...authorizedPersonnel.value, ap]
    }

    const setAuthorizedPersonnelByIndex = ({ authorizedPersonnel: ap, index }) => {
      const authorizedPersonnelList = [...authorizedPersonnel.value]
      authorizedPersonnelList.splice(index, 1, ap)
      authorizedPersonnel.value = [...authorizedPersonnelList]
    }

    const deleteAuthorizedPersonnelByIndex = ({ index }) => {
      const authorizedPersonnelList = [...authorizedPersonnel.value]
      authorizedPersonnelList.splice(index, 1)
      authorizedPersonnel.value = [...authorizedPersonnelList]

      if (authorizedPersonnel.value.length === 0) {
        additionalInformationStatus.value[1].status = 'pending'
        agreeHasNoOwners.value = false
      }
    }

    const setStepperItems = ({ id, items }) => {
      // refactor this
      const si = { ...stepperItems.value }
      si[id] = items
      stepperItems.value = si
    }

    const setQuestionAndAnswerById = (questionAndAnswer) => {
      let targetId
      // Check for duplicates
      const existingObj = questionsAndAnswers.value.find((item) => {
        targetId = questionAndAnswer.id
        return item.idText === questionAndAnswer.idText
      })
      // If it's not a duplicate, add it; if it's a duplicate, update it
      if (!existingObj) {
        questionsAndAnswers.value.push(questionAndAnswer)
      } else {
        // Update the existing object
        questionsAndAnswers.value = questionsAndAnswers.value.map((item) => {
          if (item.id === targetId) {
            item.value = questionAndAnswer.value
          }
          return item
        })
      }
    }

    const resetState = () => {
      steps.value = [
        {
          title: 'AdditionalInfo',
          path: '/dashboard/pending-actions/additional-information/questions',
          pageTitle: 'Questions',
        },
      ]
      authorizedPersonnel.value = []
      authorizedSignatoryDetails.value = {}
      activeStepIdx.value = 0
      uploadedFile.value = {}
      corpUserDetails.value = {}
      corpProfile.value = {}
      pendingActions.value = {}
      additionalInformation.value = {}
      questionsAndAnswers.value = []
      purposeOfPayments.value = []
      documentaryEvidenceStatus.value = []
      additionalInformationStatus.value = [
        { name: 'AdditionalInfo', title: 'questions', status: 'pending', hidden: false },
        {
          name: 'AuthorizedPersonnel',
          title: 'authorized personnel',
          status: 'pending',
          hidden: false,
        },
      ]
      isFirstLoad.value = false
      agreeAllOwners.value = false
      agreeHasNoOwners.value = false
      pendingActionsConfig.value = {}
      stepperItems.value = {}
      mockedCompanyDetails.value = null
      companyDetails.value = {}
      addressCompanyDetails.value = {}
      currentRoute.value = ''
      isStepperHidden.value = false
      businessOwnersFromBvd.value = []
      directorsFromBvd.value = []
      bvdData.value = {}
    }

    const copySuggestedIndListFromBvd = () => {
      suggestedBvdPersonnelResult.value = JSON.parse(
        JSON.stringify(getAuthorizedPersonnelFromBvd.value)
      )
    }

    // APIs
    const getCorpUserDetails = async () => {
      const data = await getClient.exec()
      if (data) {
        corpUserDetails.value = data
      } else {
        throw new Error(`No CorpUserDetails saved in dynamoDb`)
      }
    }

    const getPurposeOfPayments = async () => {
      const data = await getPurposeOfPaymentsCall.exec()
      if (data) {
        purposeOfPayments.value = data
      } else {
        throw new Error(`No CorpUserDetails saved in dynamoDb`)
      }
    }

    const getCorpProfile = async ({ profileId }) => {
      const data = await getCorpProfileCall.exec({ profileId })
      if (data) {
        corpProfile.value = data
      } else {
        throw new Error(`No corp user profile`)
      }
    }

    const putCorpProfile = async () => {
      const deviceCountry = deviceStore.country
      const dfxId = consentStore.getDfxIdFromCookie
      const country = corpProfile.value?.registrationAddress?.country || ''
      const authorizedSignatory = getFormAuthorizedSignatory.value

      const userAddress = addressCompanyDetails.value?.registeredaddress?.companyAddress
      const profileAddress = corpProfile.value.registrationAddress
      const isFixedFormat = Boolean(corpProfile.value.registrationAddress?.fixedFormat)
      const isUserAddress = Boolean(userAddress)
      // use addressCompanyDetails.registeredaddress if provided (address field in company details page)
      // otherwise, fall back to corpProfile.registrationAddress
      const registrationAddressFreeFormat = selectAddress(
        isUserAddress,
        userAddress?.freeFormat,
        profileAddress?.freeFormat || {}
      )
      const registrationAddressFixedFormat = selectAddress(
        isUserAddress,
        userAddress?.fixedFormat,
        profileAddress?.fixedFormat || {}
      )
      const registrationAddress = addressFormat(
        country,
        isFixedFormat ? registrationAddressFixedFormat : registrationAddressFreeFormat, // data
        isFixedFormat
      )

      // use addressCompanyDetails.tradingaddress if provided (address field in company details page)
      // otherwise, fall back to corpProfile.tradingAddress if exists
      // otherwise, fall back to corpProfile.registrationAddress
      const userTradingAddress = addressCompanyDetails.value?.tradingaddress?.companyAddress
      const profileTradingAddress = corpProfile.value.tradingAddress
      const isUserTradingAddress = Boolean(userTradingAddress)

      const tradingAddressFreeFormat = selectAddress(
        isUserTradingAddress,
        userTradingAddress?.freeFormat,
        profileTradingAddress?.freeFormat || profileAddress?.freeFormat || {}
      )
      const tradingAddressFixedFormat = selectAddress(
        isUserTradingAddress,
        userTradingAddress?.fixedFormat,
        profileTradingAddress?.fixedFormat || profileAddress?.fixedFormat || {}
      )
      const tradingAddress = addressFormat(
        country,
        isFixedFormat ? tradingAddressFixedFormat : tradingAddressFreeFormat, // data
        isFixedFormat
      )
      const mainContactAddress = addressFormat(
        authorizedSignatory?.fixedFormatAddress?.countryCode ||
          authorizedSignatory?.freeFormatAddress?.countryCode ||
          '',
        isFixedFormat
          ? authorizedSignatory?.fixedFormatAddress
          : authorizedSignatory?.freeFormatAddress,
        isFixedFormat
      )

      const body = {
        CorporateAccountProfile: {
          ProfileId: corpProfile.value.profileId,
          ClientId: corpProfile.value.clientId,
          ClientNumber: corpProfile.value.clientNumber,
          BusinessUnitId: corpProfile.value.trmBusinessUnitId,
        },
        Region: getRegionFromCountry(country),
        CountryFromIpAtRegistration: deviceCountry,
        ProblematicCountryMismatchAtReg: country === deviceCountry,
        RegistrationAddress: registrationAddress,
        TradingAddress: tradingAddress,
        MainContact: {
          FirstName: corpProfile.value.mainContact.firstName,
          LastName: corpProfile.value.mainContact.lastName,
          Email: corpProfile.value.mainContact.email,
          DateOfBirth: authorizedSignatory?.dob || '',
          PhonePrefix: corpProfile.value.mainContact.phonePrefix,
          PhoneNumber: corpProfile.value.mainContact.phoneNumber,
          Address: mainContactAddress,
          IsSignatory: corpProfile.value.mainContact.isSignatory || false,
        },
        CompanyDetails: {
          RegisteredName: companyDetails.value.registeredname,
          NatureOfBusinessId: companyDetails.value.natureofbusiness,
          SubIndustryTypeId: companyDetails.value.industry,
          ExpectedAnnualTradingVolume:
            corpProfile.value.companyDetails.expectedAnnualTradingVolume || 0,
          IncorporationDate:
            companyDetails.value.incorporationdate ||
            corpProfile.value.companyDetails.incorporationDate ||
            '',
          Website: companyDetails.value.website || '',
        },
        dfxLeadSources: {
          dfxId: dfxId || '',
        },
      }

      const data = await putCorpProfileCall.exec({
        profileId: corpProfile.value.profileId,
        fields: body,
      })
      if (data) {
        corpProfile.value = data
      } else {
        throw new Error(`Could not update corp profile`)
      }
    }

    const getPendingActions = async (req) => {
      const data = await postCorporatePendingActions.exec(req)
      if (data) {
        pendingActions.value = data
        return data.data
      } else {
        throw new Error(`No corporate pending actions, kyc`)
      }
    }
    const getBvdData = async () => {
      const bvdid = corpProfile.value.companyDetails.registrationNumber
      const companyCountry = corpProfile.value.registrationAddress.country

      const result = await companyDetailsStore.getCompanyNameSearchResult({
        name: bvdid,
        country: companyCountry,
      })

      if (result && result.length > 0) {
        bvdData.value = result[0]
        // Get business owner fields
        const { boFirstName, boLastName, boIsPep } = result[0]

        // Assuming BOs are listed in order
        const boList = boFirstName
          .map((firstName, index) => {
            return {
              firstName: firstName ?? '',
              lastName: boLastName[index] ?? '',
              dob: null,
              address: null,
              isUbo: true,
              personneltype: ['isUbo'],
              isPep: boIsPep[index],
            }
          })
          .filter((eachBo) => eachBo.firstName || eachBo.lastName)

        // Get directors fields
        const { cpyFirstName, cpyLastName } = result[0]
        // Assuming Directors are listed in order

        const directorList = cpyFirstName
          .map((firstName, index) => {
            return {
              firstName: firstName ?? '',
              lastName: cpyLastName[index] ?? '',
              dob: null,
              address: null,
              isDirector: true,
              personneltype: ['isDirector'],
            }
          })
          .filter((eachCpy) => eachCpy.firstName || eachCpy.lastName)

        businessOwnersFromBvd.value = boList
        directorsFromBvd.value = directorList
      }
    }

    const setSaveFile = ({ docType, s3key, file }) => {
      uploadedFile.value[docType] = { ...file, s3key }
    }

    const saveFile = async ({
      docType,
      country,
      companyType,
      formData,
      file,
      goToPendingActions,
    }) => {
      try {
        const req = {
          docType,
          country,
          companyType,
          formData,
        }

        const data = await postFile.exec(req)

        if (data) {
          if (goToPendingActions) {
            router.push({ name: 'PendingActions' })
          }
          setSaveFile({ docType, s3key: data.key, file })
        }
      } catch (error) {
        uploadedFile.value[docType] = {}
        // dispatch('setClearFile', docType)
        router.push({
          name: 'PendingActionError',
          params: {
            errorType: 'dashboard',
          },
        })
        throw new Error(`unable to save file`)
      }
    }

    const allowedInDashboard = async () => {
      let profileId = authStore.profileId
      let regionCompanyTypesMapping = new Map(pendingActionsConfig.value.allowedForPendingActions)

      await getCorpProfile({ profileId })
      const country = corpProfile.value.registrationAddress.country.toUpperCase()
      const companyTypeAcronym = corpProfile.value.companyDetails.companyTypeAcronym
      const companyType =
        companyTypeAcronym === 'NOT SUPPORTED'
          ? companyTypeMapper(corpProfile.value.companyDetails.companyType, country)
          : companyTypeAcronym

      const isAccountIsAllowedForStp = getAccountIsAllowedForStp.value

      // if not allowed region/companyType combo then redirect to fxweb with flag
      if (
        !regionCompanyTypesMapping.get(country)?.includes(companyType) &&
        !isAccountIsAllowedForStp
      ) {
        window.location.href = getRedirectUrlWithDashboardFlag.value
        return false
      }

      try {
        await getCorpUserDetails()
        await getPendingActions({ country, companyType: companyType })
      } catch (ex) {
        router.push({
          name: 'PendingActionError',
          params: {
            isFullReload: true,
            errorTycorpProfilepe: 'dashboard',
          },
        })

        appStore.logException({
          text: 'Verify exception',
          exception: ex,
        })
        return true
      }
      if (pendingActions.value.data.verified.toLowerCase() === 'true') {
        window.location.href = getRedirectUrlWithDashboardFlag.value
        return false
      }

      // initialize status to hide non needed section
      initAdditionalInformationStatus(pendingActions.value)

      // save submitted authorizedPersonnelList based on backend response
      const authorizedPersonnelList = corpUserDetails.value.authorizedPersonnel || []
      if (authorizedPersonnelList.length > 0) {
        authorizedPersonnel.value = authorizedPersonnelList
      }

      // save submitted questions and answers based on backend response
      const answers = corpUserDetails.value.answers || []
      const isAnswersSavedInStore = questionsAndAnswers.value.length > 0

      if (!isAnswersSavedInStore && answers.length > 0) {
        const payload = answers.map(({ questionID, questionText, answer }) => ({
          id: questionID,
          idText: questionText,
          value: questionID === 'destinationCountries' ? answer.split(',') : answer,
        }))

        questionsAndAnswers.value = payload
        // dispatch('setQuestionsAndAnswers', payload)
      }

      // populate questionList
      const questionIdList = pendingActions.value.data?.kycRequirements
        .filter((item) => item.group === 'OtherInfo')
        .map((item) => item.id)
      const mappedAdditionalInformation = {
        ...additionalInformationMock,
        fields: additionalInformationMock.fields.filter((item) => questionIdList.includes(item.id)),
      }
      await getPurposeOfPayments()
      await corporateRegistrationStore.fetchAllCountryList()
      setAdditionalInformation(mappedAdditionalInformation)
      // await dispatch('corporateRegistration/getAllCountryList', {}, { root: true })
      // await dispatch('setAdditionalInformation', mappedAdditionalInformation)

      const documentIdList = pendingActions.value.data?.kycRequirements
        .filter((item) => item.group === 'IdDoc')
        .map((item) => item.id)
      // uploadedFile state setup
      documentIdList.forEach((docId) => {
        setUploadedFile({ target: docId, data: {} })
        // dispatch('setUploadedFile', { target: docId, data: {} })
      })

      // this is the documentList we use as reference
      const localDocumentListStatus = documentIdList.map((docId) => {
        const docNameMap = {
          idBankStatement: 'Bank Statement',
          bankStatement: 'Bank Statement',
          idArticleOfOrganization: 'Business Registration Document',
          utilityBill: 'Utility Bill',
          supplierInvoice: 'Invoice',
          idCustomerInvoice: 'Customer Invoice',
          idPassportOrDriversLicense: "Passport or Driver's License",
          idBusinessLicense: 'Business License',
          idPartnershipAgreement: 'Partnership Agreement',
          idOtherDocument: 'Other Documents',
        }
        const docName = docNameMap[docId]
        if (docName === undefined) {
          throw new Error('docId not supported')
        }

        // check status for uploadedFile in store
        let status = 'pending'
        if (uploadedFile.value && Object.keys(uploadedFile.value).length > 0) {
          Object.entries(uploadedFile.value).forEach(([uploadedDocId, uploadedDoc]) => {
            if (uploadedDocId === docId) {
              status = uploadedDoc?.s3key ? 'completed' : 'pending'
            }
          })
        }
        return { name: docName, title: docName, status: status, id: docId }
      })

      documentaryEvidenceStatus.value = localDocumentListStatus
      appStore.isDashboardLoaded = true
      return true
    }

    const setStepperValue = ({ itemId, targetKey, value, customDescription, setChecked }) => {
      let items = getStepperItems(targetKey)

      items = items.map((item) => {
        if (item.id === itemId) {
          item.activeItem = false
          if (customDescription) {
            item.description = customDescription
          } else if (item.id === 'destinationCountries') {
            let countryFullName = getFundDestinationCountriesByIsoCode(value)
            item.description = countryFullName.join(', ')
          } else {
            item.description = value
          }
          item.isItemChecked = setChecked

          // Selects the next step to show
          let firstUndefined = items.find((x) => {
            return !x.isItemChecked && x.id !== 'registrationnumber'
          })

          if (firstUndefined) {
            firstUndefined.activeItem = true
          }
        }
        return item
      })
      setStepperItems({ id: targetKey, items })
    }

    const setStepperActive = ({ id, targetKey }) => {
      let items = getStepperItems(targetKey)
      const itemsToUpdate = items.map((item) => {
        return { ...item, activeItem: item.id === id }
      })
      setStepperItems({ id: targetKey, items: itemsToUpdate })
    }

    const setCompanyDetailsValue = ({ value, id }) => {
      companyDetails.value[id] = value
    }

    const setInitializeSteps = () => {
      try {
        let updatedStepperItems = {}
        // Get values from store (company details, business purpose, additional documents)
        const getStepperPages = getStpPages.value.filter((item) => item.isStepper)
        const additionalDocuments = documentaryEvidenceStatus.value.map(({ id, name }) => ({
          id,
          name,
        }))
        const additionalInfoIds = additionalInformation.value?.fields?.map((q) => q.id) || []
        getStepperPages.forEach((item, i) => {
          updatedStepperItems[item.id] = getStepperPages[i].stepperOptions.items
          if (item.id === 'additionalDocuments') {
            updatedStepperItems[item.id] = additionalDocuments
          } else if (item.id === 'businessPurpose') {
            // only render what backend returns
            updatedStepperItems[item.id] = updatedStepperItems[item.id].filter((bp) =>
              additionalInfoIds.includes(bp.id)
            )
          } else if (item.id === 'companyDetails') {
            const { regionName } = corpProfile.value
            // filter out region-specific questions
            // add allowed regions in PA.json
            updatedStepperItems[item.id] = updatedStepperItems[item.id].filter((cdItem) => {
              return 'allowedRegions' in cdItem ? cdItem.allowedRegions.includes(regionName) : true
            })
          }
          // Set active item if list is empty
          if (updatedStepperItems[item.id].length > 0) {
            updatedStepperItems[item.id][0].activeItem = true
          }
        })
        stepperItems.value = updatedStepperItems
      } catch (error) {
        throw `Error loading stepper items: ${error}`
      }
    }

    const getStpEkycCheck = async (ekycCheckRequest) => {
      const data = await postStpEkycCheck.exec(ekycCheckRequest)
      if (data) {
        return data
      } else {
        throw new Error(`StpEkycCheck Failed`)
      }
    }

    const getStpApproval = async (stpApprovalRequest) => {
      const data = await postStpApproval.exec(stpApprovalRequest)
      if (data) {
        return data
      } else {
        throw new Error(`StpApproval Failed`)
      }
    }

    const getStpStartApproval = async (stpApprovalRequest) => {
      const data = await stpStartApproval.exec(stpApprovalRequest)
      if (data) {
        return data
      } else {
        throw new Error(`StpApproval Failed`)
      }
    }

    const getStpGetApproval = async (stpApprovalRequest, complete = false) => {
      const data = await stpGetApproval.exec({ stpApprovalRequest, complete })
      if (data) {
        return data
      } else {
        throw new Error(`StpApproval Failed`)
      }
    }

    const setPendingActionsConfig = () => (pendingActionsConfig.value = config)
    const setMockedCompanyDetails = () => (mockedCompanyDetails.value = mockedCompanyDetailApi)
    const setClearSessionStore = (isInitializingSteppers = true) => {
      if (isInitializingSteppers) {
        setInitializeSteps()
      }
      resetState()
    }

    return {
      // State
      steps,
      authorizedPersonnel,
      authorizedSignatoryDetails,
      activeStepIdx,
      uploadedFile,
      corpUserDetails,
      corpProfile,
      pendingActions,
      additionalInformation,
      questionsAndAnswers,
      purposeOfPayments,
      documentaryEvidenceStatus,
      additionalInformationStatus,
      isFirstLoad,
      agreeAllOwners,
      agreeHasNoOwners,
      stepperItems,
      mockedCompanyDetails,
      companyDetails,
      addressCompanyDetails,
      currentRoute,
      isStepperHidden,
      businessOwnersFromBvd,
      directorsFromBvd,
      businessOwnersOptions,
      hasSuggestedIndividualsFinished,
      suggestedBvdPersonnelResult,
      bvdData,
      displayHVModal,

      // Getters
      getOtherDocumentRequestText,
      getOtherQuestionRequestText,
      getOtherDocumentDisplayableIds,
      getIsExtraInfoRequired,
      getCompanyDetailsCompletionStatus,
      getBusinessPurposesCompletionStatus,
      getAdditionalDocumentsCompletionStatus,
      getQuestionsAndAnswersValueById,
      getIsUboAdded,
      getHasOwnersOrAgreedNoOwners,
      getNeedsDirector,
      getIsDirectorAdded,
      getNeedsDirectorOrDirectorIsAdded,
      getIsAdditionalInformationStatusCompleted,
      getIsOwnershipLimitExceeded,
      getAuthorizedPerssonelById,
      getAuthorizedSignatory,
      getFormAuthorizedSignatory,
      getUboCount,
      getIsEveryIndividualsValid,
      getIsUsLlcOrSp,
      getIsUsCorp,
      getIsCaPrivate,
      getIsCaSole,
      getIsGbSole,
      getIsGbLimited,
      getShouldShowDirectorBanner,
      getRequiredStpPagesForExtraInfo,
      getRedirectUrlWithDashboardFlag,
      getPendingActionsSubmitText,
      getAccountIsAllowedForStp,
      getStpPageById,
      getPendingListItems,
      getStepperItems,
      getFundDestinationCountriesByIsoCode,
      getCompanyInfoFields,
      getAllPendingReadyToSubmit,
      getCurrentStepperItems,
      getActiveStepperItem,
      getStepperOptions,
      getStepperOptionsHiddenFlag,
      getStpPages,
      getStepperInitializeState,
      getIsStepperActivated,
      getStepperIsCompleted,
      getAuthorizedPersonnelFromBvd,
      getRedirectUrl,
      pendingActionsConfig,
      getIsHighValueClient,

      // Actions
      setStepperItems,
      setUploadedFile,
      setClearFile,
      setAdditionalInformation,
      initAdditionalInformationStatus,
      setAdditionalInformationStatus,
      setAuthorizedPersonnel,
      setAuthorizedPersonnelByIndex,
      deleteAuthorizedPersonnelByIndex,
      setQuestionAndAnswerById,
      resetState,
      saveFile,
      setStepperActive,
      setCompanyDetailsValue,
      setInitializeSteps,
      allowedInDashboard,
      setStepperValue,
      setPendingActionsConfig,
      setMockedCompanyDetails,
      setClearSessionStore,
      copySuggestedIndListFromBvd,
      getPhoneNumber,
      getClientNumber,
      getTbuName,

      // APIs
      getCorpUserDetails,
      getPurposeOfPayments,
      getCorpProfile,
      putCorpProfile,
      getPendingActions,
      getBvdData,
      getStpEkycCheck,
      getStpApproval,
      getStpStartApproval,
      getStpGetApproval,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
