import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import CorpUserDetails from '@/models/CorpUserDetails/app'

export default new APIHandler(
  API,
  () => {
    return {
      method: 'GET',
      url: `client`,
    }
  },
  (response) => {
    return new CorpUserDetails(response.data.data)
  }
)
