/**
 * @allowedCompanyTypesForStp Sole Trader, Ltd, Limited Liability Company, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'HU',
  allowedCompanyTypesForStp: ['3401', '3402', '3403', '3404'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3401'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wÁÉÚŐÓÜŰÖÍáéúőóűüöía-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
