import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  ({ docType, country, companyType, formData }, config) => {
    return {
      ...config,
      method: 'POST',
      url: `verify/docs/${docType}/${country}/${companyType}`,
      data: formData,
    }
  },
  (response) => {
    return {
      ...response,
      key: response.data.data.key,
    }
  }
)
