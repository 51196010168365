/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Publicly Traded Partnership, Sole trader, Partnerships
 */

export const regionValues = {
  regionId: 1505,
  defaultCountryFrom: 'IT',
  defaultCountryFromOnfido: 'ITA',
  defaultCountryCodeFrom: 33,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'it-IT',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{5}$`,
  availableLanguageCultures: ['it-IT', 'en-IT'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2101', '2102', '2103', '2104', '2105'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2104'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
