import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  (termsAndConditionsVersion) => {
    return {
      method: 'PUT',
      url: `terms-conditions`,
      data: {
        termsAndConditionsVersion
      },
    }
  },
  (response) => {
    return {
      ...response.data,
      data: response.data
    }
  }
)
