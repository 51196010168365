<template>
  <section class="card" :class="[classes, 'sm:max-w-' + size]">
    <div v-if="$slots.figureTitle" class="card-scroll">
      <!-- @slot header: for the card header, use `<AppCardHeader>` -->
      <slot name="header" />
      <div class="card-content">
        <slot name="figureTitle" />
        <!-- @slot default: for the card content, quirk `<AppCardFooter>` currently belongs here -->
        <div class="card-content-block">
          <slot />
        </div>
      </div>
    </div>
    <template v-else>
      <!-- @slot header: for the card header, use `<AppCardHeader>` -->
      <slot name="header" />
      <div class="card-content">
        <!-- @slot default: for the card content, quirk `<AppCardFooter>` currently belongs here -->
        <div class="card-content-block">
          <slot />
        </div>
      </div>
    </template>

    <!-- @slot footer: for the card footer-->
    <slot name="footer" />
  </section>
</template>

<script>
import { computed, onBeforeMount, provide, ref } from '@vue/composition-api'
import { useMediaQuery } from '@/composables/useMediaQuery'

export default {
  name: 'AppCard',
  emits: [],
  props: {
    sheet: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  setup(props, { slots }) {
    const mq = useMediaQuery()
    const hasFigureTitle = ref(false)
    const hasTabs = ref(false)
    const hasImageTitle = ref(false)

    onBeforeMount(() => {
      provide('hasFigureTitle', hasFigureTitle)
      provide('hasImageTitle', hasImageTitle)
      provide('hasTabs', hasTabs)
    })
    const classes = computed(() => {
      return {
        'card--has-header': slots.header,
        'card--has-content': slots.default,
        'card--has-footer': slots.footer,
        'card--has-figure-title': hasFigureTitle.value,
        'card--has-image-title': hasImageTitle.value,
        'card--has-tabs': hasTabs.value,
        'card--modal': props.modal,
        'card--sheet': props.sheet,
        'card--centered': props.centered,
        'card--xs': mq.current === 'xs',
      }
    })

    return { classes, hasFigureTitle }
  },
}
</script>

<style lang="postcss" scoped>
.card {
  @apply w-full h-full;
  @apply flex flex-col justify-between;
  @apply bg-white;
  @apply overflow-hidden;
  &.card--content-no-padding {
    ::v-deep {
      .card-content {
        @apply pt-0 !important;
      }
    }
  }

  .card-scroll {
    @apply h-full;
    @apply flex-1 flex flex-col;
  }

  @screen sm {
    @apply h-auto;
    @apply m-auto;
    @apply border border-gray-light rounded-xxl;
    @apply overflow-visible;
  }
  &.card--has-footer {
    @screen sm {
      @apply pb-12;
    }
  }
  &.card--wide {
    @screen sm {
      @apply max-w-2xl;
    }
  }
  &.card--sheet {
    @apply border-none;
  }
  &.card--has-figure-title {
    .card-scroll {
      @apply overflow-y-auto;
    }

    .card-content {
      @apply overflow-y-visible;
      /* @apply max-w-xl */
    }

    &.card--has-header {
      .card-header {
        @apply sticky top-0 bg-white rounded-t-xxl;
      }

      .card-content {
        @apply -mt-10;
        @screen sm {
          @apply -mt-4;
        }
      }
    }
  }
  &.card--has-image-title {
    .card-content {
      @apply flex flex-col items-center;
      @apply m-auto flex-initial;
    }
    &.card--has-header {
      .card-content {
        @screen sm {
          @apply -mt-6;
        }
      }
    }
  }
  &.card--has-tabs {
    &.card--xs {
      ::v-deep {
        .card-header {
          @apply border-0;
        }

        .card-content {
          @apply p-0;
        }

        .tabs {
          @apply h-full;
          @apply flex flex-col;
        }

        .tabs-buttons {
          @apply mb-0 px-5;
        }

        .tabs-panel {
          @apply flex-1;
          @apply overflow-y-auto;
          @apply p-6;
        }
      }
    }
  }
}

.card-content {
  @apply flex-1;
  @apply overflow-y-auto;
  @apply pt-12 px-6 pb-96;

  /*
  .card-content-block
  Exists purely to collapse margins, since margins dont collapse with flex box
  https://www.joshwcomeau.com/css/rules-of-margin-collapse/

  An alternate option would be to target .card-content > *:last-child, but this wouldn't work when
  only text is provided to the slot
  */
  .card-content-block {
    @apply mb-6;
  }

  @screen sm {
    @apply overflow-y-visible;
    @apply pt-12 px-12;
    .card-content-block {
      @apply mb-12;
    }
  }
  .card--has-header & {
    @screen sm {
      @apply pt-0;
    }
  }
}
</style>
