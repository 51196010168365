/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC, Limited Partnership, General Partnership, Limited Partnership with Shares, Joint Stock Company
 */
export const regionValues = {
  defaultCountryFrom: 'BG',
  allowedCompanyTypesForStp: ['3301', '3302', '3303', '3304', '3305', '3306', '3307'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3301'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
