import { defineStore } from 'pinia'
import { ref } from '@vue/composition-api'

import * as randomBytes from 'randombytes'
import * as md5 from 'md5'
import getUserCountry from '@/api/corporate/countries/userCountry/get'

import { useAppStore } from '@/stores/app'
import { useCompanyDetailsStore } from '@/stores/companyDetails'

export const useDeviceStore = defineStore(
  'device',
  () => {
    // ------- STORES -------
    const appStore = useAppStore()
    const companyDetailsStore = useCompanyDetailsStore()

    // ------- STATE -------
    const country = ref('GB')
    const corporateRegistrationIds = ref({})
    const id = ref(md5(`RMTWeb-${new Date().toISOString()}-${randomBytes(16)}`))

    // ------- ACTIONS -------

    const checkDeviceCountry = async () => {
      try {
        const { data } = await getUserCountry.exec()
        country.value = data
        companyDetailsStore.companyCountry = data
      } catch (e) {
        appStore.logException({ text: 'Exception during getting user country', exception: e })
      }
    }
    const getCorporateRegistrationIdForUser = (user) => {
      if (corporateRegistrationIds.value[user]) {
        return corporateRegistrationIds.value[user]
      }
      return null
    }

    return {
      country,
      corporateRegistrationIds,
      id,

      checkDeviceCountry,
      getCorporateRegistrationIdForUser,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
