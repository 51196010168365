// Define country groups for easier management and readability
const countryGroups = {
  UK: ['GB', 'JE', 'IM', 'GG', 'GI'],
  AU: ['AU', 'NF'],
  NZ: ['NZ', 'CK'],
  EU: [
    'AT',
    'BE',
    'BG',
    'CH',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LI',
    'LT',
    'LU',
    'LV',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SE',
    'SK',
  ],
}

// Utility function to determine if a country is in a specific group
export const isInCountryGroup = (group, country) => {
  return countryGroups[group]?.includes(country) || false
}

// Exported utility function to get the region or country
export const getRegionFromCountry = (country) => {
  for (let region in countryGroups) {
    if (countryGroups[region].includes(country)) {
      return region
    }
  }
  return false
}

// maps some islands under the appropriate country
export const getMappedCountry = (country) => {
  switch (country) {
    case 'CK':
      return 'NZ'
    case 'NF':
      return 'AU'
    default:
      return country
  }
}

export const isApacRegion = (country) => {
  return [...countryGroups.AU, ...countryGroups.NZ].includes(country)
    ? 'APAC'
    : getRegionFromCountry(country)
}
