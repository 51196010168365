import Vue from 'vue'
import {
  provide,
  inject,
  computed,
  reactive,
  getCurrentInstance,
  onUnmounted,
} from '@vue/composition-api'
import router from '@/router'

const RouterSymbol = Symbol()

export function provideRouter(router) {
  provide(RouterSymbol, router)
}
export function useRouter() {
  return inject(RouterSymbol)
}

const routeData = reactive({
  params: {},
  query: {},
  meta: {},
  path: '',
  matched: [],
})

export function getRoutePath(args) {
  let path = args
  if (args.path) {
    path = args.path
  }

  if (args.name) {
    const { route } = router.resolve({
      name: args.name,
    })

    path = route.fullPath
  }

  return path
}

let routerStackPaths = [window.locationpathname]
let routerStackStates = [window.history.state]
const routerGo = router.go
const routerReplace = router.replace
const routerPush = router.push
router.go = (steps) => {
  if (steps < 0) {
    for (let i = 0; i < Math.abs(steps); i++) {
      routerStackPaths.pop()
      routerStackStates.pop()
    }
  }
  routerGo.call(router, steps)
}

router.replace = async (args) => {
  const path = getRoutePath(args)

  routerStackPaths.pop()
  routerStackStates.pop()

  await routerReplace.call(router, args)

  routerStackPaths.push(path)
  routerStackStates.push(window.history.state)
}

router.push = async (args) => {
  const path = getRoutePath(args)

  await routerPush.call(router, args)

  routerStackPaths.push(path)
  routerStackStates.push(window.history.state)
}
router.pop = () => {
  router.go(-1)
}
router.goBackSteps = async (steps) => {
  if (steps < 1) {
    return false
  }

  const pos = routerStackStates.length - 1 - steps
  if (pos < 0) {
    return false
  }

  // unfortunately we can't fully rely on our stack as i.e. Onfido pushes pages to the window.history - hence we look for the correct state we should have and restore to it
  const lastState = routerStackStates[routerStackStates.length - 1]
  if (window.history.state && lastState && window.history.state.key !== lastState.key) {
    let maxSize = window.history.length

    let promiseResolve
    const promise = new Promise((resolve) => {
      promiseResolve = resolve
    })
    const popstate = window.onpopstate
    window.onpopstate = () => {
      if (!window.history.state) {
        promiseResolve(false)
        return
      }

      if (window.history.state.key === lastState.key) {
        promiseResolve(true)
        return
      }

      maxSize--
      if (maxSize <= 0 || window.history.length <= 0) {
        promiseResolve(false)
        return
      }

      routerGo.call(router, -1)
    }
    routerGo.call(router, -1)
    const result = await promise
    window.onpopstate = popstate
    if (!result) {
      return false
    }
  }
  routerGo.call(router, steps * -1)

  routerStackPaths = routerStackPaths.slice(0, pos + 1)
  routerStackStates = routerStackStates.slice(0, pos + 1)

  return true
}
router.goBackTo = async (args) => {
  const path = getRoutePath(args)

  const pos = routerStackPaths.lastIndexOf(path)

  if (pos >= 0) {
    const steps = routerStackPaths.length - 1 - pos
    const result = await router.goBackSteps(steps)
    return result
  }
  return false
}

router.afterEach((route) => {
  const { params, query, meta, path, matched } = route

  routeData.params = params
  routeData.query = query
  routeData.meta = meta
  routeData.path = path
  routeData.matched = matched
})

export function useParams() {
  return computed(() => routeData.params)
}

export function useQuery() {
  return computed(() => routeData.query)
}

export function useMeta() {
  return computed(() => routeData.meta)
}

export function usePath() {
  return computed(() => routeData.path)
}

export function useMatched() {
  return computed(() => routeData.matched)
}

export function toParentRoute() {
  const matched = routeData.matched
  let parent = null

  if (matched.length < 2) {
    //no parents redirect to home
    router.replace({ name: 'home' })
  } else {
    //take second last element of matched object
    parent = matched[matched.length - 2]
    router.replace({ name: parent.name })
  }
}

export function redirectToPath(redirectRoute) {
  if (router.currentRoute.path !== redirectRoute) {
    router.replace(redirectRoute)
  }
}

const unregisterCallback = (callbacks, handler) => {
  const index = callbacks.indexOf(handler)
  if (index > -1) {
    callbacks.splice(index, 1)
  }
}

export function onHook(name, callback) {
  const vm = getCurrentInstance()
  const merge = Vue.config.optionMergeStrategies[name]
  if (vm && merge) {
    const prototype = Object.getPrototypeOf(vm.proxy.$options)
    prototype[name] = merge(vm.proxy.$options[name], callback)

    onUnmounted(() => unregisterCallback(prototype[name], callback))
  }
}

export function onBeforeRouteUpdate(callback) {
  return onHook('beforeRouteUpdate', callback)
}

export function onBeforeRouteLeave(callback) {
  return onHook('beforeRouteLeave', callback)
}

// Avoid redundant navigation using the route name
export function checkCurrentRouteAndRedirect(router, name) {
  const {
    currentRoute: { name: currentName },
  } = router

  if (currentName !== name) {
    router.push({ name })
  }
}
