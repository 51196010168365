import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import Country from '@/models/CorporateRegistration/Country/app'

export default new APIHandler(
  API,
  ({ all = false } = {}) => {
    const url = all ? 'countries?all=true' : 'countries'
    return {
      method: 'GET',
      url,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((item) => new Country(item)),
    }
  }
)
