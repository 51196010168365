import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'

// Guards
import { useAppOverlayRouterGuards } from '@/composables/useAppOverlay'

Vue.use(VueCompositionAPI)
Vue.use(VueRouter)

export const routes = [
  /*
  =====================================================================================================
  XE corporate registration
  =====================================================================================================
  */
  {
    path: '',
    name: 'home',
    redirect: { name: 'register' },
  },
  {
    path: '/registration',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '@/layouts/register/Register.vue'),
    children: [
      {
        path: 'personal-details',
        name: 'RegisterPersonalDetails',
        component: () =>
          import(
            /* webpackChunkName: "RegisterPersonalDetails" */ '@/views/corporate/RegistrationPersonalDetails/RegistrationPersonalDetails.vue'
          ),
      },
      {
        path: 'business-type',
        name: 'RegisterBusinessInformation',
        component: () =>
          import(
            /* webpackChunkName: "RegisterBusinessInformation" */ '@/views/corporate/RegisterBusinessInformation/RegisterBusinessInformation.vue'
          ),
      },
      {
        path: 'business-search',
        name: 'RegisterBusinessSearch',
        component: () =>
          import(
            /* webpackChunkName: "RegisterBusinessSearch" */ '@/views/corporate/RegisterBusinessSearch/RegisterBusinessSearch.vue'
          ),
      },
      {
        path: 'business-address/search',
        name: 'RegisterAddressSearch',
        component: () =>
          import(
            /* webpackChunkName: "RegisterAddressSearch" */ '@/views/corporate/RegisterAddressSearch.vue'
          ),
        props: {
          searchType: 'business',
        },
      },
      {
        path: 'business-details',
        name: 'RegisterBusinessDetails',
        component: () =>
          import(
            /* webpackChunkName: "RegisterBusinessDetails" */ '@/views/corporate/RegisterBusinessDetails/RegisterBusinessDetails.vue'
          ),
      },
      {
        path: 'business-address',
        name: 'BusinessAddress',
        component: () =>
          import(
            /* webpackChunkName: "RegisterBusinessAddress" */ '@/views/corporate/RegisterBusinessAddress.vue'
          ),
      },
      {
        path: 'business-requirements',
        name: 'RegisterCurrencyAndVolume',
        component: () =>
          import(
            /* webpackChunkName: "RegisterCurrencyAndVolume" */ '@/views/corporate/RegisterCurrencyAndVolume.vue'
          ),
      },
      {
        path: 'personal-address/selection',
        name: 'RegisterPersonalAddressSelection',
        component: () =>
          import(
            /* webpackChunkName: "RegisterPersonalAddressSelection" */ '@/views/corporate/RegisterPersonalAddressSelection.vue'
          ),
      },
      {
        path: 'personal-address/search',
        name: 'RegisterPersonalAddressSearch',
        component: () =>
          import(
            /* webpackChunkName: "RegisterAddressSearch" */ '@/views/corporate/RegisterAddressSearch.vue'
          ),
        props: {
          searchType: 'personal',
        },
      },
      {
        path: 'business-operating-address/search',
        name: 'RegisterBusinessAddressSearch',
        component: () =>
          import(
            /* webpackChunkName: "RegisterAddressSearch" */ '@/views/corporate/RegisterAddressSearch.vue'
          ),
        props: {
          searchType: 'operatingBusiness',
        },
      },
      {
        path: 'business-operating-address',
        name: 'OperatingAddress',
        component: () =>
          import(
            /* webpackChunkName: "RegisterOperatingAddress" */ '@/views/corporate/RegisterOperatingAddress.vue'
          ),
      },
      {
        path: 'personal-address',
        name: 'PersonalAddress',
        component: () =>
          import(
            /* webpackChunkName: "RegisterPersonalAddress" */ '@/views/corporate/RegisterPersonalAddress.vue'
          ),
      },
      {
        path: 'terms-and-conditions',
        name: 'PersonalTerms',
        component: () =>
          import(
            /* webpackChunkName: "RegisterPersonalTerms" */ '@/views/corporate/RegisterPersonalTerms/RegisterPersonalTerms.vue'
          ),
      },
      {
        path: 'account-created',
        name: 'RegisterBusinessCreating',
        component: () =>
          import(
            /* webpackChunkName: "RegisterBusinessCreating" */ '@/views/corporate/RegisterBusinessCreating.vue'
          ),
      },
      {
        path: 'submit-registration',
        name: 'RegisterSubmit',
        component: () =>
          import(/* webpackChunkName: "RegisterSubmit" */ '@/views/corporate/RegisterSubmit.vue'),
      },
      {
        path: 'error',
        name: 'RegisterError',
        component: () => import(/* webpackChunkName: "RegisterError" */ '@/views/Error.vue'),
        props: (route) => {
          const layoutName = route.matched[0].name
          return route.params.errorType
            ? { errorType: route.params.errorType, layout: layoutName }
            : { errorType: route.query.errorType, layout: layoutName }
        },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: { name: 'PendingActions' },
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '@/layouts/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'pending-actions',
        name: 'PendingActions',
        component: () =>
          import(
            /* webpackChunkName: "PendingActions" */ '@/views/dashboard/DashboardPendingActions/DashboardPendingActions.vue'
          ),
        children: [
          {
            path: 'account-created',
            name: 'AccountCreated',
            component: () =>
              import(
                /* webpackChunkName: "AccountCreated" */ '@/views/dashboard/DashboardAccountCreated.vue'
              ),
            props: (route) => ({ ...route.params, showModal: true }),
          },
          {
            path: 'documentary-evidence',
            redirect: { name: 'PendingActions' },
          },
          {
            path: 'documentary-evidence/:documentName/:documentId',
            name: 'DocumentaryEvidence',
            component: () =>
              import(
                /* webpackChunkName: "DocumentaryEvidence" */ '@/views/dashboard/DashboardDocumentaryEvidence.vue'
              ),
            props: (route) => ({ ...route.params }),
          },
          {
            path: 'processing-information',
            name: 'ProcessingInfo',
            component: () =>
              import(
                /* webpackChunkName: "ProcessingInfo" */ '@/views/dashboard/DashboardProcessingInformation.vue'
              ),
          },
          {
            path: 'action-required',
            name: 'ActionRequired',
            component: () =>
              import(
                /* webpackChunkName: "ActionRequired" */ '@/views/dashboard/DashboardActionRequired.vue'
              ),
          },
        ],
      },
      {
        path: '/dashboard/pending-actions/company-details',
        name: 'companyDetails',
        component: () =>
          import(
            /* webpackChunkName: "companyDetails" */ '@/views/dashboard/DashboardCompanyDetails/DashboardCompanyDetails.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/business-purpose',
        name: 'businessPurpose',
        component: () =>
          import(
            /* webpackChunkName: "businessPurpose" */ '@/views/dashboard/DashboardBusinessPurpose/DashboardBusinessPurpose.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/business-owners',
        name: 'businessOwners',
        component: () =>
          import(
            /* webpackChunkName: "businessOwners" */ '@/views/dashboard/DashboardBusinessOwners/DashboardBusinessOwners.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/business-owners/details',
        name: 'BusinessOwnersDetails',
        props: (route) => ({ ...route.params }),
        component: () =>
          import(
            /* webpackChunkName: "businessOwners" */ '@/views/dashboard/DashboardBusinessOwners/BusinessOwnersDetails.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/additional-documents',
        name: 'additionalDocuments',
        component: () =>
          import(
            /* webpackChunkName: "additionalDocuments" */ '@/views/dashboard/DashboardAdditionalDocuments/DashboardAdditionalDocuments.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/additional-information/questions',
        name: 'AdditionalInfo',
        component: () =>
          import(
            /* webpackChunkName: "AdditionalInfo" */ '@/views/dashboard/DashboardAdditionalInfo.vue'
          ),
      },
      {
        path: '/dashboard/pending-actions/additional-information/authorized-personnel',
        name: 'AuthorizedPersonnel',
        component: () =>
          import(
            /* webpackChunkName: "AuthorizedPersonnel" */ '@/views/dashboard/DashboardAuthorizedPersonnel.vue'
          ),
        children: [
          {
            path: 'authorizedSignatories/:action?/:id?',
            name: 'AuthorizedSignatory',
            props: (route) => ({ ...route.params, showModal: true }),
            component: () =>
              import(
                /* webpackChunkName: "AuthorizedSignatory" */ '@/views/dashboard/AuthorizedSignatory.vue'
              ),
          },
        ],
        props: (route) => ({ ...route.params }),
      },
      {
        path: '/dashboard/pending-actions/submit',
        name: 'DashboardSubmit',
        component: () =>
          import(/* webpackChunkName: "DashboardSubmit" */ '@/views/dashboard/DashboardSubmit.vue'),
      },
      {
        path: '/dashboard/pending-actions/error',
        name: 'PendingActionError',
        component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
        props: (route) => {
          const layoutName = route.matched[0].name
          return route.params.errorType
            ? {
                errorType: route.params.errorType,
                layout: layoutName,
                isFullReload: route.params.isFullReload,
              }
            : {
                errorType: route.query.errorType,
                layout: layoutName,
                isFullReload: route.query.isFullReload,
              }
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

useAppOverlayRouterGuards(router)

export default router
