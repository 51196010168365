import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  ({ stpApprovalRequest, complete = false }) => {
    return {
      method: 'POST',
      url: `verify/stpgetapprovecorporate?complete=${complete}`,
      data: stpApprovalRequest,
    }
  },
  (response) => {
    return {
      ...response.data,
    }
  }
)
