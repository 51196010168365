export default class CorpUserDetails {
  constructor(corpUserDetails) {
    this.clientId = corpUserDetails.clientId
    this.documents = corpUserDetails.documents
    this.answers = corpUserDetails.answers
    this.authorizedPersonnel = corpUserDetails.authorizedPersonnel
    this.companyInformation = corpUserDetails.companyInformation
    this.extraInfoRequired = corpUserDetails.extraInfoRequired
    this.approvalConditions = corpUserDetails.approvalConditions
  }
}
