/**
 * @allowedCompanyTypesForStp Ltd, PLC, Sole Proprietorship, Limited Partnership with Shares, General Partnership, Limited Partnership, Limited Liability Partnership
 */
export const regionValues = {
  defaultCountryFrom: 'PL',
  allowedCompanyTypesForStp: ['3101', '3102', '3103', '3104', '3105', '3106', '3107'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3103'],
  postalCodeRegex: `^\\d{2}-\\d{3}$`,
  addressRegex: `^[\\wĄąĆćĘęlŁłŃńÓóŚśŹźŻża-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
