<template>
  <Transition :name="transition">
    <AppIcon v-if="loading" class="animate-spin" :size="size" v-bind="$attrs">
      <IconSpinner />
    </AppIcon>
  </Transition>
</template>

<script>
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconSpinner } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'AppSpinner',
  components: { AppIcon, IconSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'icon-expand',
    },
    size: {
      type: Number,
      default: 18,
    },
  },
}
</script>

<style scoped></style>
