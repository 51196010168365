import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  (ekycCheckRequest) => {
    return {
      method: 'POST',
      url: `/client/stpekyccheck`,
      data: ekycCheckRequest,
    }
  },
  (response) => {
    return { ...response.data }
  }
)
