import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  (config) => {
    return {
      ...config,
      method: 'GET',
      url: 'countries/user-country',
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.countryCode
    }
  }
)
