export const OPTIONS = {
  ACTIONS,
  PERSONNEL_TYPE,
  PERSONNEL_KEY,
}

export const ACTIONS = {
  create: 'create',
}

/* PERSONNEL */
export const PERSONNEL_TYPE = {
  authorizedSignatory: 'Auth Signatory',
  owner: 'Business Owner',
  director: 'Director',
}

export const PERSONNEL_KEY = {
  isUbo: 'isUbo',
  isDirector: 'isDirector',
}
