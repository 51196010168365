import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import { useConsentStore } from '@/stores/consent'

export default new APIHandler(
  API,
  (country) => {
    const consentStore = useConsentStore()
    const dfxId = consentStore.getDfxIdFromCookie
    return {
      method: 'GET',
      url: `terms-conditions/${country}`,
      params: {
        dfxId,
      },
    }
  },
  (response) => {
    return {
      ...response,
      data: {
        version: response.data.termsAndConditionsVersion,
        url: response.data.termsAndConditionsDocumentUrl,
        privacyUrl: response.data.privacyUrl,
      },
    }
  }
)
