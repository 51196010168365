import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import companyTypeMapper from '@/composables/companyTypeMapper'

export default new APIHandler(
  API,
  ({ profileId }) => {
    return {
      method: 'GET',
      url: `corporate/profiles/${profileId}`,
    }
  },
  (response) => {
    let { companyDetails, registrationAddress } = response.data.data
    const companyDetailsWithCompanyTypeAcronym = {
      ...companyDetails,
      companyTypeAcronym: companyTypeMapper(
        companyDetails.companyType,
        registrationAddress.country.toUpperCase()
      ),
    }
    return { ...response.data.data, companyDetails: companyDetailsWithCompanyTypeAcronym }
  }
)
