import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'

import Cookies from 'js-cookie'
import { clientAffiliateCookie, dfxCookie } from '@/composables/useConsentManager/useConsent'

// ------- Stores -------
import { useAuthStore } from '@/stores/auth'
import { useAnalyticsStore } from '@/stores/analytics'

export const useConsentStore = defineStore(
  'consent',
  () => {
    const authStore = useAuthStore()
    const analyticsStore = useAnalyticsStore()

    // ------- STATE -------
    const consentState = ref({
      marketing: false,
      performance: false,
      compliance: false,
    })

    const leadSourcesState = ref({
      dfxId: null,
      clientAffiliate: null,
    })

    // ------- ACTIONS -------

    const setAcquisitionSourceEvent = () => {
      const leadSourcesState = getLeadSourcesState
      const profileId = authStore.profileId
      let eventObject = {
        userId: profileId,
        traits: {
          acquisitionSource: '',
        },
      }

      if (Object.keys(leadSourcesState.value).length > 0) {
        eventObject.traits.acquisitionSource = leadSourcesState.value?.dfxId
          ? leadSourcesState.value.dfxId
          : `aff-${leadSourcesState.value?.clientAffiliate}`
        analyticsStore.identify(eventObject)
        return
      }
    }

    // ------- GETTERS -------

    const getLeadSourcesState = computed(() => {
      if (leadSourcesState.value) {
        return leadSourcesState.value
      }
      return {}
    })

    const getDfxIdFromCookie = computed(() => {
      const parsedDfxCookie = new URLSearchParams(Cookies.get(dfxCookie))
      const dfxId = parsedDfxCookie.get('_dfxid')
      return dfxId
    })

    const getClientAffiliateIdFromCookie = computed(() => {
      const parsedClientAffiliate = new URLSearchParams(Cookies.get(clientAffiliateCookie))
      const clientAffiliate = parsedClientAffiliate.get('_clientagentid')
      return clientAffiliate
    })

    // ------- ACTIONS -------
    const setConsentState = () => {
      const xeConsentState = consentState.value
      // Initialize analytics provider based on MKT consent
      if (xeConsentState.marketing) {
        analyticsStore.init({
          segmentKey: config.VUE_APP_KEYS_SEGMENT,
          amplitudeKey: config.VUE_APP_KEYS_AMPLITUDE,
          gtmKey: config.VUE_APP_KEYS_GTM,
        })
      }
    }

    return {
      consentState,
      leadSourcesState,
      setAcquisitionSourceEvent,
      getLeadSourcesState,
      getDfxIdFromCookie,
      getClientAffiliateIdFromCookie,
      setConsentState,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
