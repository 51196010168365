import { render, staticRenderFns } from "./AppSpinner.vue?vue&type=template&id=4571c72b&scoped=true&"
import script from "./AppSpinner.vue?vue&type=script&lang=js&"
export * from "./AppSpinner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4571c72b",
  null
  
)

export default component.exports