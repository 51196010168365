/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'LV',
  allowedCompanyTypesForStp: ['4401', '4402', '4403'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4401'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
