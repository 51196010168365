import Cookies from 'js-cookie'

export const clientAffiliateCookie = 'HiFX_ClientAffiliate'
export const dfxCookie = 'HiFX_DfxId'

// Consent model validation (Check value type)
export const validateConsentModel = (consent) => {
  try {
    const { performance, marketing, compliance } = consent

    return typeof performance === 'boolean' && typeof marketing === 'boolean' && compliance === true
  } catch (_e) {
    return false
  }
}

const consent = () => {
  const rawCookie = Cookies.get('xeConsentState')
  const consentCookie = JSON.parse(decodeURIComponent(rawCookie || '{}'))

  if (validateConsentModel(consentCookie)) {
    return consentCookie
  }
  return getConsentFromDatalayer()
}

const getConsentFromDatalayer = () => {
  const { dataLayer } = window
  const lastConsentChange = dataLayer.filter((value) => value.event === 'consentStateChange').pop()
  if (validateConsentModel(lastConsentChange)) {
    return lastConsentChange
  }
  return {
    performance: true,
    marketing: true,
    compliance: true,
  }
}

export const consentState = consent()

export const setDfxIdCookie = (dfxId) => {
  const _dfxid_reg = /^[0-9]{1,10}$/
  if (_dfxid_reg.test(dfxId)) {
    if (consentState && consentState.performance) {
      Cookies.remove(dfxCookie)
      Cookies.set(dfxCookie, `_dfxid=${dfxId}`, { expires: 30 })
    }
    return dfxId
  }
}

export const setClienAffiliateCookie = (clientAffiliateId) => {
  const _clientaffliate_reg = /^[0-9|A-z]{14}$/
  if (_clientaffliate_reg.test(clientAffiliateId)) {
    if (consentState && consentState.performance) {
      Cookies.remove(clientAffiliateCookie)
      Cookies.set(clientAffiliateCookie, `_clientagentid=${clientAffiliateId}`, { expires: 30 })
    }
    return clientAffiliateId
  }
}

export const cleanCookies = () => {
  Cookies.remove(dfxCookie)
  Cookies.remove(clientAffiliateCookie)
}

// Set Optimizely optout
export const setOptimzelyOptOut = (consentState) => {
  const { performance, marketing, compliance } = consentState

  // all consented, then setOptOut is false
  if (marketing && performance && compliance) {
    // Initialize Optimizely
    window['optimizely'] = window['optimizely'] || []
    // Turn off optOut
    window['optimizely'].push({ type: 'optOut', isOptOut: false })
    window.optout = false
  }

  // only marketing true, then setOptOut is false
  if (!marketing && performance && compliance) {
    // Initialize Optimizely
    window['optimizely'] = window['optimizely'] || []
    // Turn off optOut
    window['optimizely'].push({ type: 'optOut', isOptOut: false })
    window.optout = false
  }

  // marketing and compliance true then set optOut true
  if (marketing && !performance && compliance) {
    // Initialize Optimizely
    window['optimizely'] = window['optimizely'] || []
    // Set OptOut
    window['optimizely'].push({ type: 'optOut', isOptOut: true })
    window.optout = true
  }

  // only compliance true then set optOut true
  if (!marketing && !performance && compliance) {
    // Initialize Optimizely
    window['optimizely'] = window['optimizely'] || []
    // Set OptOut
    window['optimizely'].push({ type: 'optOut', isOptOut: true })
    window.optout = true
  }
}
