import XeField from '@/models/DynamicField/XeField'

export default class CorporateFieldGroup {
  constructor(fieldGroup) {
    this.fields = fieldGroup.fields.map(
      field => new XeField(fieldGroup.name, field)
    )
    this.name = fieldGroup.name
    this.mandatory = fieldGroup.mandatory
  }
}
