import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import NatureOfBusiness from '@/models/CorporateRegistration/NatureOfBusiness/app'

export default new APIHandler(
  API,
  () => {
    return {
      method: 'GET',
      url: `industrycategories`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.data.map((item) => new NatureOfBusiness(item)),
    }
  }
)
