<template>
  <button class="alert-action" @click="$emit('actionClick')">
    <slot>
      <AppIcon aria-label="Close">
        <IconClose />
      </AppIcon>
    </slot>
  </button>
</template>

<script>
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconClose } from '@moneytransfer.ui/euronet-icons'
export default {
  name: 'AppAlertAction',
  components: {
    AppIcon,
    IconClose,
  },
  setup() {
    return {}
  },
}
</script>

<style lang="postcss" scoped>
.alert-action {
  @apply flex justify-center items-center;
  @apply type-body;
  @apply type-caption font-bold;
  @apply ml-2;
  @apply whitespace-no-wrap;
}
</style>
