import { regionValues as AT } from '@/regions/AT'
import { regionValues as AU } from '@/regions/AU'
import { regionValues as BE } from '@/regions/BE'
import { regionValues as BG } from '@/regions/BG'
import { regionValues as CA } from '@/regions/CA'
import { regionValues as CH } from '@/regions/CH'
import { regionValues as CK } from '@/regions/CK'
import { regionValues as CY } from '@/regions/CY'
import { regionValues as CZ } from '@/regions/CZ'
import { regionValues as DE } from '@/regions/DE'
import { regionValues as DK } from '@/regions/DK'
import { regionValues as EE } from '@/regions/EE'
import { regionValues as ES } from '@/regions/ES'
import { regionValues as FI } from '@/regions/FI'
import { regionValues as FR } from '@/regions/FR'
import { regionValues as GG } from '@/regions/GG'
import { regionValues as GI } from '@/regions/GI'
import { regionValues as HR } from '@/regions/HR'
import { regionValues as HU } from '@/regions/HU'
import { regionValues as IE } from '@/regions/IE'
import { regionValues as IM } from '@/regions/IM'
import { regionValues as IT } from '@/regions/IT'
import { regionValues as JE } from '@/regions/JE'
import { regionValues as LI } from '@/regions/LI'
import { regionValues as LT } from '@/regions/LT'
import { regionValues as LU } from '@/regions/LU'
import { regionValues as LV } from '@/regions/LV'
import { regionValues as NL } from '@/regions/NL'
import { regionValues as NO } from '@/regions/NO'
import { regionValues as NF } from '@/regions/NF'
import { regionValues as NZ } from '@/regions/NZ'
import { regionValues as PL } from '@/regions/PL'
import { regionValues as PT } from '@/regions/PT'
import { regionValues as RO } from '@/regions/RO'
import { regionValues as SE } from '@/regions/SE'
import { regionValues as SK } from '@/regions/SK'
import { regionValues as GB } from '@/regions/UK'
import { regionValues as US } from '@/regions/US'

export default [
  AT,
  AU,
  BE,
  BG,
  CA,
  CH,
  CK,
  CY,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GG,
  GI,
  HR,
  HU,
  IE,
  IM,
  IT,
  JE,
  LI,
  LT,
  LU,
  LV,
  NL,
  NO,
  NF,
  NZ,
  PL,
  PT,
  RO,
  SE,
  SK,
  GB,
  US,
]
