/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole Trader, Listed Public Company, Partnership, Propietary Limited, Trust, Cooperative, Incorporated Association, Unincorporated Association
 */
export const regionValues = {
  defaultCountryFrom: 'CK',
  defaultCurrencyFrom: 'NZD',
  //allowedCompanyTypesForStp: ['4704', '4705', '4706', '4707', '4708', '4709', '4710', '4711'],
  //allowedSTPCountry: true,
  //marketingPromptCompanies: ['4704'],
  postalCodeRegex: `^\\d{4}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
