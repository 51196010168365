export const getInitials = (fullname) => {
  let names = fullname.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}
// Capitalize text
export const capitalizeText = (text) =>
  text.toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())

//
export const namesToArrayLowerCase = (firstname, lastname) => {
  return [...(firstname || '').split(' '), ...(lastname || '').split(' ')].map((term) =>
    term.toLowerCase()
  )
}

export const constantToString = (constant, caseType) => {
  let formattedText

  // Remove special characters
  formattedText = constant.replace(/[^a-zA-Z]/g, '')

  // Result
  if (caseType === 'lower') {
    return formattedText.toLocaleLowerCase()
  }
  if (caseType === 'capitalized') {
    return capitalizeText(formattedText)
  }
  return formattedText
}
