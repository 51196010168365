import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  ({ countryCode, companyTypeId }) => {
    return {
      method: 'GET',
      url: `marketingpreferenceconsent/marketingprompt?countryCode=${countryCode}&companyTypeId=${companyTypeId}`,
    }
  },
  (response) => {
    return {
      ...response.data,
      data: response.data,
    }
  }
)
