/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Limited Partnership, Sole Trader, Cooperative, Foundations and Associations, Limited Liability Sports Corporation, Labour Limited Corporation, General Partnership
 */
export const regionValues = {
  regionId: 9305,
  defaultCountryFrom: 'ES',
  defaultCountryCodeFrom: 34,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'es-ES',
  defaultLocation: {
    latitude: 40.46366700000001,
    longitude: -3.74922,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^[0-9 -]{5,5}$`,
  availableLanguageCultures: ['en-US'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: [
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
  ],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2004'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
