/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp PLC, Ltd, Sole trader, Partnerships, General Partnership, Limited Partnership
 */
export const regionValues = {
  regionId: 1005,
  defaultCountryFrom: 'AT',
  defaultCountryFromOnfido: 'AUT',
  defaultCountryCodeFrom: 43,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'de-AT',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['de-AT', 'en-AT'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2701', '2702', '2703', '2704', '2705', '2706'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2703'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
