import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import FieldError from '@/models/CorporateRegistration/FieldError/app'

export default new APIHandler(
  API,
  ({ id, countryFromIp }) => {
    return {
      method: 'POST',
      url: `submit-registration/${id}`,
      data: {
        countryFromIpAtRegistration: countryFromIp,
      },
    }
  },
  (response) => {
    const data = {
      success: false,
      errors: []
    }
    if (response.data.errors)
      data.errors = response.data.errors.map(
        (error) => new FieldError(error)      
    )
    if (!data.errors || data.errors.length === 0)
        data.success = true

    return {
      ...response.data,
      data,
    }
  }
)
