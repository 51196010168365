/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Limited, Sole Trader
 */
export const regionValues = {
  regionId: 505,
  defaultCountryFrom: 'GB',
  defaultCountryFromOnfido: 'GBR',
  defaultCountryCodeFrom: 44,
  defaultCurrencyFrom: 'GBP',
  defaultLanguageCulture: 'en-GB',
  defaultLocation: {
    latitude: 55.378051,
    longitude: -3.435973,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^[a-zA-Z]{1,2}\\d[a-zA-Z\\d]? ?\\d[a-zA-Z]{2}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['en-GB'],
  allowedCompanyTypesForStp: ['1101', '1103'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['1103'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
