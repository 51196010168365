import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import subIndustry from '@/models/CorporateRegistration/NatureOfBusiness/SubIndustry/app'

export default new APIHandler(
  API,
  (id) => {
    return {
      method: 'GET',
      url: `industrytypesbycategory?categoryId=${id}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.data.map((item) => new subIndustry(item)),
    }
  }
)
