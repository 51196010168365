import { render, staticRenderFns } from "./IconOperationIf.vue?vue&type=template&id=22c0147f&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports