import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import companyTypeMapper from '@/composables/companyTypeMapper'

export default new APIHandler(
  API,
  ({ profileId, fields }) => {
    return {
      method: 'PUT',
      url: `corporate/profiles/${profileId}`,
      data: fields,
    }
  },
  (response) => {
    let { companyDetails, registrationAddress } = response.data

    const companyDetailsWithCompanyTypeAcronym = {
      ...companyDetails,
      companyTypeAcronym: companyTypeMapper(
        companyDetails.companyType,
        registrationAddress.country.toUpperCase()
      ),
    }

    return { ...response.data, companyDetails: companyDetailsWithCompanyTypeAcronym }
  }
)
