/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'JE',
  allowedCompanyTypesForStp: ['4301', '4302', '4303'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4301'],
  postalCodeRegex: `^JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
