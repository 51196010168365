<template>
  <Transition v-if="alert" name="popup">
    <div :class="snackAlertClasses">
      <TransitionGroup name="fade-in">
        <AppAlert :key="alert.id" :theme="theme">
          {{ alert.text }}
          <AppAlertAction @actionClick="$emit('dismiss')" />
        </AppAlert>
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script>
import AppAlert, { themes } from '@/components/AppAlert/AppAlert'
import AppAlertAction from '@/components/AppAlertAction/AppAlertAction'
import useAlert from '@/composables/useAlert/index'
import { computed } from '@vue/composition-api'

export default {
  name: 'AppSnackAlert',
  components: {
    AppAlert,
    AppAlertAction,
  },
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: themes[themes.length - 1],
      validation: (value) => {
        return themes.indexOf(value) > -1
      },
    },
  },
  emits: ['dismiss'],
  setup() {
    const { isAlertBtmMargin } = useAlert()

    const snackAlertClasses = computed(() => {
      const classes = ['alert-snack']

      //Accommodates extra bottom margin when either AppNavBottombar or AppCardFooter present
      if (isAlertBtmMargin.value) {
        classes.push('alert-snack--bottom-margin')
      }
      return classes
    })

    return {
      snackAlertClasses,
    }
  },
}
</script>

<style lang="postcss" scoped>
.alert-snack {
  @apply fixed mx-4 right-0 left-0 bottom-0;
  @apply mb-4;

  @screen sm {
    @apply mx-auto w-full;
    width: 400px; /*width as per figma design*/
  }
  @screen md {
    @apply mb-6;
  }
}
.alert-snack--bottom-margin {
  @apply mb-18;
}

.fade-in-enter,
.fade-in-leave-to {
  @apply absolute inset-0 opacity-0;
}

.fade-in-enter-active,
.fade-in-leave-active {
  @apply transition-opacity duration-450 ease-in-out;
}

.fade-in-enter-active {
  @apply delay-450;
}

.popup-enter-active,
.popup-leave-active {
  @apply duration-450 opacity-100;
}
.popup-enter,
.popup-leave-to {
  @apply opacity-0 transform translate-y-full;
}
</style>
