import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  (id) => {
    return {
      method: 'GET',
      url: `corporate-registration/${id}`,
    }
  },
  (response) => {
    let corporateForm = null
    if (response.data.fields) {
      corporateForm = {}
      for (let i=0; i < response.data.fields.length; i++) {
        const field = response.data.fields[i]
        if (!corporateForm[field.group])
          corporateForm[field.group] = {}
        corporateForm[field.group][field.id] = {
          value: field.parsed ? field.parsed.value : null,
        }
      }
    }

    return {
      ...response,
      data: {
        corporateForm
      }
    }
  }
)
