import { defineStore } from 'pinia'
import { ref, computed } from '@vue/composition-api'

import getAuthorizeSsoSettings from '@/api/corporate/authorize/ssoSettings/get'
import SessionLogPayload from '@/models/Session/sessionLog'
import LogRocket from 'logrocket'
import getGsoToken from '@/api/corporate/authorize/gsoToken/get'
import { setHeader, removeHeader } from '@/api/APIHandler'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import router from '@/router'

import { useRegistrationStore } from '@/stores/registration'
import { useDashboardStore } from '@/stores/dashboard'
import { useConsentStore } from '@/stores/consent'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useDeviceStore } from '@/stores/device'
import { useAppStore } from '@/stores/app'
import { useSessionStore } from '@/stores/session'

export const useAuthStore = defineStore(
  'auth',
  () => {
    // ------- STORES -------
    const registrationStore = useRegistrationStore()
    const dashboardStore = useDashboardStore()
    const consentStore = useConsentStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const deviceStore = useDeviceStore()
    const appStore = useAppStore()
    const sessionStore = useSessionStore()

    // ------- STATE -------
    const token = ref(null)
    const user = ref(null)
    const lastLogin = ref(null)
    const profileId = ref(null)
    const refreshTimeout = ref(null)
    const refreshRetries = ref(0)
    const userProfile = ref({})
    const loginUrl = ref(null)
    const tokenUrl = ref(null)
    const refreshToken = ref(null)
    const isLiteRegistration = ref(false)

    // ------- GETTERS -------
    const getSsoIdLaunchpad = computed(() => Cookies.get('xe_sso_id_launchpad'))

    // ------- ACTIONS -------
    /* API */
    const checkSsoSettings = async () => {
      try {
        // Get SSO settings
        const settings = await getAuthorizeSsoSettings.exec()
        // Set token, Redirection URL, and lite registration status
        loginUrl.value = config.VUE_APP_URL_LOGIN_URI
        tokenUrl.value = config.VUE_APP_URL_TOKEN_URI
        isLiteRegistration.value = settings.data.isLiteRegistration
      } catch (ex) {
        checkGsoErrorHandler(ex)
      }
    }
    const checkGsoToken = async () => {
      try {
        const { data } = await getGsoToken.exec(tokenUrl.value)
        return data
      } catch (ex) {
        checkGsoErrorHandler(ex)
      }
    }
    const checkDeviceCountry = async () => {
      try {
        await deviceStore.checkDeviceCountry()
      } catch (ex) {
        checkGsoErrorHandler(ex)
      }
    }
    const checkProfileData = async () => {
      try {
        const profileData = await corporateRegistrationStore.updateProfile()
        return profileData
      } catch (ex) {
        checkGsoErrorHandler(ex)
      }
    }

    /* Auth handlers */
    const checkGso = async () => {
      // Set consent state
      consentStore.setConsentState()
      // Get SSO settings
      await checkSsoSettings()
      // Get GSO token
      const data = await checkGsoToken()
      // Authorization logic
      return await authHandler(data)
    }
    const pendingActionsHandler = async () => {
      // Pending actions logic
      const allowedInDashboard = await dashboardStore.allowedInDashboard()
      if (allowedInDashboard) {
        // Pending actions redirection
        if (router?.history?.current?.matched[0]?.path !== '/dashboard') {
          router.push({ name: 'dashboard' })
          // Stops Calls after redirected
          return false
        }
        return true
      } else {
        // Clear dashboard store
        dashboardStore.setClearSessionStore(null)
        return true
      }
    }
    const afterRegistrationHandler = async (decoded) => {
      // User logged in but it's registration has finished
      if (decoded && decoded.profile && decoded.profile.clientNumber) {
        // Check pending actions
        return await pendingActionsHandler()
      } else if (decoded && decoded.email) {
        // Check for last session
        if (lastLogin.value !== decoded.email) {
          // Set email if value is different from last login email
          lastLogin.value = decoded.email
          corporateRegistrationStore.resetForm(null)
          return true
        }
      } else {
        // Clear login data from store
        lastLogin.value = null
        profileId.value = null
        return true
      }
      return true
    }
    const authHandler = async (data) => {
      if (data && data.accessToken) {
        // Saves the token on the request
        setTokenData(data)
        // set access token
        const decoded = setDecodedAccessToken(data)
        // Logic for pending actions and other cases
        const result = await afterRegistrationHandler(decoded)
        // Handle pending action status
        if (!result) {
          return false
        }
        // Fill the country prior to update the user
        await checkDeviceCountry()
        // Set profile data
        await setProfileData()
        // Handles  identify call
        await logRocketIdentifyCall()
        return true
      }
    }
    const exitRegistration = async () => {
      await window.removeEventListener('beforeunload', window.handleWindowClose)
      // Clear registration store data
      corporateRegistrationStore.resetForm()
    }

    /* Data */
    const setTokenData = (data) => {
      // Saves the token on the request
      token.value = data.accessToken
      refreshToken.value = data.refreshToken
      // Save token in Axios
      setHeader('Authorization', `Bearer ${data.accessToken}`)
      // Remove unnecessary store values
      if (registrationStore.registrationCompleted) {
        registrationStore.resetForm()
        registrationStore.registrationCompeted = false
      }
    }
    const setDecodedAccessToken = (data) => {
      const decoded = jwtDecode(data.accessToken)
      if (decoded && decoded.profile) {
        profileId.value = decoded.profile.profileId
        return decoded
      }
    }
    const setProfileData = async () => {
      const profileData = await checkProfileData()
      if (profileData) {
        profileId.value = profileData.profileId
      }
    }

    const leaveRegistration = async () => {
      try {
        await exitRegistration()
        const launchpadCookieExist = getSsoIdLaunchpad.value
        //if xe_sso_id_launchpad cookie exist redirect user to Account-Site as they logged in via Account-Site
        if (launchpadCookieExist) {
          const launchpadLogoutUrl = config.VUE_APP_ENDPOINTS_LAUNCHPADAPI + 'logout'
          await fetch(launchpadLogoutUrl, { credentials: 'include', mode: 'no-cors' })
          resetForm()
          sessionStore.sessionId = null
          window.location.href = config.VUE_APP_ENDPOINTS_ACCOUNT_SITE_LOGIN
        } else {
          //Redirect to Account-UI
          window.location = loginUrl.value
        }
      } catch (error) {
        appStore.logException({
          text: 'Unable to logout while leaving the registration',
          exception: error,
        })
      }
    }

    /* Error */
    const checkGsoErrorHandler = (ex) => {
      // Redirection helper cookie logic
      const xe_redirect_helper = Cookies.get('xe_redirect_helper')
      if (!xe_redirect_helper) {
        // No expiry so will automatically be a session cookie.
        Cookies.set('xe_redirect_helper', 'true')
      }
      // Log the error
      const statusCode = ex.response.status
      appStore.logException({
        text: 'Exception during authentication process',
        exception: ex,
      })
      //This is to identify users has come to signup with MoneyTransfer account, but failing to get GsoToken
      //due to 2FA not done - so redirecting them to Fxweb to go through 2FA.
      if (statusCode === 401 && xe_redirect_helper) {
        Cookies.remove('xe_redirect_helper')
        window.removeEventListener('beforeunload', window.handleWindowClose)
        window.location.href = config.VUE_APP_URL_TRANSFER
        return false
      }
      // Redirect user to login UI/Accounts UI
      window.location.href = loginUrl.value
      return false
    }

    // TODO: Move to session store
    const logRocketIdentifyCall = () => {
      // Handles  identify call
      const profileValue = profileId.value || ''
      const country = deviceStore.country || ''
      const sessionLogPayload = new SessionLogPayload({ profileId: profileValue, country })
      LogRocket.identify(`${sessionLogPayload.userId}`, sessionLogPayload)
    }

    /* Reset */
    const resetForm = () => {
      token.value = null
      user.value = null
      lastLogin.value = null
      profileId.value = null
      refreshTimeout.value = null
      refreshRetries.value = 0
      userProfile.value = {}
      loginUrl.value = null
      tokenUrl.value = null
      refreshToken.value = null
    }

    const reset = () => {
      // Remove Axios instance Auth Header
      removeHeader('Authorization')
      // Reset store state
      resetForm()
    }

    const sendToContinueAs = () => {
      window.removeEventListener('beforeunload', window.handleWindowClose)
      const continueAsUrl = loginUrl.value.replace('signup', 'continueAs')
      window.location.href = continueAsUrl
    }

    const logoutFromLaunchpad = async () => {
      const accountSiteUrl = config.VUE_APP_ENDPOINTS_ACCOUNT_SITE

      try {
        const launchpadLogoutUrl = config.VUE_APP_ENDPOINTS_LAUNCHPADAPI + 'logout'
        //We also need to logout from Fxweb as Fxweb session might be lingering around
        const transferLogoutUrl = config.VUE_APP_URL_TRANSFER_LOGOUT
        await Promise.all([
          fetch(transferLogoutUrl, { credentials: 'include', mode: 'no-cors' }),
          fetch(launchpadLogoutUrl, { credentials: 'include', mode: 'no-cors' }),
        ])
        reset()
        sessionStore.sessionId = null
        window.location.href = accountSiteUrl
      } catch (error) {
        // Error handler
        appStore.logException({
          text: 'Unable to logout from Launchpad',
          exception: error,
        })
      }
    }

    const logoutFromFxWeb = async () => {
      // fxweb logout
      const transUrl = config.VUE_APP_URL_TRANSFER_LOGOUT
      await fetch(transUrl, { credentials: 'include', mode: 'no-cors' })
      let loginUrl = loginUrl.value
      loginUrl = loginUrl.replace('signup', 'logout')
      reset()
      sessionStore.sessionId = null
      window.location.href = loginUrl
    }

    const logout = async () => {
      // Removes event listener on logout
      window.removeEventListener('beforeunload', window.handleWindowClose)

      // Checking if login was done from "New login UI" or existing "Accounts UI"
      if (getSsoIdLaunchpad) {
        // Logout from login UI)
        await logoutFromLaunchpad()
      } else {
        // Logout from Accounts UI
        await logoutFromFxWeb()
      }
    }

    return {
      // STATES
      token,
      user,
      lastLogin,
      profileId,
      refreshTimeout,
      refreshRetries,
      userProfile,
      loginUrl,
      tokenUrl,
      refreshToken,
      isLiteRegistration,
      logoutFromFxWeb,
      checkGso,
      getSsoIdLaunchpad,
      exitRegistration,
      leaveRegistration,
      logout,
      sendToContinueAs,
      resetForm,
      reset,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
