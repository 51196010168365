import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'

export default new APIHandler(
  API,
  () => {
    return {
      method: 'GET',
      url: `purposeofpayments?applicableto=2`,
    }
  },
  (response) => {
    return response?.data;
  }
)
