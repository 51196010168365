/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'CZ',
  allowedCompanyTypesForStp: ['3801', '3802', '3803'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['3801'],
  postalCodeRegex: `^\\d{3}[ ]?\\d{2}$`,
  addressRegex: `^[\\wěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
