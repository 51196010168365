/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, General Partnership (use optional), Limited Partnership, Cooperative, Sole trader,
 */
export const regionValues = {
  regionId: 8505,
  defaultCountryFrom: 'FI',
  defaultCountryFromOnfido: 'FIN',
  defaultCountryCodeFrom: 358,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'fi-FI',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{5}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['fi-FI', 'en-FI'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2601', '2602', '2603', '2604', '2605', '2606'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2606'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
