/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Sole trader, General Partnership, Limited Partnership, Group Practice (of professionals), Ltd, PLC, Foundation
 */
export const regionValues = {
  regionId: 16214,
  defaultCountryFrom: 'NL',
  defaultCountryFromOnfido: 'NLD',
  defaultCountryCodeFrom: 31,
  defaultCurrencyFrom: 'EUR',
  defaultLanguageCulture: 'nl-NL',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}\\s{0,1}[A-Za-z]{2}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['nl-NL', 'en-NL'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['1601', '1602', '1603', '1604', '1605', '1606', '1607'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['1601'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
