import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import FieldError from '@/models/CorporateRegistration/FieldError/app'

export default new APIHandler(
  API,
  ({ id, fields }) => {
    return {
      method: 'PUT',
      url: `corporate-registration/${id}`,
      data: {
        fields
      },
    }
  },
  (response) => {
    const responseError = response.data.errors.map(
      (error) => new FieldError(error)
    )
    return {
      ...response.data,
      data: responseError,
    }
  }
)
