/**
 * @allowedCompanyTypesForStp PLC, Limited Partnership, Limited Liability Cooperative, Ltd, Publicly traded corporation, Privately held (closely held) corporation, Sole trader
 */
export const regionValues = {
  defaultCountryFrom: 'PT',
  allowedCompanyTypesForStp: ['2901', '2902', '2903', '2904', '2905', '2906', '2907'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2907'],
  postalCodeRegex: `^\\d{4}([\\-]\\d{3})?$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
