/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'GG',
  allowedCompanyTypesForStp: ['4101', '4102', '4103'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4101'],
  postalCodeRegex: `^GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}$`,
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
