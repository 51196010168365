import API from '@/api/corporate'
import APIHandler from '@/api/APIHandler'
import CurrencyType from '@/models/CorporateRegistration/CurrencyType/app'

export default new APIHandler(
  API,
  ({ country }) => {
    return {
      method: 'GET',
      url: `tradeablecurrenciesbycountry?brandid=8&country=${country}`,
    }
  },
  (response) => {
    return {
      ...response,
      data: response.data.map((item) => new CurrencyType(item)),
    }
  }
)
