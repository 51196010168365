export default class companyNameSearchResult {
  constructor(companyType) {
    this.id = companyType.bvdId
    this.companyName = companyType.name
    this.addressLine1 = companyType.addressLine1
    this.addressLine2 = companyType.addressLine2
    this.addressLine3 = companyType.addressLine3
    this.city = companyType.city
    this.countryCode = companyType.countryCode
    this.postCode = companyType.postCode
    this.state = companyType.state
    this.county = companyType.county
    this.consolidationCode = companyType.consolidationCode
    this.naceCode = companyType.nace2CoreCode
    this.naceLabel = companyType.nace2CoreLabel
    this.incorporationDate = companyType.incorporationDate
    this.nationalId = companyType.nationalId
    this.vatNumber = companyType.vatNumber[0] ?? ''
    this.website = companyType.website
    this.boFirstName = companyType.boFirstName
    this.boLastName = companyType.boLastName
    this.boBirthdate = companyType.boBirthdate.map((dob) => this.convertBvdDateToYYYYMMDD(dob))
    this.boAddress = companyType.boAddress
    this.boCity = companyType.boCity
    this.boCountryIsoCode = companyType.boCountryIsoCode
    this.boPostcode = companyType.boPostcode
    this.boIsPep = companyType.boWorldComplianceMatchCurrentPEPIndicator
    this.cpyFirstName = companyType.cpycontactsHeaderFirstnameOriginalLanguagePreferred
    this.cpyLastName = companyType.cpycontactsHeaderLastnameOriginalLanguagePreferred
    this.cpyBirthdate = companyType.cpycontactsHeaderBirthdate.map((dob) =>
      this.convertBvdDateToYYYYMMDD(dob)
    )
    this.cpyWorkAddress = companyType.cpycontactsMembershipWorkFullAddress
  }

  convertBvdDateToYYYYMMDD(dateStr) {
    if (dateStr) {
      // Creating a Date object from the input string
      const date = new Date(dateStr)
      // Extracting year, month, and day
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // +1 because months are 0-indexed
      const day = date.getDate().toString().padStart(2, '0')

      // Formatting to "YYYY-MM-DD"
      return `${year}-${month}-${day}`
    }
  }
}
