<template>
  <div :class="{ 'loading-spinner': true, 'loading-spinner-screen': !inline }">
    <div class="loading-spinner-container">
      <AppSpinner
        :loading="loading"
        :size="50"
        transition="fade"
        class="spinner"
      ></AppSpinner>
    </div>
  </div>
</template>

<script>
import AppSpinner from '@/components/AppSpinner/AppSpinner'

export default {
  name: 'AppSpinnerBig',
  components: { AppSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.loading-spinner {
  /* TODO: improve */
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-spinner-container {
    top: 9px;
    position: absolute;
    z-index: 1000;
  }

  svg {
    @apply text-orange !important;
  }
}

.loading-spinner-screen {
  .loading-spinner-container {
    margin-top: 0px;
    top: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
