/**
 * @description Geographical region the application is currently configured for, e.g. US, Spain, Australia.
 * @region Enumeration for this region.
 * @defaultLanguage Default language culture to use, i.e. content translations
 * @allowedCompanyTypesForStp Ltd, PLC, Sole trader, Limited Partnership, General Partnership with mutual liability
 */
export const regionValues = {
  regionId: 1805,
  defaultCountryFrom: 'NO',
  defaultCountryFromOnfido: 'NOR',
  defaultCountryCodeFrom: 47,
  defaultCurrencyFrom: 'NOK',
  defaultLanguageCulture: 'nb-NO',
  defaultLocation: {
    latitude: 48.878528,
    longitude: 2.353356,
  },
  dateFormat: 'DD/MM/YYYY',
  postalCodeRegex: `^\\d{4}$`,
  phoneRegex: `^[0-9\\.\\-\\+\\(\\)\\[\\] ]{10}$`,
  availableLanguageCultures: ['nb-NO', 'en-NO'],
  regionalIp: '66.82.123.234',
  allowedCompanyTypesForStp: ['2301', '2302', '2303', '2304', '2305'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['2303'],
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
