export const newRegNumber = (regNumber, country) => {
  // Replace ABN/ACN with country code
  if (regNumber.startsWith('ABN') || regNumber.startsWith('ACN')) {
    regNumber = regNumber.replace(regNumber.substring(0, 3), country)
  }

  // Prepend country code if search term contains only numbers
  if (regNumber.substring(0, 2) !== country) {
    const expr = new RegExp('^\\S*[A-Za-z]*\\d{5,10}\\S*$')
    if (expr.test(regNumber)) {
      regNumber = country + regNumber
    }
  }

  return regNumber
}
