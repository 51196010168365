
// Imports
import amplitude from 'amplitude-js'; // required to initialize Amplitude instance.

// Promises to handle initalization and event push
let analyticsResolve = null
const analyticsPromise = new Promise((resolve) => { analyticsResolve = resolve })

// Get analytics providers based on API key
let analyticsProviders
const getAnalyticsProviders = (providers, target) => providers.includes(target)

// Segment snippet
// More info: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
const segmentInit = async (segmentKey) => {
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
    analytics.load(segmentKey)
    analyticsResolve()
  }}();
}

// Amplitude init
// More info: https://developers.amplitude.com/docs/javascript
const amplitudeInit = (amplitudeKey) => {
  amplitude.getInstance().init(amplitudeKey)
}

// GTM snippet
// More info: https://developers.google.com/tag-platform/tag-manager/web
const gtmInit = (gtmKey) => {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', gtmKey);
}

// Initialize
export const analyticsInit = async ({ ...analyticsKeys }) => {
  // Analytics API key
  const { amplitudeKey, gtmKey, segmentKey } = analyticsKeys

  // Get name of analitics provider
  analyticsProviders = Object.keys(analyticsKeys).map(
    provider => provider.split('Key')
      .join('')
      .toLowerCase()
  )

  // Initialize analytics
  if (segmentKey) {
    // Segment Initialization
    segmentInit(segmentKey)
  }
  if (gtmKey) {
    gtmInit(gtmKey) // GTM Initilization
  }
  if (amplitudeKey) {
    amplitudeInit(amplitudeKey) // Amplitude Initialization
  }
}

// Push user actions to Segment and Amplitude (Depends on user keys added in the initialization)
export const identify = async ({ userId, traits }) => {
  await analyticsPromise

  if (getAnalyticsProviders(analyticsProviders, 'segment')) {
    window.analytics.identify(userId, traits)
  }
  if (getAnalyticsProviders(analyticsProviders, 'amplitude')) {
    amplitude.getInstance().setUserId(userId)
    amplitude.getInstance().setUserProperties(traits)
  }
}

// Push page event to Segment
export const page = async (pageName) => {
  await analyticsPromise

  if (getAnalyticsProviders(analyticsProviders, 'segment')) {
    window.analytics.page(pageName)
  }
}

// Push GTM event through Segment
export const gtmTrack = async ({ event, variables}) => {
  await analyticsPromise

  if (getAnalyticsProviders(analyticsProviders, 'segment')) {
    window.analytics.track(event, { ...variables })
  }
}

// Push page event to Segment (Depends on user keys added in the initialization)
export const track = async ({  userId, event, traits }) => {
  await analyticsPromise

  if (getAnalyticsProviders(analyticsProviders, 'segment')) {
    window.analytics.track(event, traits)
  }
  if (getAnalyticsProviders(analyticsProviders, 'amplitude')) {
    if (!!userId && !amplitude.getInstance().getUserId()) {
      amplitude.getInstance().setUserId(userId)
    }
    amplitude.getInstance().logEvent(event, traits)
  }
}
