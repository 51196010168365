export default class PhoneDetails {
  constructor({ number, areaCode, nameAbbr }) {
    this.number = number
    this.areaCode = areaCode
    this.nameAbbr = nameAbbr
    this.value = this.number.substr(this.number.indexOf('-') + 1)
  }

  static FromCountry(country) {
    if (country) {
      const { code, phonePrefix } = country
      return new PhoneDetails({
        number: phonePrefix,
        areaCode: phonePrefix,
        nameAbbr: code,
      })
    }
  }

  get numberOnly() {
    return this.number.replace(/-/g, '')
  }
}
