/**
 * @allowedCompanyTypesForStp Sole Proprietorship, Ltd, PLC
 */
export const regionValues = {
  defaultCountryFrom: 'GI',
  allowedCompanyTypesForStp: ['4001', '4002', '4003'],
  allowedSTPCountry: true,
  marketingPromptCompanies: ['4001'],
  postalCodeRegex: `^([A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$`, // Regex not found. TODO!
  addressRegex: `^[\\wa-zA-ZÀ-ÿ0-9|/\\s,'-.]*$`,
}
