<template>
  <div class="dialog-header">
    <!-- @slot default: The title-->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppDialogHeader',
  components: {},
  emits: [],
  props: {},
  setup() {
    return {}
  },
}
</script>
<style scoped>
.dialog-header {
  @apply text-primary-text type-h3 text-center mt-6 px-8;
}
</style>
